import React from 'react'
import FlexBox from '../FlexBox'
import moment from 'moment-timezone/builds/moment-timezone-with-data'
import {
  StyledPickUpInfo,
  StyledBoldSubHeading,
} from '../styles/StyledElements'
function TripInfo(props) {
  const { checkout, isMobile, isSuccessPage } = props
  return (
    <div>
      <FlexBox direction="row" marginTop="0.8rem" marginBottom="0.5rem">
        <FlexBox direction="column" marginRight="5rem">
          {checkout.rideDate && (
            <StyledBoldSubHeading color={isSuccessPage ? '#212121' : '#FFF'}>
              Pick up:
            </StyledBoldSubHeading>
          )}
          {checkout.rideDate && (
            <StyledPickUpInfo color={isSuccessPage ? '#212121' : '#FFF'}>
              <i className="far fa-calendar-alt"></i>{' '}
              {checkout.rideDate
                ? moment(checkout.rideDate).format('MM-DD-YYYY')
                : null}
            </StyledPickUpInfo>
          )}
          {checkout.selectedTimeFormat && (
            <StyledPickUpInfo color={isSuccessPage ? '#212121' : '#FFF'}>
              <i className="far fa-clock"></i> {checkout.selectedTimeFormat}
            </StyledPickUpInfo>
          )}
        </FlexBox>
        <FlexBox direction="column" alignItems="flex-start">
          {checkout.dropoffDate && (
            <StyledBoldSubHeading color={isSuccessPage ? '#212121' : '#FFF'}>
              Return:
            </StyledBoldSubHeading>
          )}
          {checkout.dropoffDate && (
            <StyledPickUpInfo color={isSuccessPage ? '#212121' : '#FFF'}>
              <i className="far fa-calendar-alt"></i>{' '}
              {checkout.dropoffDate
                ? moment(checkout?.dropoffDate).format('MM-DD-YYYY')
                : null}
            </StyledPickUpInfo>
          )}
          {checkout.dropoffDate && (
            <StyledPickUpInfo color={isSuccessPage ? '#212121' : '#FFF'}>
              <i className="far fa-clock"></i> {checkout.dropoffTimeFormat}
            </StyledPickUpInfo>
          )}
        </FlexBox>
      </FlexBox>
    </div>
  )
}

export default TripInfo
