export const TIME_OPTIONS = [
  { key: '0', value: '0', text: '12:00am' },
  { key: '1', value: '1', text: '1:00am' },
  { key: '2', value: '2', text: '2:00am' },
  { key: '3', value: '3', text: '3:00am' },
  { key: '4', value: '4', text: '4:00am' },
  { key: '5', value: '5', text: '5:00am' },
  { key: '6', value: '6', text: '6:00am' },
  { key: '7', value: '7', text: '7:00am' },
  { key: '8', value: '8', text: '8:00am' },
  { key: '9', value: '9', text: '9:00am' },
  { key: '10', value: '10', text: '10:00am' },
  { key: '11', value: '11', text: '11:00am' },
  { key: '12', value: '12', text: '12:00pm' },
  { key: '13', value: '13', text: '1:00pm' },
  { key: '14', value: '14', text: '2:00pm' },
  { key: '15', value: '15', text: '3:00pm' },
  { key: '16', value: '16', text: '4:00pm' },
  { key: '17', value: '17', text: '5:00pm' },
  { key: '18', value: '18', text: '6:00pm' },
  { key: '19', value: '19', text: '7:00pm' },
  { key: '20', value: '20', text: '8:00pm' },
  { key: '21', value: '21', text: '9:00pm' },
  { key: '22', value: '22', text: '10:00pm' },
  { key: '23', value: '23', text: '11:00pm' }
];