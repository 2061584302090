import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import FlexBox from './FlexBox'
import { useDispatch } from 'react-redux'
import { Typography } from '@mui/material'
import {
  Button,
  Image,
  StyledInputFieldWrapper,
  StyledFieldLabel,
  StyledInputField,
  StyledBoldSubHeading,
  StyledSpan,
  StyledSubTitle,
  StyledParagraph,
  StyledRadioField,
  StyledErrorLabel,
  StyledSuccess
} from '../components/styles/StyledElements'
import Grid from '@material-ui/core/Grid'
import {
  useCreatePaymentMutation,
  useGetPaymentInfoQuery,
  useDeletePaymentInfoMutation,
} from '../data/services/hobnob'
import {
  setPaymentInfo,
  setTermsServiceAgreed,
  setSelectedPaymentId,
  setCurrentStep,
  setPromoCode,
  setPromoSuccessMessage,
  setVouchedTriggered,
  setAuthorizationAgreed
} from '../data/reducer'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import styled from 'styled-components'
import { paymentTypeInfo } from '../components/utils/paymentTypeInfo'
import { loading } from './utils/loading'
import {
  vouchedEnabled,
  driverLicenseBeforeBooking
} from './vouched/featureFlag'

const StyledDeleteOutlineOutlinedIcon = styled(DeleteOutlineOutlinedIcon)`
  &:hover {
    cursor: pointer;
  }
`

function AddStripePayment(props) {
  const { checkout, isMobile } = props
  const [createPayment, createPaymentResult] = useCreatePaymentMutation()
  const [deletePaymentInfo, deletePaymentInfoResult] =
    useDeletePaymentInfoMutation()
  const getPaymentInfoResult = useGetPaymentInfoQuery()
  const stripe = useStripe()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const elements = useElements()
  const [stripeError, setStripeError] = useState(false)
  const [cardHolderName, setCardHolderName] = useState('')
  const [paymentActionName, setPaymentActionName] = useState(null)
  const [showAddPayment, setShowAddPayment] = useState(false)
  const [showPromoCode, setShowPromoCode] = useState(false)
  const [promocodeField, setPromoCodeField] = useState('')
  const [promocodeError, setPromoError] = useState('')

  useEffect(() => {
    if (
      createPaymentResult.status === 'fulfilled' &&
      paymentActionName === 'add'
    ) {
      dispatch(setPaymentInfo(getPaymentInfoResult.data))
      cardAdded()
      dispatch(setSelectedPaymentId(createPaymentResult.data.uuid))
      setPaymentActionName(null)
    } else if (
      deletePaymentInfoResult.status === 'fulfilled' &&
      paymentActionName === 'delete'
    ) {
      dispatch(setSelectedPaymentId(null))
      dispatch(setPaymentInfo(getPaymentInfoResult.data))
      cardRemoved()
      setPaymentActionName(null)
    } else if (getPaymentInfoResult.status === 'fulfilled') {
      dispatch(setPaymentInfo(getPaymentInfoResult.data))
    }
  }, [createPaymentResult.data, deletePaymentInfoResult.data])

  if (getPaymentInfoResult.status === 'fulfilled') {
    dispatch(setPaymentInfo(getPaymentInfoResult.data))
    if (getPaymentInfoResult?.data?.length > 0) {
      dispatch(setSelectedPaymentId(getPaymentInfoResult?.data[0]?.uuid))
    }
  }

  const HandleSubmit = async (event) => {
    event.preventDefault()

    if (elements === null) {
      return
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    })

    if (error) {
      setStripeError(true)
    } else {
      let methodData = {
        stripe_payment_method_token: paymentMethod.id,
        name: cardHolderName,
      }
      setPaymentActionName('add')
      AddInfoToTwistedRoad(methodData)
    }
  }

  const AddInfoToTwistedRoad = (methodData) => {
    createPayment(methodData)
  }

  const cardAdded = () => {
    toast.success('The payment method was successfully added.', {
      position: 'top-right',
      icon: false,
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
    })
    setShowAddPayment(false)
  }

  const cardRemoved = () => {
    toast.success('The payment method was successfully deleted.', {
      position: 'top-right',
      icon: false,
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
    })
    setShowAddPayment(false)
  }

  const handleContinue = () => {
    dispatch(setVouchedTriggered(true))
    if (!isMobile) {
      if (driverLicenseBeforeBooking() && vouchedEnabled()) {
        dispatch(setCurrentStep(5))
        navigate('/checkout/5/vouched')
      } else {
        dispatch(setCurrentStep(5))
        navigate('/checkout/5')
      }
    } else {
      if (driverLicenseBeforeBooking() && vouchedEnabled()) {
        dispatch(setCurrentStep(6))
        navigate('/checkout/5/vouched')
      } else {
        dispatch(setCurrentStep(6))
        navigate('/checkout/5')
      }
    }
  }

  const handleApplyPromoCode = () => {
    if (!promocodeField) {
      setPromoError('Please add a promo code.')
    } else {
      setPromoError('')
      dispatch(setPromoCode(promocodeField))
    }
  }

  const handleRemovePromo = () => {
    setPromoCodeField('')
    dispatch(setPromoCode(''))
    dispatch(setPromoSuccessMessage(''))
  }

  return (
    <>
      <FlexBox direction="column">
        {checkout.paymentInfo?.map((card, index) => (
          <div key={index} className="payment-methods">
            <FlexBox direction="row" alignItems="center" marginBottom="-0.8rem">
              <StyledRadioField
                type="radio"
                name={card.brand}
                checked={checkout?.paymentsCardUuid === card.uuid}
                onChange={(e) => {
                  dispatch(setSelectedPaymentId(card.uuid))
                }}
              />
              &nbsp;
              <Image
                src={paymentTypeInfo(card.brand)}
                width="45px"
                height="28px"
                objectFit="contain"
              />
              &nbsp;
              <StyledBoldSubHeading>
                {'Ending in '} {card.last_4}
              </StyledBoldSubHeading>
              &nbsp;&nbsp;&nbsp;
              <div
                onClick={() => {
                  setPaymentActionName('delete')
                  deletePaymentInfo({ payment_method_id: card.uuid })
                }}
              >
                <StyledDeleteOutlineOutlinedIcon fontSize="large" />
              </div>
            </FlexBox>
            <br />
          </div>
        ))}
        {!showAddPayment && !checkout.paymentInfo?.length === 0 && (
          <div className="add-payment-method"
            onClick={() => setShowAddPayment(!showAddPayment)}
            style={{ 'cursor': 'pointer' }}
          >
            <FlexBox direction="row" alignItems="center" marginBottom="-0.8rem">
              <StyledRadioField
                type="radio"
                name={"new card"}
                checked={false}
              />
              &nbsp;
              <StyledSpan twistedBrown>
                <i class="fa fa-plus" aria-hidden="true"></i>
              </StyledSpan>
              &nbsp;
              <StyledSpan
                twistedBrown
                fontWeight="600"
              >
                {'Add New Card '}
              </StyledSpan>
              <Image
                src={paymentTypeInfo('')}
                width="55px"
                height="33px"
                objectFit="contain"
              />&nbsp;
            </FlexBox>
            <br />
          </div>
        )}
      </FlexBox>
      {(showAddPayment || checkout.paymentInfo?.length <= 0) && (
        <>
          <StyledSubTitle>Enter Payment Information</StyledSubTitle>
          <form onSubmit={HandleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <StyledInputFieldWrapper>
                  <StyledFieldLabel>Card Holder Name</StyledFieldLabel>
                  <StyledInputField
                    type="text"
                    placeholder="Card Holder Name"
                    width={'90%'}
                    height={'40px'}
                    value={cardHolderName}
                    onChange={(e) => {
                      setCardHolderName(e.target.value)
                    }}
                  />
                </StyledInputFieldWrapper>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <StyledInputFieldWrapper>
                  <StyledFieldLabel marginBottom="-0.8rem">
                    Enter Card Details
                  </StyledFieldLabel>
                </StyledInputFieldWrapper>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={11} md={6}>
                <CardElement />
                <FlexBox alignItems="center">
                  <Button
                    type="submit"
                    width={isMobile ? '100%' : '85%'}
                    marginTop="2rem"
                    marginBottom="2rem"
                    twistedBrown
                    disabled={createPaymentResult.isLoading}
                  >
                    {createPaymentResult.isLoading ? (
                      <FlexBox
                        direction="row"
                        alignItems="center"
                        justify="center"
                      >
                        <Image
                          src={loading.LOADING}
                          width="25px"
                          height="25px"
                        />
                      </FlexBox>
                    ) : (
                      'Save Payment Method'
                    )}
                  </Button>
                </FlexBox>
              </Grid>
            </Grid>
          </form>
        </>
      )}
      {!showPromoCode && (
        <Button
          className="add-promo-code"
          transparent="transparent"
          padding={isMobile ? " 0.75em 0.5em" : "0rem 0.5rem"}
          onClick={() => setShowPromoCode(true)}
        >
          <FlexBox
            direction="row"
            alignItems="center"
            marginTop="0.5rem"
            marginBottom={isMobile ? "-1rem" : "0rem"}
          >
            <StyledSpan twistedBrown>
              <i class="fa fa-plus" aria-hidden="true"></i>
            </StyledSpan>&nbsp;
            <StyledSpan twistedBrown>
              Add Promo Code
            </StyledSpan>
          </FlexBox>
        </Button>
      )}
      {showPromoCode && (
        <div
          className="promo-code-container"
          style={{ marginLeft: "13px" }}
        >
          <StyledInputFieldWrapper paddingBottom="0rem">
            <StyledFieldLabel>Promo Code</StyledFieldLabel>
            <FlexBox direction="row" alignItems="center">
              <StyledInputField
                type="text"
                name="promo_code"
                placeholder="Ex: BIGRIDE"
                width={isMobile ? '40%' : '45%'}
                height={'40px'}
                paddingLeft="8px"
                value={promocodeField}
                onChange={(e) => setPromoCodeField(e.target.value)}
              />
              <Button
                twistedBrown
                marginLeft="-8px"
                padding="0.5rem 1rem"
                borderTopLeft="0"
                borderBottomLeft="0"
                marginBottom="0"
                onClick={() => handleApplyPromoCode()}
              >
                Apply Code
              </Button>
            </FlexBox>
            <StyledErrorLabel>
              {promocodeError || checkout.promoCodeMessage}
            </StyledErrorLabel>
            {checkout.promoSuccessMessage && (
              <StyledSuccess marginTop="8px">
                <FlexBox direction="column" alignItems="flex-end">
                  <div>
                    <i class="fa fa-check-circle" aria-hidden="true"></i> &nbsp;
                    {checkout.promoSuccessMessage}
                  </div>
                  <StyledSpan
                    fontWeight="600"
                    fontSize="16px"
                    className="remove-promo-code"
                    padding="4px 8px"
                    onClick={() => handleRemovePromo()}
                  >
                    Remove
                  </StyledSpan>
                </FlexBox>
              </StyledSuccess>
            )}
          </StyledInputFieldWrapper><br />
        </div>
      )}
      <StyledSubTitle marginBottom="0.4rem">Security Deposit</StyledSubTitle>
      <StyledParagraph>
        A security deposit of ${checkout.securityDepositAmount} is taken 2 days
        before the trip begins, and returned to you 2 days after the trip is
        completed pending no issues.
      </StyledParagraph>
      <FlexBox direction="row" alignItems={'flex-start'} marginTop="2rem">
        <StyledInputField
          type="checkbox"
          name="acceptedTerms"
          width={'27px'}
          height={'27px'}
          checked={checkout.termsServiceAgreed ? true : false}
          onChange={() =>
            dispatch(setTermsServiceAgreed(!checkout.termsServiceAgreed))
          }
        />
        &nbsp;
        <Typography fontSize="15px">
          &#160;I agree to Twisted Road's{' '}
          <StyledSpan twistedBrown>
            <a
              href="https://www.twistedroad.com/terms-conditions"
              target="_blank" rel="noreferrer"
            >
              {' '}
              Terms Of Service
            </a>{' '}
          </StyledSpan>
          and{' '}
          <StyledSpan twistedBrown>
            <a
              href="https://www.twistedroad.com/privacy-policy"
              target="_blank" rel="noreferrer"
            >
              {' '}
              Privacy Policy.
            </a>{' '}
          </StyledSpan>
          I also understand that only verified and approved riders are
          authorized to operate the vehicle.
        </Typography>
      </FlexBox>
      <FlexBox direction="row" alignItems={'flex-start'} marginTop="1rem">
        <StyledInputField
          marginTop='3px'
          type="checkbox"
          name="acceptedAuthorize"
          width={'18px'}
          height={'18px'}
          checked={checkout.authorizationAgreed ? true : false}
          onChange={() =>
            dispatch(setAuthorizationAgreed(!checkout.authorizationAgreed))
          }
        />
        &nbsp;
        <Typography fontSize="15px">
          &#160;I authorize the dealer and/or the vehicle manufacturer to contact me directly with regards to this test ride.
        </Typography>
      </FlexBox>
      <FlexBox>
        <Button
          marginTop={'2rem'}
          marginBottom={'0.7rem'}
          twistedGold
          twistedDarkBlack
          disabled={
            checkout.paymentsCardUuid && checkout.termsServiceAgreed
                && checkout.authorizationAgreed ? false : true
          }
          onClick={() => {
            handleContinue()
          }}
        >
          Continue
        </Button>
      </FlexBox>
      <ToastContainer autoClose={4000} />
    </>
  )
}

export default AddStripePayment
