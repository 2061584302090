import React from 'react';
import {
  StyledH1,
  StyledParagraph,
  Image,
} from '../styles/StyledElements'
import FlexBox from '../../components/FlexBox'
import { loading } from '../utils/loading'

function BrandingDesktop(props) {
  return (
    <FlexBox direction="column">
      <StyledH1 marginBottom="-0.1rem">Extended Test Ride</StyledH1>
      <FlexBox
        direction="row"
        alignItems="center"
        marginBottom={"2rem"}
      >
        <StyledParagraph>
          Powered by
            </StyledParagraph>
        <Image src={loading.TR_LOGO_2}
          width="135px"
          height="38px"
          marginLeft="-0.4rem"
          objectFit="contain"
        />
      </FlexBox>
    </FlexBox>
  );
}

export default BrandingDesktop;