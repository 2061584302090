import moment from 'moment-timezone/builds/moment-timezone-with-data';

export const BIRTH_MONTH_OPTIONS = [
  { key: '01', value: '01', text: 'January' },
  { key: '02', value: '02', text: 'February' },
  { key: '03', value: '03', text: 'March' },
  { key: '04', value: '04', text: 'April' },
  { key: '05', value: '05', text: 'May' },
  { key: '06', value: '06', text: 'June' },
  { key: '07', value: '07', text: 'July' },
  { key: '08', value: '08', text: 'August' },
  { key: '09', value: '09', text: 'September' },
  { key: '10', value: '10', text: 'October' },
  { key: '11', value: '11', text: 'November' },
  { key: '12', value: '12', text: 'December' },
]

const dateOptions = () => {
  let dateOptions = []
  for (let i = 1; i < 32; i++) {
    dateOptions.push({
      key: i <= 9 ? '0' + i.toString() : i.toString(),
      value: i <= 9 ? '0' + i.toString() : i.toString(),
      text: i <= 9 ? '0' + i.toString() : i.toString()
    })
  }
  return dateOptions
}

export const DATE_OPTIONS = dateOptions()

const yearOptions = () => {
  let yearOptionsArray = [];

  for (let i = 0; i < 25; i++) {
    yearOptionsArray.push({
      key: moment()
        .add(i, "years")
        .format("YYYY")
        .toString(),
      value: moment()
        .add(i, "years")
        .format("YYYY")
        .toString(),
      text: moment()
        .add(i, "years")
        .format("YYYY")
        .toString(),
    });
  }

  return yearOptionsArray;
}

export const YEAR_OPTIONS = yearOptions()

const yearOptionsPast = () => {
  let yearOptionsPast = []
  for (let i = 0; i < 25; i++) {
    yearOptionsPast.push({
      key: moment().add(i, 'years').format('YYYY').toString(),
      value: moment().add(i, 'years').format('YYYY').toString(),
      text: moment().add(i, 'years').format('YYYY').toString()
    })
  }
  return yearOptionsPast
}

export const YEAR_OPTIONS_PAST = yearOptionsPast()

const yearBirthOptions = () => {
  let yearBirthOptions = []
  for (let i = 0; i < 100; i++) {
    yearBirthOptions.push({
      key: moment().subtract(i, 'years').format('YYYY').toString(),
      value: moment().subtract(i, 'years').format('YYYY').toString(),
      text: moment().subtract(i, 'years').format('YYYY').toString()
    })
  }
  return yearBirthOptions
}

export const YEAR_BIRTH_OPTIONS = yearBirthOptions()
