import * as React from 'react'
import { Image } from './../styles/StyledElements'
import Dialog from '@mui/material/Dialog'
import ListItem from '@mui/material/ListItem'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { useMotorcyclePricingQuery } from './../../data/services/hobnob'
import moment from 'moment-timezone/builds/moment-timezone-with-data'
import './MobileStyling.css'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function BikeInfoDialog(props) {
  const { currentStep, maxStep } = props.checkout
  const pricingResult = useMotorcyclePricingQuery(props.checkout, {
    skip: props.checkout?.motorcycleId ? false : true,
  })

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.handleClose}
      TransitionComponent={Transition}
      sx={{ marginTop: '130px' }}
    >
      <AppBar
        sx={{
          position: 'relative',
          color: '#000000',
          background: 'linear-gradient(180deg, #FFEC3B 30%, #E2BC00 90%)',
        }}
      >
        <Toolbar>
          <Typography
            sx={{ ml: 2, flex: 1, fontWeight: 'bold' }}
            variant="h6"
            component="div"
          >
            Reservation Info
          </Typography>
          <IconButton
            sx={{ fontWeight: 'bold' }}
            edge="end"
            color="inherit"
            onClick={props.handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <List>
        <ListItem>
          <Grid container direction="row" spacing={1}>
            <Grid item xs={5}>
              <Image
                src={props.data?.images[0]?.url}
                alt="bike-image-mobile"
                className="bike-image-mobile-dialog"
                objectFit="cover"
              />
            </Grid>
            <Grid item xs={7}>
              <Typography sx={{ fontSize: '17px', fontWeight: 'bold' }}>
                {props.data ? props.data?.year + ' ' + props.data?.make : null}
              </Typography>
              <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                {props.data ? props.data?.model : null}
              </Typography>
              <Typography
                sx={{ fontSize: '15px', fontWeight: '500', marginTop: '10px' }}
              >
                {props.data
                  ? props.data?.partner_info.partner_location.city
                  : null}
                ,{' '}
                {props.data
                  ? props.data?.partner_info.partner_location
                    .region_abbreviation
                  : null}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <Divider />
        <ListItem>
          <Grid
            container
            justifyContent="space-between"
            spacing={2}
            marginTop="10px !important"
            marginBottom="10px !important"
          >
            <Grid
              item
              xs={5}
              sx={{
                border: '1px solid  black',
                borderRadius: '10px',
                padding: '5px',
              }}
            >
              <Typography fontSize="17px" fontWeight="bold">
                Pick Up:
              </Typography>
              <Typography>
                <i className="far fa-calendar-alt"></i>{' '}
                {props.checkout?.rideDate
                  ? moment(props.checkout?.rideDate).format('MM-DD-YYYY')
                  : '-'}
              </Typography>
              <Typography>
                <i className="far fa-clock"></i>{' '}
                {props.checkout?.selectedTimeFormat}
              </Typography>
            </Grid>
            <Grid
              item
              xs={5}
              sx={{
                border: '1px solid  black',
                borderRadius: '10px',
                padding: '5px',
              }}
            >
              <Typography fontSize="17px" fontWeight="bold">
                Return:
              </Typography>
              <Typography>
                <i className="far fa-calendar-alt"></i>{' '}
                {props.checkout?.dropoffDate
                  ? moment(props.checkout?.dropoffDate).format('MM-DD-YYYY')
                  : '-'}
              </Typography>
              <Typography>
                <i className="far fa-clock"></i>{' '}
                {props.checkout?.dropoffTimeFormat}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <Divider />
        <ListItem>
          <Grid container marginTop="10px !important">
            <Typography fontSize="17px" fontWeight="bold" marginRight="5px">
              Total:
            </Typography>
            {pricingResult.status === 'fulfilled' && maxStep > 4 ? (
              <Typography>${props.checkout?.netTotal} (refundable)*</Typography>
            ) : (
                '-'
              )}
          </Grid>
        </ListItem>
        <Divider />
        {pricingResult.status === 'fulfilled' && maxStep > 4 && (
          <ListItem>
            <Grid container marginTop="10px !important">
              <Typography fontSize="15px">
                * The test ride fee covers insurance costs and wear and tear on
                the motorcycle during the Extended Test Ride period. This fee is
                fully refundable if you choose to purchase the bike.
              </Typography>
            </Grid>
          </ListItem>
        )}
      </List>
    </Dialog>
  )
}
