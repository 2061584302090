import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Image } from './../styles/StyledElements'
import {
  useMotorcyclePricingQuery,
  useMotorcycleInfoQuery,
} from './../../data/services/hobnob'
import {
  setSelectedInsurance,
  setBlackOutDates,
  setMotorcycleId,
  setPartnerWorkingHours,
  setPartnerLocation,
  setSecurityDepositAmount,
  setNetTotal,
  setPromoCodeMessage,
  setPromoSuccessMessage,
  setMotorcycleTimeZone,
  setPartnerLogoUrl,
  setBikeID,
} from './../../data/reducer'
import './MobileStyling.css'
import { useLocation } from 'react-router-dom'
import { ROUTING_URLS } from './../../constants/routingUrls'
import { IconButton, Typography, Grid } from '@mui/material'
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined'
import BikeInfoDialog from './BikeInfoDialog'
import { TIME_ZONE } from '../../constants/index'

const BikeInfoMobile = (props) => {
  const locationName = useLocation()
  const [open, setOpen] = useState(false)
  const { checkout } = props
  const dispatch = useDispatch()
  const pricingResult = useMotorcyclePricingQuery(checkout, {
    skip: checkout.motorcycleId ? false : true,
  })
  const motorcycleResult = useMotorcycleInfoQuery(checkout, {
    skip: checkout.VIN || checkout.VIN6 ? false : true,
  })

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (pricingResult.status === 'fulfilled') {
      dispatch(
        setNetTotal(
          pricingResult.data.pricing?.totals?.net_total?.net_total_cents
            ? pricingResult.data.pricing?.totals?.net_total?.net_total_cents /
            100
            : 0
        )
      )
      if (pricingResult.data.pricing?.promotions?.promotion_error_message
        && checkout.promoCode !== null) {
        dispatch(
          setPromoCodeMessage(
            pricingResult.data.pricing?.promotions?.promotion_error_message
          )
        )
      } else {
        dispatch(setPromoCodeMessage(null))
      }
      const aplliedCode = pricingResult.data.pricing?.line_items.filter(item => item.type == 'promotion_code')
      if (aplliedCode?.length > 0) {
        dispatch(
          setPromoSuccessMessage((
            aplliedCode[0]?.promo_info?.code + " - " + aplliedCode[0]?.promo_info?.title
          )
          )
        )
      }
    }
    if (motorcycleResult.status === 'fulfilled') {
      if (!motorcycleResult.data) {
        window.open("https://www.twistedroad.com", "_self")
      }
      dispatch(
        setPartnerLogoUrl(
          motorcycleResult?.data?.partner_info?.partner_logo_url
        )
      )
      dispatch(
        setMotorcycleTimeZone(
          motorcycleResult?.data?.partner_info?.timezone ?
            motorcycleResult?.data?.partner_info?.timezone
            : motorcycleResult?.data?.timezone
        )
      )
      dispatch(
        setBlackOutDates(motorcycleResult.data?.motorcycle_blackout_dates[0])
      )
      const insuranceOption =
        motorcycleResult.data?.available_services?.rider_insurance_options.filter(
          (option) => option.title === 'Premium'
        )
      if (insuranceOption?.length > 0) {
        dispatch(setSelectedInsurance(insuranceOption[0]?.uuid))
      }
      dispatch(setMotorcycleId(motorcycleResult.data?.pretty_id))
      dispatch(setBikeID(motorcycleResult.data.id))
      dispatch(
        setPartnerWorkingHours(
          motorcycleResult.data?.partner_info?.partner_working_hours
        )
      )
      dispatch(
        setPartnerLocation(
          motorcycleResult.data?.partner_info?.partner_location
        )
      )
      dispatch(
        setSecurityDepositAmount(motorcycleResult.data?.pricing?.hold_amount)
      )
    }
  }, [motorcycleResult, pricingResult])

  return (
    <>
      {motorcycleResult?.data && (
        <div className="bike-info-mobile-container">
          <Grid container direction="row" spacing={1}>
            <Grid item xs={3}>
              <Image
                src={motorcycleResult.data?.images[0]?.url}
                alt="bike-image-mobile"
                className="bike-image-mobile"
                objectFit="cover"
              />
            </Grid>
            <Grid item xs={9}>
              {motorcycleResult.data && (
                <Grid container spacing={1} justifyContent="space-between">
                  <Grid item xs={9}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography sx={{ fontSize: '16px', color: '#816b2b' }}>
                          Expand for full ride details
                        </Typography>
                        <Typography
                          sx={{ fontSize: '16px', fontWeight: 'bold' }}
                        >
                          {motorcycleResult.data
                            ? motorcycleResult.data?.year +
                            ' ' +
                            motorcycleResult.data?.make
                            : null}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sx={{ marginTop: '-10px !important' }}>
                        <Typography
                          sx={{ fontSize: '15px', fontWeight: 'bold' }}
                        >
                          {motorcycleResult.data
                            ? motorcycleResult.data?.model
                            : null}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item justifyContent="flex-end">
                    <IconButton
                      color="primary"
                      onClick={handleClickOpen}
                      aria-label="open dialog"
                    >
                      <ExpandLessOutlinedIcon
                        sx={{
                          fontSize: '40px !important',
                          color: '#000000',
                          borderRadius: '25px',
                          backgroundColor: '#FFEC3B',
                        }}
                      />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    {pricingResult.status === 'fulfilled' &&
                      ROUTING_URLS.includes(locationName?.pathname) ? (
                      <Typography fontWeight="bold">
                        ${props.checkout?.netTotal} (refundable)*
                      </Typography>
                    ) : (
                      '-'
                    )}
                  </Grid>
                </Grid>
              )}
              <BikeInfoDialog
                open={open}
                handleClose={handleClose}
                data={motorcycleResult?.data}
                checkout={props.checkout}
                isMobile={props.isMobile}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  )
}

export default BikeInfoMobile
