import { createSlice } from '@reduxjs/toolkit'
import StepDateTime from '../components/StepDateTime'

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: {
    currentStep: 1,
    maxStep: 5,

    motorcycleId: null, //null, M-79W64XE
    VIN: null, //null, 1HD1FRM1XBB630933
    VIN6: null,

    rideDate: null, // Stored as date string: "2022-01-18"
    dropoffDate: null,
    pickupTime: null,
    dropoffTime: null,
    returnDay: null,
    pickupTimeFormat: null,
    dropoffTimeFormat: null,
    selectedTimeFormat: null,
    blackOutDates: null, // Stored as time string: "00:09"
    partnerWorkingHours: null,
    sameDayRental: false,
    securityDepositAmount: null,
    netTotal: 0,

    motorcycleEndorsement: null,
    above25Years: null,
    authToken: null,
    bikeID: null, //stored as Integer value Ex: 1255

    email: null,
    firstName: null,
    lastName: null,
    password: null,
    isOtpSent: false,
    isMobileConfirmed: false, // TODO should this be here?

    motorcycleStyle: null,
    experienceYears: 0,
    experienceId: null,
    paymentInfo: null,
    paymentsCardUuid: null,
    termsServiceAgreed: false,
    authorizationAgreed: false,
    requestId: null,
    selectedInsurance: null,
    bookedRideUuid: null,

    fetchedLicenseInfo: null,
    license: {
      number: null,
      gender: null,
      firstName: null,
      middleName: null,
      lastName: null,
      birthMonth: null,
      birthDay: null,
      birthYear: null,
      expMonth: null,
      expDay: null,
      expYear: null,
      country: null,
      streetAddress1: null,
      streetAddress2: null,
      aptSuite: null,
      city: null,
      state: null,
      postal: null,
    },

    bikeCategory: null,
    isStyleSelected: false,
    isBookSuccess: false,
    partnerLocation: null,
    isReservationCompleted: false,
    promoCode: '',
    promoCodeMessage: null,
    promoSuccessMessage: '',
    motorcycleTimeZone: null,
    partner_logo_url: null,
    partner_avatar_url: null,

    basic_info: null,
    isVouchedTriggered: false,

    showKlaviyoModal: true,
    source: '',
    klaviyoFormInfo: {}
  },
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload
    },
    setMaxStep: (state, action) => {
      state.maxStep = action.payload
    },
    setPartnerId: (state, action) => {
      state.partnerId = action.payload
    },
    setMotorcycleId: (state, action) => {
      state.motorcycleId = action.payload
    },
    setRideDate: (state, action) => {
      state.rideDate = action.payload
    },
    setDropoffDate: (state, action) => {
      state.dropoffDate = action.payload
    },
    setReturnDay: (state, action) => {
      state.returnDay = action.payload
    },
    setBlackOutDates: (state, action) => {
      state.blackOutDates = action.payload
    },
    setPickupTime: (state, action) => {
      state.pickupTime = action.payload
    },
    setDropoffTime: (state, action) => {
      state.dropoffTime = action.payload
    },
    setPickupTimeFormat: (state, action) => {
      state.pickupTimeFormat = action.payload
    },
    setDropoffTimeFormat: (state, action) => {
      state.dropoffTimeFormat = action.payload
    },
    setOtp: (state, action) => {
      state.isOtpSent = action.payload
    },
    setPaymentInfo: (state, action) => {
      state.paymentInfo = action.payload
    },
    setTermsServiceAgreed: (state, action) => {
      state.termsServiceAgreed = action.payload
    },
    setAuthorizationAgreed: (state, action) => {
      state.authorizationAgreed = action.payload
    },
    setSelectedInsurance: (state, action) => {
      state.selectedInsurance = action.payload
    },
    setSelectedPaymentId: (state, action) => {
      state.paymentsCardUuid = action.payload
    },
    setRiderRequestId: (state, action) => {
      state.requestId = action.payload
    },
    setBookedRideUuid: (state, action) => {
      state.bookedRideUuid = action.payload
    },
    setMotorcycleEndorsement: (state, action) => {
      state.motorcycleEndorsement = action.payload
    },
    setAbove25Years: (state, action) => {
      state.above25Years = action.payload
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload
    },
    setLicenseNumber: (state, action) => {
      state.license.number = action.payload
    },
    setLicenseGender: (state, action) => {
      state.license.gender = action.payload
    },
    setLicenseFirstName: (state, action) => {
      state.license.firstName = action.payload
    },
    setLicenseMiddleName: (state, action) => {
      state.license.middleName = action.payload
    },
    setLicenseLastName: (state, action) => {
      state.license.lastName = action.payload
    },
    setLicenseBirthDay: (state, action) => {
      state.license.birthDay = action.payload
    },
    setLicenseBirthMonth: (state, action) => {
      state.license.birthMonth = action.payload
    },
    setLicenseBirthYear: (state, action) => {
      state.license.birthYear = action.payload
    },
    setLicenseExpMonth: (state, action) => {
      state.license.expMonth = action.payload
    },
    setLicenseExpDay: (state, action) => {
      state.license.expDay = action.payload
    },
    setLicenseExpYear: (state, action) => {
      state.license.expYear = action.payload
    },
    setLicenseExpYear: (state, action) => {
      state.license.expYear = action.payload
    },
    setLicenseCountry: (state, action) => {
      state.license.country = action.payload
    },
    setLicenseStreetAddress1: (state, action) => {
      state.license.streetAddress1 = action.payload
    },
    setLicenseStreetAddress2: (state, action) => {
      state.license.streetAddress2 = action.payload
    },
    setLicenseExpYear: (state, action) => {
      state.license.expYear = action.payload
    },
    setLicenseAptSuite: (state, action) => {
      state.license.aptSuite = action.payload
    },
    setLicenseCity: (state, action) => {
      state.license.city = action.payload
    },
    setLicenseState: (state, action) => {
      state.license.state = action.payload
    },
    setLicensePostalCode: (state, action) => {
      state.license.postal = action.payload
    },
    setBikeCategory: (state, action) => {
      state.bikeCategory = action.payload
    },
    setIsStyleSelected: (state, action) => {
      state.isStyleSelected = action.payload
    },
    setYearsOfExperience: (state, action) => {
      state.experienceYears = action.payload
    },
    setMobileConfirmed: (state, action) => {
      state.isMobileConfirmed = action.payload
    },
    setBookSuccess: (state, action) => {
      state.isBookSuccess = action.payload
    },
    setVin: (state, action) => {
      state.VIN = action.payload
    },
    setVin6: (state, action) => {
      state.VIN6 = action.payload
    },
    setSelectedTimeFormat: (state, action) => {
      state.selectedTimeFormat = action.payload
    },
    setPartnerWorkingHours: (state, action) => {
      state.partnerWorkingHours = action.payload
    },
    setPartnerLocation: (state, action) => {
      state.partnerLocation = action.payload
    },
    setSameDayRental: (state, action) => {
      state.sameDayRental = action.payload
    },
    setSecurityDepositAmount: (state, action) => {
      state.securityDepositAmount = action.payload
    },
    setNetTotal: (state, action) => {
      state.netTotal = action.payload
    },
    setFetchedLicenseInfo: (state, action) => {
      state.fetchedLicenseInfo = action.payload
    },
    setExperienceId: (state, action) => {
      state.experienceId = action.payload
    },
    setIsReservationCompleted: (state, action) => {
      state.isReservationCompleted = action.payload
    },
    setPromoCode: (state, action) => {
      state.promoCode = action.payload
    },
    setPromoCodeMessage: (state, action) => {
      state.promoCodeMessage = action.payload
    },
    setPromoSuccessMessage: (state, action) => {
      state.promoSuccessMessage = action.payload
    },
    setMotorcycleTimeZone: (state, action) => {
      state.motorcycleTimeZone = action.payload
    },
    setPartnerLogoUrl: (state, action) => {
      state.partner_logo_url = action.payload
    },
    setPartnerAvatarUrl: (state, action) => {
      state.partner_avatar_url = action.payload
    },
    setBasicInfo: (state, action) => {
      state.basic_info = action.payload
    },
    setVouchedTriggered: (state, action) => {
      state.isVouchedTriggered = action.payload
    },
    setShowKlaviyoModal: (state, action) => {
      state.showKlaviyoModal = action.payload
    },
    setklaviyoFormInfo: (state, action) => {
      state.klaviyoFormInfo = action.payload
    },
    setBikeID: (state, action) => {
      state.bikeID = action.payload
    },
    setSource: (state, action) => {
      state.source = action.payload
    }
  },
})

export const {
  setCurrentStep,
  setMaxStep,
  setPartnerId,
  setMotorcycleId,
  setRideDate,
  setPickupTime,
  setMotorcycleEndorsement,
  setAbove25Years,
  setAuthToken,
  setBikeCategory,
  setOtp,
  setIsStyleSelected,
  setYearsOfExperience,
  setLicenseNumber,
  setLicenseGender,
  setLicenseFirstName,
  setLicenseMiddleName,
  setLicenseLastName,
  setLicenseBirthDay,
  setLicenseBirthYear,
  setLicenseBirthMonth,
  setLicenseExpDay,
  setLicenseExpMonth,
  setLicenseExpYear,
  setLicenseCountry,
  setLicenseAptSuite,
  setLicenseCity,
  setLicenseStreetAddress1,
  setLicenseStreetAddress2,
  setLicenseState,
  setLicensePostalCode,
  setMobileConfirmed,
  setPaymentInfo,
  setSelectedPaymentId,
  setRiderRequestId,
  setTermsServiceAgreed,
  setAuthorizationAgreed,
  setSelectedInsurance,
  setBookedRideUuid,
  setPickupTimeFormat,
  setBlackOutDates,
  setReturnDay,
  setBookSuccess,
  setVin,
  setVin6,
  setSelectedTimeFormat,
  setDropoffTimeFormat,
  setDropoffDate,
  setPartnerWorkingHours,
  setDropoffTime,
  setPartnerLocation,
  setSameDayRental,
  setSecurityDepositAmount,
  setNetTotal,
  setFetchedLicenseInfo,
  setExperienceId,
  setIsReservationCompleted,
  setPromoCode,
  setPromoCodeMessage,
  setPromoSuccessMessage,
  setMotorcycleTimeZone,
  setPartnerLogoUrl,
  setPartnerAvatarUrl,
  setBasicInfo,
  setVouchedTriggered,
  setShowKlaviyoModal,
  setklaviyoFormInfo,
  setBikeID,
  setSource
} = checkoutSlice.actions

export const checkoutReducer = checkoutSlice.reducer
