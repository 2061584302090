import moment from 'moment-timezone/builds/moment-timezone-with-data'
import { TIME_OPTIONS } from '../components/utils/timeOptions'
import { days } from './weekDays'

export const getWorkingHours = (partnerHours, rideDate) => {
  let originalTimeOptions = null
  const holidaysList = partnerHours?.filter(
    (weekDay) => weekDay.holiday === true
  )
  if (partnerHours === null) {
    return {
      notAvailable: false,
      originalTimeOptions,
      holidaysList
    }
  }
  if (rideDate === null || rideDate === '') {
    return {
      notAvailable: false,
      originalTimeOptions,
      holidaysList
    }
  }
  let isToday = moment(rideDate).isSame(Date.now(), 'day')
  const ridingDayName = moment(rideDate).format('dddd')

  let latestOpeningTime = null
  let earliestClosingTime = null

  partnerHours.forEach(day => {
    if (!day.holiday) {
      const openingTime = moment(day.start_at, 'h:mma')
      const closingTime = moment(day.end_at, 'h:mma')

      if (!latestOpeningTime || openingTime.isAfter(latestOpeningTime)) {
          latestOpeningTime = openingTime
      }

      if (!earliestClosingTime || closingTime.isBefore(earliestClosingTime)) {
          earliestClosingTime = closingTime
      }
    }
  })

  if (latestOpeningTime && earliestClosingTime) {
    latestOpeningTime = latestOpeningTime.format('H')
    earliestClosingTime = earliestClosingTime.format('H')
  }

  if (isToday) {
    let weekDays = partnerHours?.filter((weekDay) => {
      if (
        weekDay.day.toLowerCase() === ridingDayName.toLowerCase() &&
        weekDay.holiday
      ) {
        return weekDay
      } else if (weekDay.day.toLowerCase() === ridingDayName.toLowerCase()) {
        return weekDay
      }
    })
    if (weekDays === undefined || weekDays === null || weekDays[0]?.holiday) {
      return {
        notAvailable: true,
        originalTimeOptions,
        holidaysList,
      }
    } else {
      // let { start_at, end_at } = weekDays[0]
      // start_at = moment().format('H')
      // end_at = moment(end_at, 'h:mm a').format('H')
      // keeping commented code, may require to undo in future
      latestOpeningTime = moment().format('H')
      return {
        notAvailable:
          TIME_OPTIONS.slice(Number(latestOpeningTime) + 1, Number(earliestClosingTime) + 1)
            .length === 0
            ? true
            : false,
        originalTimeOptions: TIME_OPTIONS.slice(
          Number(latestOpeningTime) + 1,
          Number(earliestClosingTime) + 1
        ),
        holidaysList,
      }
    }
  } else {
    let weekDays = partnerHours?.filter((weekDay) => {
      if (
        weekDay.day.toLowerCase() === ridingDayName.toLowerCase() &&
        weekDay.holiday
      ) {
        return weekDay
      } else if (weekDay.day.toLowerCase() === ridingDayName.toLowerCase()) {
        return weekDay
      }
    })
    if (weekDays === undefined || weekDays === null || weekDays[0]?.holiday) {
      return {
        notAvailable: true,
        originalTimeOptions,
        holidaysList,
      }
    } else {
      // let { start_at, end_at } = weekDays[0]
      // start_at = moment(start_at, 'h:mm a').format('H')
      // end_at = moment(end_at, 'h:mm a').format('H')
      // keeping commented code, may require to undo in future
      return {
        notAvailable:
          TIME_OPTIONS.slice(Number(latestOpeningTime), Number(earliestClosingTime) + 1).length === 0
            ? true
            : false,
        originalTimeOptions: TIME_OPTIONS.slice(
          Number(latestOpeningTime),
          Number(earliestClosingTime) + 1
        ),
        holidaysList,
      }
    }
  }
}
