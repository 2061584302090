import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import FlexBox from './../FlexBox'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import StepDateTime from './../StepDateTime'
import RiderExperience from './../RiderExperience'
import AboutYou from './../AboutYou'
import Payments from './../Payments'
import DriverLicense from './../DriverLicense'
import SignUp from './../signup/SignUp'
import LogIn from './../login/LogIn'
import Success from './../Success'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import CircleIcon from '@mui/icons-material/Circle'
import { setCurrentStep, setMaxStep } from './../../data/reducer'
import { useNavigate, useLocation } from 'react-router-dom'
import BrandingMobile from './BrandingMobile'
import {
  StyledBoldSubHeading,
  StyledRadioField,
} from './../styles/StyledElements'
import BikeInfoMobile from './BikeInfoMobile'
import {
  vouchedEnabled,
  driverLicenseBeforeBooking
} from '../vouched/featureFlag'
import Vouched from '../vouched/vouched'

export default function ControlledAccordions(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { checkout } = props
  const { currentStep, maxStep, isReservationCompleted } = checkout
  const [expanded, setExpanded] = useState(false)
  const [showLogin, setShowLogin] = useState(undefined)

  const handleChange = (panel, step) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
    dispatch(setCurrentStep(step))
  }

  useEffect(() => {
    isReservationCompleted ?
      dispatch(setCurrentStep(7)) : dispatch(setCurrentStep(1))
    isReservationCompleted ?
      dispatch(setMaxStep(7)) : dispatch(setMaxStep(1))
    isReservationCompleted ?
      setExpanded('panel7') : setExpanded('panel1')
  }, [])

  useEffect(() => {
    if (showLogin === true) {
      navigate('/checkout/2/login')
    } else if (showLogin === false) {
      navigate('/checkout/2/signup')
    }
  }, [showLogin])

  useEffect(() => {
    switch (location.pathname) {
      case '/checkout/1':
        setExpanded('panel1')
        break
      case '/checkout/2':
        setExpanded('panel2')
        break
      case '/checkout/2/login':
        setExpanded('panel3')
        break
      case '/checkout/2/signup':
        setExpanded('panel3')
        break
      case '/checkout/3':
        setExpanded('panel4')
        break
      case '/checkout/4':
        setExpanded('panel5')
        break
      case '/checkout/5/vouched':
        setExpanded('panel6')
        break
      case '/checkout/5':
        setExpanded('panel6')
        break
      case '/checkout/success':
        setExpanded('panel7')
        break
      default:
    }
  }, [location])

  useEffect(() => {
    switch (currentStep) {
      case 1:
        setExpanded('panel1')
        navigate('/checkout/1')
        dispatch(setMaxStep(1))
        break
      case 2:
        setExpanded('panel2')
        navigate('/checkout/2')
        dispatch(setMaxStep(2))
        break
      case 3:
        setExpanded('panel3')
        navigate('/checkout/2/signup')
        dispatch(setMaxStep(3))
        break
      case 4:
        setExpanded('panel4')
        navigate('/checkout/3')
        dispatch(setMaxStep(4))
        break
      case 5:
        setExpanded('panel5')
        navigate('/checkout/4')
        dispatch(setMaxStep(5))
        break
      case 6:
        setExpanded('panel6')
        if (driverLicenseBeforeBooking() && vouchedEnabled()) {
          navigate('/checkout/5/vouched')
        } else {
          navigate('/checkout/5')
        }
        dispatch(setMaxStep(6))
        break
      default:
        setExpanded('panel7')
        navigate('/checkout/success')
        dispatch(setMaxStep(7))
    }
  }, [currentStep])

  return (
    <>
      <div>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1', 1)}
          disabled={currentStep === 7}
          sx={{
            borderTop: '1px solid white',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#AF985F' }} />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              backgroundColor: '#212121',
            }}
            className='date-picker-field'
          >
            <Typography
              sx={{
                color: expanded === 'panel1' ? '#AF985F' : '#ffffff',
                fontWeight: expanded === 'panel1' ? 'bold' : '500',
              }}
              component={'span'}
            >
              <Grid container alignItems="center">
                {maxStep > 1 ? (
                  <CheckCircleIcon sx={{ color: '#AF985F', marginRight: 1 }} />
                ) : (
                  <CircleIcon sx={{ color: '#AF985F', marginRight: 1 }} />
                )}
                Choose Date & Time
              </Grid>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expanded === 'panel1' && (
              <StepDateTime
                checkout={props.checkout}
                isMobile={props.isMobile}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2', 2)}
          disabled={maxStep < 2 || currentStep === 7}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#AF985F' }} />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
            sx={{
              backgroundColor: '#212121',
            }}
          >
            <Typography
              sx={{
                color: expanded === 'panel2' ? '#AF985F' : '#ffffff',
                fontWeight: expanded === 'panel2' ? 'bold' : '500',
              }}
              component={'span'}
            >
              <Grid container alignItems="center">
                {maxStep > 2 ? (
                  <CheckCircleIcon sx={{ color: '#AF985F', marginRight: 1 }} />
                ) : maxStep === 2 ? (
                  <CircleIcon sx={{ color: '#AF985F', marginRight: 1 }} />
                ) : (
                  <CircleOutlinedIcon
                    sx={{ color: '#AF985F', marginRight: 1 }}
                  />
                )}
                About You
              </Grid>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expanded === 'panel2' && (
              <AboutYou checkout={props.checkout} isMobile={props.isMobile} />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3', 3)}
          disabled={maxStep < 3 || currentStep === 7}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#AF985F' }} />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
            sx={{
              backgroundColor: '#212121',
            }}
          >
            <Typography
              sx={{
                color: expanded === 'panel3' ? '#AF985F' : '#ffffff',
                fontWeight: expanded === 'panel3' ? 'bold' : '500',
              }}
              component={'span'}
            >
              <Grid container alignItems="center">
                {maxStep > 3 ? (
                  <CheckCircleIcon sx={{ color: '#AF985F', marginRight: 1 }} />
                ) : maxStep === 3 ? (
                  <CircleIcon sx={{ color: '#AF985F', marginRight: 1 }} />
                ) : (
                  <CircleOutlinedIcon
                    sx={{ color: '#AF985F', marginRight: 1 }}
                  />
                )}
                Sign Up
              </Grid>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <BrandingMobile />
            <Grid item xs={12} md={12}>
              <FlexBox
                direction={props.isMobile ? 'column' : 'row'}
                alignItems="flex-start"
              >
                <StyledBoldSubHeading color="#212121">
                  Sign in with a Twisted Road account for a faster experience or
                  create an account if you’re new! Do you have a Twisted Road
                  account?
                </StyledBoldSubHeading>
                &nbsp;&nbsp;
                <FlexBox direction="row" alignItems="center">
                  <StyledRadioField
                    name="yes"
                    checked={showLogin === true}
                    onChange={(e) => setShowLogin(true)}
                    type="radio"
                  />
                  &nbsp;
                  <StyledBoldSubHeading>Yes&nbsp;&nbsp;</StyledBoldSubHeading>
                  <StyledRadioField
                    name="no"
                    checked={showLogin === false}
                    onChange={(e) => setShowLogin(false)}
                    type="radio"
                  />
                  &nbsp;
                  <StyledBoldSubHeading>No &nbsp;</StyledBoldSubHeading>
                </FlexBox>
              </FlexBox>
            </Grid>
            <hr className="mobileHr" />
            {showLogin === false && (
              <SignUp checkout={props.checkout} isMobile={props.isMobile} />
            )}
            {showLogin === true && (
              <LogIn checkout={props.checkout} isMobile={props.isMobile} />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4', 4)}
          disabled={maxStep < 4 || currentStep === 7}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#AF985F' }} />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
            sx={{
              backgroundColor: '#212121',
            }}
          >
            <Typography
              sx={{
                color: expanded === 'panel4' ? '#AF985F' : '#ffffff',
                fontWeight: expanded === 'panel4' ? 'bold' : '500',
              }}
              component={'span'}
            >
              <Grid container alignItems="center">
                {maxStep > 4 ? (
                  <CheckCircleIcon sx={{ color: '#AF985F', marginRight: 1 }} />
                ) : maxStep === 4 ? (
                  <CircleIcon sx={{ color: '#AF985F', marginRight: 1 }} />
                ) : (
                  <CircleOutlinedIcon
                    sx={{ color: '#AF985F', marginRight: 1 }}
                  />
                )}
                Your Experience
              </Grid>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expanded === 'panel4' && (
              <RiderExperience
                checkout={props.checkout}
                isMobile={props.isMobile}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5', 5)}
          disabled={maxStep < 5 || currentStep === 7}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#AF985F' }} />}
            aria-controls="panel5bh-content"
            id="panel5bh-header"
            sx={{
              backgroundColor: '#212121',
            }}
          >
            <Typography
              sx={{
                color: expanded === 'panel5' ? '#AF985F' : '#ffffff',
                fontWeight: expanded === 'panel5' ? 'bold' : '500',
              }}
              component={'span'}
            >
              <Grid container alignItems="center">
                {maxStep > 5 ? (
                  <CheckCircleIcon sx={{ color: '#AF985F', marginRight: 1 }} />
                ) : maxStep === 5 ? (
                  <CircleIcon sx={{ color: '#AF985F', marginRight: 1 }} />
                ) : (
                  <CircleOutlinedIcon
                    sx={{ color: '#AF985F', marginRight: 1 }}
                  />
                )}
                Payments
              </Grid>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expanded === 'panel5' && (
              <Payments checkout={props.checkout} isMobile={props.isMobile} />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel6'}
          onChange={handleChange('panel6', 6)}
          disabled={maxStep < 6 || currentStep === 7}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#AF985F' }} />}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
            sx={{
              backgroundColor: '#212121',
            }}
          >
            <Typography
              sx={{
                color: expanded === 'panel6' ? '#AF985F' : '#ffffff',
                fontWeight: expanded === 'panel6' ? 'bold' : '500',
              }}
              component={'span'}
            >
              <Grid container alignItems="center">
                {maxStep > 6 ? (
                  <CheckCircleIcon sx={{ color: '#AF985F', marginRight: 1 }} />
                ) : maxStep === 6 ? (
                  <CircleIcon sx={{ color: '#AF985F', marginRight: 1 }} />
                ) : (
                  <CircleOutlinedIcon
                    sx={{ color: '#AF985F', marginRight: 1 }}
                  />
                )}
                Drivers License
              </Grid>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expanded === 'panel6' && location.pathname === '/checkout/5/vouched' ?
              (<Vouched checkout={props.checkout} isMobile={props.isMobile} />)
              :
              (
                <DriverLicense
                  checkout={props.checkout}
                  isMobile={props.isMobile}
                />
              )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel7'}
          onChange={handleChange('panel7', 7)}
          disabled={maxStep < 7}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#AF985F' }} />}
            aria-controls="panel7bh-content"
            id="panel7bh-header"
            sx={{
              backgroundColor: '#212121',
            }}
          >
            <Typography
              sx={{
                color: expanded === 'panel7' ? '#AF985F' : '#ffffff',
                fontWeight: expanded === 'panel7' ? 'bold' : '500',
              }}
              component={'span'}
            >
              <Grid container alignItems="center">
                {maxStep > 6 ? (
                  <CheckCircleIcon sx={{ color: '#AF985F', marginRight: 1 }} />
                ) : (
                  <CircleOutlinedIcon
                    sx={{ color: '#AF985F', marginRight: 1 }}
                  />
                )}
                Reservation Complete
              </Grid>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expanded === 'panel7' && (
              <Success checkout={props.checkout} isMobile={props.isMobile} />
            )}
          </AccordionDetails>
        </Accordion>
      </div>
      {currentStep < 7 && (
        <BikeInfoMobile checkout={props.checkout} isMobile={props.isMobile} />
      )}
    </>
  )
}
