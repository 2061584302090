import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import {
  Dialog,
  Grid,
} from '@mui/material'
import {
  Button,
  StyledH1,
  StyledH2,
  StyledInputField,
  StyledErrorLabel,
  StyledInputFieldWrapper,
  StyledPhoneInput,
  StyledSignUPWrapper,
  StyledPickUpInfo

} from "../../styles/StyledElements"
import {
  StyledKlaviyoBikeWrapper,
  StyledKaviyoContentWrapper,
  StyledKlaviyoBikeImg
} from "../../styles/klaviyo/index"
import FlexBox from "../../FlexBox"
import { klaviyoFormSchema } from '../../validations/klaviyoFormValidation'
import {
  setShowKlaviyoModal,
  setklaviyoFormInfo
} from '../../../data/reducer'
import { subscribeUser } from '../../elements/klaviyoTracking/userSignup'
import { useScheduleLeadCommunicationMutation } from '../../../data/services/hobnob'

const KlaviyoModal = (props) => {
  const { isMobile } = props
  const checkout = useSelector(({ checkout }) => checkout)
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(klaviyoFormSchema),
  })
  const dispatch = useDispatch()
  const [scheduleLeadCommunication, scheduleLeadCommunicationResult] = useScheduleLeadCommunicationMutation()

  useEffect(() => {
    if(scheduleLeadCommunicationResult.status === 'fulfilled') {
      console.log('Scheduled lead communication successfully')
    } else {
      console.log('Failed to schedule lead communication', scheduleLeadCommunicationResult)
    }
  }, [scheduleLeadCommunicationResult])

  const onSubmit = async (data) => {
    const { firstName, lastName, email, phoneInput, } = data
    let mobile = phoneInput?.replaceAll(' ','')
    subscribeUser({
      email: email,
      firstName: firstName,
      lastName: lastName,
      phone: mobile,
      source: "Extended Test Ride",
      vin: checkout.VIN || null,
      },
      () => {
        dispatch(setklaviyoFormInfo({
          email: email, firstName: firstName, lastName: lastName, phone: mobile
        }))
        dispatch(setShowKlaviyoModal(false))
        scheduleLeadCommunication({
          email: email,
          phone: mobile,
          first_name: firstName,
          last_name: lastName,
          motorcycle_id: checkout.bikeID,
          notes: `${firstName} shown interest in TestRide motorcycle. Bike id is ${checkout.bikeID}`
        })
      }
    )
  }

  const closeModal = (event, reason) => {
    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown")) {
      return
    } else {
      dispatch(setShowKlaviyoModal(false))
    }
  }

  return (
    <React.Fragment>
      {checkout.showKlaviyoModal &&
        <Dialog
          open={checkout.showKlaviyoModal}
          onClose={closeModal}
          aria-labelledby="responsive-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 4,
            }}
          >
            <CloseIcon />
          </IconButton>
          <FlexBox flexWrap={ isMobile ? 'wrap' : ''}>
            <StyledKlaviyoBikeWrapper
              width={isMobile ? '100%' : '200px'}
              height={isMobile ? '300px' : ''}
            >
              <StyledKlaviyoBikeImg
                src="https://res.cloudinary.com/twisted-road/image/upload/c_auto,g_auto,w_350/v1719416208/frontend_assets/Extended_Test_Ride_Modal_Image.png"
                alt="Klaviyo Bike"
              />
            </StyledKlaviyoBikeWrapper>
            <StyledKaviyoContentWrapper>
              <DialogContent>
                <FlexBox direction='column' alignItems='center'>
                  <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
                    <StyledH1 marginTop="4px" marginBottom={isMobile ? '0.5rem' : '1rem'} fontSize={isMobile ? '26px' : ''}>
                      Try Before You Buy!
                    </StyledH1>
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
                    <StyledH2 marginBottom={isMobile ? '0.5rem' : '1rem'} fontSize={isMobile ? '18px' : '22px'}>
                      Take this bike overnight.
                    </StyledH2>
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
                    <StyledPickUpInfo>
                      Put some saddle time in and make sure it's
                      the right bike fore you.
                    </StyledPickUpInfo>
                  </DialogContentText>
                  <FlexBox direction='column' marginTop='16px'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <FlexBox>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <StyledInputFieldWrapper paddingBottom='0px'>
                              <StyledInputField
                                type="text"
                                name="firstName"
                                placeholder="First name"
                                width={'100%'}
                                height={'40px'}
                                color={errors.firstName?.message}
                                rules={{ required: true }}
                                {...register('firstName')}
                              />
                              <StyledErrorLabel>
                                {errors.firstName?.message}
                              </StyledErrorLabel>
                            </StyledInputFieldWrapper>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <StyledInputFieldWrapper>
                              <StyledInputField
                                type="text"
                                name="lastName"
                                placeholder="Last name"
                                width={'100%'}
                                height={'40px'}
                                color={errors.lastName?.message}
                                {...register('lastName')}
                              />
                              <StyledErrorLabel>
                                {errors.lastName?.message}
                              </StyledErrorLabel>
                            </StyledInputFieldWrapper>
                          </Grid>
                        </Grid>
                      </FlexBox>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <StyledInputFieldWrapper>
                            <StyledInputField
                              type="email"
                              name="email"
                              placeholder="Enter your email address"
                              width={'100%'}
                              height={'40px'}
                              color={errors.email?.message}
                              {...register('email')}
                            />
                            <StyledErrorLabel>{errors.email?.message}</StyledErrorLabel>
                          </StyledInputFieldWrapper>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <StyledInputFieldWrapper>
                            {/* <Controller
                              name="phoneInput"
                              control={control}
                              render={({ field: { onChange } }) => (
                                <StyledPhoneInput
                                  international
                                  placeholder="Phone number"
                                  defaultCountry="US"
                                  onChange={onChange}
                                  {...register('phoneInput')}
                                />
                              )}
                            /> */}
                            <StyledPhoneInput
                              international
                              name="phoneInput"
                              defaultCountry="US"
                              control={control}
                            />
                            <StyledErrorLabel>{errors.phoneInput?.message}</StyledErrorLabel>
                          </StyledInputFieldWrapper>
                        </Grid>
                      </Grid>
                      <StyledSignUPWrapper>
                        <Button
                          type="submit"
                          marginTop={'1rem'}
                          padding={'0.60rem 2.5rem'}
                          twistedGold
                          twistedDarkBlack
                        >
                          Let's Ride!
                        </Button>
                      </StyledSignUPWrapper>
                    </form>
                  </FlexBox>
                </FlexBox>
              </DialogContent>
            </StyledKaviyoContentWrapper>
          </FlexBox>
        </Dialog>
      }
    </React.Fragment>
  )
}
 
export default KlaviyoModal