import { NavLink } from 'react-router-dom'
import {
  StyledCheckoutLists,
  CheckoutItems,
} from '../components/styles/StyledElements'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import CircleIcon from '@mui/icons-material/Circle'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import FlexBox from './FlexBox'

const CheckoutSteps = (props) => {
  const { checkout } = props
  const { currentStep, maxStep, isReservationCompleted } = checkout

  return (
    <>
      <StyledCheckoutLists>
        <CheckoutItems>
          <FlexBox direction="row" justify="left">
            {currentStep === 1 ? (
              <CircleIcon />
            ) : currentStep > 1 ? (
              <CheckCircleRoundedIcon />
            ) : (
              <CircleOutlinedIcon />
            )}
            <li className={isReservationCompleted ? 'checkout-steps-disabled' : ''}>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive || currentStep > 1 ? '#AF985F' : '',
                  fontWeight: isActive || currentStep > 1 ? 'bold' : '',
                  opacity: isActive || currentStep > 1 ? '1' : '',
                })}
                to="/checkout/1"
              >
                Choose Date & Time
              </NavLink>
            </li>
          </FlexBox>
        </CheckoutItems>
        <CheckoutItems>
          <FlexBox direction="row" justify="left">
            {currentStep === 2 ? (
              <CircleIcon />
            ) : currentStep > 2 ? (
              <CheckCircleRoundedIcon />
            ) : (
              <CircleOutlinedIcon />
            )}
            <li className={isReservationCompleted ||
              currentStep <= 1 ? 'checkout-steps-disabled' : ''}>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive || currentStep > 2 ? '#AF985F' : '',
                  fontWeight: isActive || currentStep > 2 ? 'bold' : '',
                  opacity: isActive || currentStep > 2 ? '1' : '',
                })}
                to="/checkout/2"
              >
                About You
              </NavLink>
            </li>
          </FlexBox>
        </CheckoutItems>
        <CheckoutItems>
          <FlexBox direction="row" justify="left">
            {currentStep === 3 ? (
              <CircleIcon />
            ) : currentStep > 3 ? (
              <CheckCircleRoundedIcon />
            ) : (
              <CircleOutlinedIcon />
            )}
            <li className={isReservationCompleted ||
              currentStep <= 2 ? 'checkout-steps-disabled' : ''}>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive || currentStep > 3 ? '#AF985F' : '',
                  fontWeight: isActive || currentStep > 3 ? 'bold' : '',
                  opacity: isActive || currentStep > 3 ? '1' : '',
                })}
                to="/checkout/3"
              >
                Your Experience
              </NavLink>
            </li>
          </FlexBox>
        </CheckoutItems>
        <CheckoutItems>
          <FlexBox direction="row" justify="left">
            {currentStep === 4 ? (
              <CircleIcon />
            ) : currentStep > 4 ? (
              <CheckCircleRoundedIcon />
            ) : (
              <CircleOutlinedIcon />
            )}
            <li className={isReservationCompleted ||
              currentStep <= 3 ? 'checkout-steps-disabled' : ''}>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive || currentStep > 4 ? '#AF985F' : '',
                  fontWeight: isActive || currentStep > 4 ? 'bold' : '',
                  opacity: isActive || currentStep > 4 ? '1' : '',
                })}
                to="/checkout/4"
              >
                Payments
              </NavLink>
            </li>
          </FlexBox>
        </CheckoutItems>
        <CheckoutItems>
          <FlexBox direction="row" justify="left">
            {currentStep === 5 ? (
              <CircleIcon />
            ) : currentStep > 5 ? (
              <CheckCircleRoundedIcon />
            ) : (
              <CircleOutlinedIcon />
            )}
            <li className={isReservationCompleted ||
              currentStep <= 4 ? 'checkout-steps-disabled' : ''}>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive || currentStep > 5 ? '#AF985F' : '',
                  fontWeight: isActive || currentStep > 5 ? 'bold' : '',
                  opacity: isActive || currentStep > 5 ? '1' : '',
                })}
                to={
                  checkout.isVouchedTriggered ?
                    "/checkout/5/vouched" : "/checkout/5"
                }
              >
                Driver License
              </NavLink>
            </li>
          </FlexBox>
        </CheckoutItems>
      </StyledCheckoutLists>
    </>
  )
}

export default CheckoutSteps
