import {
  DRIVER_LICENSE_BEFORE_BOOKING,
  USE_VOUCHED
} from '../../constants/index'

export const driverLicenseBeforeBooking = () => {
  return DRIVER_LICENSE_BEFORE_BOOKING === 'true'
}

export const vouchedEnabled = () => {
  return USE_VOUCHED === 'true'
}