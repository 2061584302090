import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import FlexBox from './FlexBox'
import {
  Button,
  StyledH1,
  StyledSubTitle,
  StyledParagraph,
  StyledBoldSubHeading,
  StyledH4,
  Image,
  StyledRadioField,
} from './styles/StyledElements'
import {
  setAbove25Years,
  setMotorcycleEndorsement,
  setCurrentStep,
} from '../data/reducer'
import { useNavigate } from 'react-router-dom'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import BrandingMobile from './mobile/BrandingMobile'
import BrandingDesktop from './utils/BrandingDesktop'
import { loading } from './utils/loading'
import { scrollToTop } from './utils/scrollTop'
import Box from '@mui/material/Box'
import Grid from '@material-ui/core/Grid'

const AboutYou = (props) => {
  const { checkout, isMobile } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showAbove25, setShowAbove25Modal] = useState(false)
  const [showEndorsement, setShowEndorsementModal] = useState(false)
  const [showBoth, setShowBothModal] = useState(false)

  useEffect(() => {
    if (checkout.isReservationCompleted) {
      navigate('/checkout/success')
    } else if (!(checkout.currentStep >= 2)) {
      navigate(`/checkout/${checkout.currentStep}`)
    }
  }, [])

  const handleClose = () => {
    if (
      checkout.above25Years === false &&
      checkout.motorcycleEndorsement === false
    ) {
      setShowBothModal(false)
      window.open('https://www.twistedroad.com', '_self')
    } else if (checkout.above25Years === false) {
      setShowAbove25Modal(false)
      window.open('https://www.twistedroad.com', '_self')
    } else if (checkout.motorcycleEndorsement === false) {
      setShowEndorsementModal(false)
      window.open('https://www.twistedroad.com', '_self')
    }
  }

  const handleNav = () => {
    if (
      checkout.above25Years === false &&
      checkout.motorcycleEndorsement === false
    ) {
      setShowBothModal(true)
    } else if (checkout.above25Years === false) {
      setShowAbove25Modal(true)
    } else if (checkout.motorcycleEndorsement === false) {
      setShowEndorsementModal(true)
    } else {
      dispatch(setCurrentStep(3))
      if (isMobile) { scrollToTop() }
      navigate('/checkout/2/signup')
    }
  }

  return (
    <>
      <FlexBox direction="column">
        {isMobile ? <BrandingMobile /> : <BrandingDesktop />}
        {!isMobile && (
          <StyledSubTitle marginBottom="1.5rem">
            Before we begin tell us about yourself
          </StyledSubTitle>
        )}
        {isMobile && (
          <Box
            sx={{
              fontSize: '21px',
              fontWeight: 'bold',
              marginBottom: '15px',
            }}
          >
            Before we begin tell us about yourself
          </Box>
        )}
        <FlexBox
          direction={isMobile ? 'column' : 'row'}
          alignItems="flex-start"
          marginBottom="2rem"
        >
          <Grid container direction="row">
            <Grid item xs={12} md={12}>
              <StyledBoldSubHeading>
                Do you have a motorcycle endorsement on your license?
              </StyledBoldSubHeading>
            </Grid>
            <Grid item xs={12} md={12}>
              <FlexBox
                direction="row"
                alignItems={'center'}
                marginTop={isMobile ? '10px' : '15px !important'}
              >
                <StyledRadioField
                  name="motorcycleEndorsementYes"
                  value={checkout?.motorcycleEndorsement ?
                    checkout?.motorcycleEndorsement : ''
                  }
                  onChange={(e) => dispatch(setMotorcycleEndorsement(true))}
                  type="radio"
                  checked={checkout.motorcycleEndorsement ? true : false}
                />
                <StyledBoldSubHeading marginLeft="5px" marginRight="20px">
                  Yes
                </StyledBoldSubHeading>
                <StyledRadioField
                  name="motorcycleEndorsementNo"
                  value={checkout?.motorcycleEndorsement ?
                    checkout?.motorcycleEndorsement : ''
                  }
                  onChange={(e) => {
                    dispatch(setMotorcycleEndorsement(false))
                  }}
                  type="radio"
                  checked={checkout.motorcycleEndorsement === false}
                />
                <StyledBoldSubHeading marginLeft="5px">No</StyledBoldSubHeading>
              </FlexBox>
            </Grid>
          </Grid>
        </FlexBox>
        <FlexBox
          direction={isMobile ? 'column' : 'row'}
          alignItems={'flex-start'}
        >
          <Grid container direction="row">
            <Grid item xs={12} md={12}>
              <StyledBoldSubHeading>
                Are you 25+ years of age?
              </StyledBoldSubHeading>
            </Grid>
            <Grid item xs={12} md={12}>
              <FlexBox
                direction="row"
                alignItems={'center'}
                marginTop={isMobile ? '10px' : '15px !important'}
              >
                <StyledRadioField
                  name="above25YearsYes"
                  value={checkout?.above25Years
                    ? checkout?.above25Years : ''
                  }
                  onChange={(e) => dispatch(setAbove25Years(true))}
                  type="radio"
                  checked={checkout.above25Years ? true : false}
                />
                <StyledBoldSubHeading marginLeft="5px" marginRight="20px">
                  Yes{' '}
                </StyledBoldSubHeading>
                <StyledRadioField
                  name="above25YearsNo"
                  value={checkout?.above25Years ?
                    checkout?.above25Years : ''
                  }
                  onChange={(e) => {
                    dispatch(setAbove25Years(false))
                  }}
                  type="radio"
                  checked={checkout.above25Years === false}
                />
                <StyledBoldSubHeading marginLeft="5px">No</StyledBoldSubHeading>
              </FlexBox>
            </Grid>
          </Grid>
        </FlexBox>
        <FlexBox>
          <Button
            marginTop={'2rem'}
            twistedGold
            twistedDarkBlack
            disabled={
              checkout.above25Years === null ||
              checkout.motorcycleEndorsement === null
            }
            onClick={() => {
              handleNav()
            }}
          >
            Next
          </Button>
        </FlexBox>
      </FlexBox>
      {showBoth &&
        <div>
          <Dialog
            open={showBoth === true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <FlexBox
              direction="column"
              alignItems="center"
              justify="center"
              textAlign="center"
            >
              <FlexBox
                direction="row"
                alignItems="center"
                marginTop={isMobile ? '0.5rem' : '1.5rem'}
                marginBottom={isMobile ? '0rem' : '1rem'}
              >
                <Image
                  src={checkout.partner_avatar_url ?
                    checkout.partner_avatar_url :
                    loading.TRY_ME_MODAL_IMAGE
                  }
                  width="147px"
                  height="147px"
                  objectFit="cover"
                />
              </FlexBox>
              <DialogTitle id="alert-dialog-title">
                <StyledSubTitle marginBottom="-0.5rem">Sorry</StyledSubTitle>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <StyledH4 twistedBlack>
                    Unfortunately, you will not be able to participate in the
                    extended test ride. You need to be over 25 years of age and
                    have a motorcycle endorsement.
                  </StyledH4>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  padding={'0.5em 3em'}
                  twistedGold
                  twistedGoldDark
                  twistedDarkBlack
                  onClick={handleClose}
                  disabled={false}
                >
                  I Understand
                </Button>
              </DialogActions>
            </FlexBox>
          </Dialog>
        </div>}
      {showAbove25 &&
        <div>
          <Dialog
            open={showAbove25 === true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <FlexBox
              direction="column"
              alignItems="center"
              justify="center"
              textAlign="center"
            >
              <FlexBox
                direction="row"
                alignItems="center"
                marginTop={isMobile ? '0.5rem' : '1.5rem'}
                marginBottom={isMobile ? '0rem' : '1rem'}
              >
                <Image
                  src={checkout.partner_avatar_url ?
                    checkout.partner_avatar_url :
                    loading.TRY_ME_MODAL_IMAGE
                  }
                  width="147px"
                  height="147px"
                  objectFit="cover"
                />
              </FlexBox>
              <DialogTitle id="alert-dialog-title">
                <StyledSubTitle marginBottom="-0.5rem">Sorry</StyledSubTitle>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <StyledH4 twistedBlack>
                    Unfortunately, you will not be able to participate in the
                    extended test ride. You must be over 25 years of age.
                  </StyledH4>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  padding={'0.5em 3em'}
                  twistedGold
                  twistedGoldDark
                  twistedDarkBlack
                  onClick={handleClose}
                  disabled={false}
                >
                  I Understand
                </Button>
              </DialogActions>
            </FlexBox>
          </Dialog>
        </div>}
      {showEndorsement &&
        <div>
          <Dialog
            open={showEndorsement === true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <FlexBox
              direction="column"
              alignItems="center"
              justify="center"
              textAlign="center"
            >
              <FlexBox
                direction="row"
                alignItems="center"
                marginTop={isMobile ? '0.5rem' : '1.5rem'}
                marginBottom={isMobile ? '0rem' : '1rem'}
              >
                <Image
                  src={checkout.partner_avatar_url ?
                    checkout.partner_avatar_url :
                    loading.TRY_ME_MODAL_IMAGE
                  }
                  width="147px"
                  height="147px"
                  objectFit="cover"
                />
              </FlexBox>
              <FlexBox direction="row" alignItems="center" marginTop="-0.5rem">
                <StyledH1 marginTop="1rem">Extended Test Ride</StyledH1>
              </FlexBox>
              <DialogTitle id="alert-dialog-title">
                <StyledSubTitle marginBottom="-0.5rem">Sorry</StyledSubTitle>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <StyledH4 twistedBlack>
                    Unfortunately, you will not be able to participate in the
                    extended test ride. You need to have a motorcycle endorsement.
                  </StyledH4>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  padding={'0.5em 3em'}
                  twistedGold
                  twistedGoldDark
                  twistedDarkBlack
                  onClick={handleClose}
                  disabled={false}
                >
                  I Understand
                </Button>
              </DialogActions>
            </FlexBox>
          </Dialog>
        </div>}
    </>
  )
}

export default AboutYou
