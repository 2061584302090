import * as Yup from 'yup'

export const signUpValidationSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .email('Email address is not valid.')
    .required('Email is required.'),
  firstName: Yup.string()
    .nullable()
    .required('First Name is required.'),
  lastName: Yup.string()
    .nullable()
    .required('Last Name is required.'),
  phoneInput: Yup.string()
    .nullable()
    .required('Mobile Number is Required'),
  password: Yup.string()
    .nullable()
    .min(12, 'Password must be at least 12 characters.')
    .required('Password is required.'),
  acceptedTerms: Yup.string().nullable().oneOf(['true'],
    'You must agree to our terms of service.')
})
