import { useDispatch } from 'react-redux'
import FlexBox from './FlexBox'
import moment from 'moment-timezone/builds/moment-timezone-with-data'
import {
  Button,
  StyledSubTitle,
  StyledSelect,
  StyledOptions,
  StyledBoldSubHeading,
  StyledErrorLabel,
  StyledItalic,
  StyledRadioField,
} from './styles/StyledElements'
import {
  setDropoffTimeFormat,
  setDropoffDate,
  setCurrentStep,
  setPickupTime,
  setRideDate,
  setReturnDay,
  setSelectedTimeFormat,
  setSameDayRental,
} from '../data/reducer'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { getWorkingHours } from '../constants/getWorkingHours'
import BrandingMobile from './mobile/BrandingMobile'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import EventIcon from '@mui/icons-material/Event'
import { parseISO } from 'date-fns'
import BrandingDesktop from './utils/BrandingDesktop'
import { scrollToTop } from './utils/scrollTop'

const useStyles = makeStyles((theme) => ({
  datePickerStyle: {
    '& .MuiOutlinedInput-root': {
      color: '#000000',
      width: '173px',
      fontWeight: 'bold',
      '& fieldset': {
        borderRadius: '8px',
        color: '#FFF',
        border: '1px solid #8B8B8B',
      },
      '& .MuiOutlinedInput-input': {
        color: '#000000',
        fontSize: '16px',
      },
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #AF985F',
    },
    '& .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
    {
      border: '1px solid #8B8B8B',
    },
    '& .Mui-focused .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
      color: '#000000',
      fontWeight: 'bold',
    },
  },
}))

const StepDateTime = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const classes = useStyles()
  const { checkout, isMobile } = props
  const { originalTimeOptions, notAvailable, holidaysList } = useMemo(
    () => getWorkingHours(checkout.partnerWorkingHours, checkout.rideDate),
    [checkout.partnerWorkingHours, checkout.rideDate]
  )
  const [valid, setValid] = useState(false)
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [holidayExist, setHolidayExist] = useState(false)
  const [blackoutDayExist, setBlackoutDayExist] = useState(false)

  useEffect(() => {
    if (checkout.isReservationCompleted) {
      navigate('/checkout/success')
    }
  }, [])

  useEffect(() => {
    setValid(
      [
        checkout.rideDate !== null,
        checkout.rideDate !== '',
        checkout.pickupTime !== null,
        checkout.pickupTime !== '',
      ].every(Boolean)
    )
  }, [checkout.rideDate, checkout.pickupTime])

  const getDisabledDates = (date) => {
    let dayIsBlocked = false
    checkout?.partnerWorkingHours?.forEach(entry => {
      if (entry.day === moment(date).format('dddd') &&
        entry.holiday) {
        dayIsBlocked = true
      }
    })
    checkout?.blackOutDates?.forEach(item => {
      let itemFormatted = moment(date).format('YYYY-MM-DD')
      if (itemFormatted === item) {
        dayIsBlocked = true
      }
    })

    return dayIsBlocked
  }

  const getReturnTiming = () => {
    if (checkout.returnDay === 'today') {
      if (originalTimeOptions?.length === 1) {
        dispatch(setDropoffTimeFormat(originalTimeOptions[0]?.text))
        return originalTimeOptions[0].text
      } else {
        dispatch(
          setDropoffTimeFormat(
            originalTimeOptions[originalTimeOptions?.length - 1]?.text
          )
        )
        return originalTimeOptions[originalTimeOptions?.length - 1]?.text
      }
    } else if (checkout.returnDay === 'tomorrow') {
      let dropoffTiming = moment(checkout.selectedTimeFormat, 'h:mm a')
        .add(24, 'hours')
        .format('h:mma')
      dispatch(setDropoffTimeFormat(dropoffTiming))
      return dropoffTiming
    }
  }

  const getReturnDate = () => {
    if (checkout.returnDay === 'today') {
      dispatch(setDropoffDate(moment(checkout.rideDate).format('YYYY-MM-DD')))
      return moment(checkout.rideDate).format('MM-DD-YYYY')
    } else if (checkout.returnDay === 'tomorrow') {
      dispatch(
        setDropoffDate(
          moment(checkout.rideDate).add(1, 'days').format('YYYY-MM-DD')
        )
      )
      return moment(checkout.rideDate).add(1, 'days').format('MM-DD-YYYY')
    }
  }

  const checkValidDate = () => {
    if (checkout.blackOutDates?.includes(checkout.rideDate)) {
      return true
    }
  }

  const confirmSameDayRental = (newDate) => {
    const selectedDate = moment(newDate).format('YYYY-MM-DD')
    const isToday = moment(selectedDate).isSame(Date.now(), 'day')
    dispatch(setSameDayRental(isToday))
  }

  const checkForHoliday = (newDate) => {
    const nextDay = moment(newDate).add(1, 'd')
    const returnDayName = moment(nextDay).format('dddd')
    holidaysList?.forEach((weekday) => {
      if (weekday.day === returnDayName) {
        setHolidayExist(true)
        dispatch(setReturnDay('today'))
        getReturnDate()
        return
      }
    })
    if (checkout.blackOutDates?.includes(
      moment(nextDay).format("YYYY-MM-DD")
    )) {
      setBlackoutDayExist(true)
      dispatch(setReturnDay('today'))
      getReturnDate()
      return
    }
    setBlackoutDayExist(false)
  }

  return (
    <FlexBox direction="column">
      {isMobile ? <BrandingMobile /> : <BrandingDesktop />}
      <StyledSubTitle marginBottom="1.5rem">Choose Date & Time</StyledSubTitle>
      <StyledBoldSubHeading marginBottom="0.5rem" color="#212121">
        What day do you want to ride?
      </StyledBoldSubHeading>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDatePicker
                disableCloseOnSelect={false}
                disablePast
                value={checkout.rideDate ? parseISO(checkout.rideDate) : null}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                shouldDisableDate={getDisabledDates}
                onChange={(newValue) => {
                  confirmSameDayRental(newValue)
                  setHolidayExist(false)
                  setBlackoutDayExist(false)
                  dispatch(setRideDate(moment(newValue).format('YYYY-MM-DD')))
                  checkForHoliday(newValue)
                }}
                renderInput={(params) => (
                  <TextField
                    className={classes.datePickerStyle}
                    placeholder="Choose Date"
                    autoComplete="off"
                    {...params}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end" onClick={handleOpen}>
                            <EventIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <StyledErrorLabel>
                {checkValidDate()
                  ? "Motorcycle isn't available on the selected date. Please choose another date."
                  : null}
              </StyledErrorLabel>
            </Grid>
          </Grid>
        </div>
      </div>
      <StyledBoldSubHeading
        marginBottom="0.5rem"
        marginTop="1.5rem"
        color="#212121"
      >
        Select Pickup Time
      </StyledBoldSubHeading>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <StyledSelect
              bold
              type="select"
              name="pickup-time"
              width={'173px'}
              height={'46px'}
              backgroundColor="red"
              disabled={checkout.rideDate === null || checkout.rideDate === ''}
              value={checkout?.selectedTimeFormat ?
                checkout?.selectedTimeFormat : ''
              }
              onChange={(e) => {
                dispatch(
                  setPickupTime(
                    e.target.value.replace('am', '').replace('pm', '')
                  )
                )
                dispatch(setSelectedTimeFormat(e.target.value))
                if (e.target.value === '') {
                  dispatch(setReturnDay(null))
                }
              }}
            >
              <StyledOptions value="" hidden>
                {notAvailable ? '' : 'Choose Time'}
              </StyledOptions>
              {originalTimeOptions?.map((item, index) => (
                <StyledOptions key={index} value={item.text}>
                  {item.text}
                </StyledOptions>
              ))}
            </StyledSelect>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <StyledErrorLabel>
              {notAvailable
                ? 'There are no time slots available on the chosen day to pick up the motorcycle. Please choose another date.'
                : null}
            </StyledErrorLabel>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <StyledBoldSubHeading
              marginBottom="1.5rem"
              marginTop="1.5rem"
              color="#212121"
            >
              You have up to 24 hours for your Extended Test Ride.
            </StyledBoldSubHeading>
          </Grid>
        </Grid>
        <Grid container direction={isMobile ? 'column' : 'row'}>
          <FlexBox
            direction={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'flex-start' : 'normal'}
            alignContent="center"
          >
            <Grid container direction="row">
              <Grid item xs={12} md={12}>
                <StyledBoldSubHeading color="#212121">
                  {checkout.samedayRental
                    ? 'Would you like to return the bike today or tomorrow?'
                    : 'Would you like to return the bike on the same day or the next day?'}
                </StyledBoldSubHeading>
              </Grid>
              <Grid item xs={12} md={12}>
                <FlexBox
                  direction="row"
                  alignItems="self-start"
                  marginTop="15px !important"
                >
                  <StyledRadioField
                    name="returnToday"
                    disabled={
                      checkout.pickupTime === null ||
                      checkout.pickupTime === '' ||
                      notAvailable
                    }
                    value={checkout?.returnDay ?
                      checkout?.returnDay : ''
                    }
                    checked={checkout.returnDay === 'today' ? true : false}
                    onChange={(e) => dispatch(setReturnDay('today'))}
                    type="radio"
                  />
                  <StyledBoldSubHeading marginLeft="5px" marginRight="20px">
                    Same Day
                  </StyledBoldSubHeading>
                  <StyledRadioField
                    name="returnTomorrow"
                    disabled={
                      checkout.pickupTime === null ||
                      checkout.pickupTime === '' ||
                      holidayExist ||
                      notAvailable ||
                      blackoutDayExist
                    }
                    value={checkout?.returnDay ?
                      checkout?.returnDay : ''
                    }
                    checked={checkout.returnDay === 'tomorrow' ? true : false}
                    onChange={(e) => dispatch(setReturnDay('tomorrow'))}
                    type="radio"
                  />
                  <StyledBoldSubHeading marginLeft="5px">
                    Next Day
                  </StyledBoldSubHeading>
                </FlexBox>
              </Grid>
            </Grid>
          </FlexBox>
        </Grid>
      </div>
      {holidayExist && (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <StyledItalic marginTop="0.7rem" color="red">
                Next day return unavailable: Dealership closed. Choose Same Day return or select an alternative Pickup Time.
              </StyledItalic>
            </Grid>
          </Grid>
        </div>
      )}
      {blackoutDayExist && !holidayExist && (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <StyledItalic marginTop="0.7rem" color="red">
                Next day return unavailable: Motorcycle has been booked on that day. Choose Same Day return or select an alternative Pickup Time.
              </StyledItalic>
            </Grid>
          </Grid>
        </div>
      )}
      {checkout.returnDay && originalTimeOptions && (
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <StyledItalic marginTop="1.5rem">
                You have until {getReturnTiming()} on {getReturnDate()} to
                return the bike.
              </StyledItalic>
            </Grid>
          </Grid>
        </div>
      )}
      <FlexBox>
        <Button
          marginTop={isMobile ? '2rem' : '8rem'}
          twistedGold
          twistedDarkBlack
          disabled={
            !valid || checkout.returnDay === null
            || notAvailable || checkValidDate()
          }
          onClick={() => {
            dispatch(setCurrentStep(2))
            if (isMobile) { scrollToTop() }
            navigate('/checkout/2')
          }}
        >
          Next
        </Button>
      </FlexBox>
    </FlexBox>
  )
}

export default StepDateTime
