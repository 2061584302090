import React from 'react'
import { MAPBOX_API_KEY } from '../../constants/index'
import mapboxgl from 'mapbox-gl'
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker'
import ReactMapboxGl, { ZoomControl, GeoJSONLayer } from 'react-mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import * as turf from '@turf/turf'
import geojsonStyles from './geoJsonStyles'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

mapboxgl.workerClass = MapboxWorker

const Map = ReactMapboxGl({
  accessToken: MAPBOX_API_KEY
});

function TestrideMapBox(props) {
  const { booking } = props?.checkout?.data

  const {
    search_latitude,
    search_longitude,
    city
  } = booking?.location

  const centerPoint = [Number(search_longitude), Number(search_latitude)];

  var radius = 0.1;

  var options = {
    steps: 50,
    units: "miles",
    properties: {
      text: city
    }
  };

  const firstCircle = turf.circle(centerPoint, radius, options);
  if (search_latitude !== null || search_latitude !== null) {
    return (
      <Map
        style="mapbox://styles/mapbox/streets-v9"
        containerStyle={{
          height: '189px',
          width: '100%'
        }}
        center={[Number(search_longitude), Number(search_latitude)]}
        zoom={[15]}
      >
        <ZoomControl
          position="top-left"
        />
        <GeoJSONLayer {...geojsonStyles} data={firstCircle} />
      </Map>
    );
  } else {
    return null
  }
}

export default TestrideMapBox