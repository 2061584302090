import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import FlexBox from './FlexBox'
import moment from 'moment-timezone/builds/moment-timezone-with-data'
import {
  Button,
  StyledSubTitle,
  StyledParagraph,
  StyledBoldSubHeading,
  StyledInputFieldWrapper,
  StyledFieldLabel,
  StyledInputField,
  StyledSelect,
  StyledOptions,
  StyledErrorLabel,
  StyledAbbr,
  Image,
} from './styles/StyledElements'
import {
  setLicenseNumber,
  setLicenseGender,
  setLicenseFirstName,
  setLicenseMiddleName,
  setLicenseLastName,
  setLicenseBirthDay,
  setLicenseBirthYear,
  setLicenseBirthMonth,
  setLicenseExpDay,
  setLicenseExpMonth,
  setLicenseExpYear,
  setLicenseCountry,
  setLicenseAptSuite,
  setLicenseCity,
  setLicenseStreetAddress1,
  setLicenseState,
  setLicensePostalCode,
  setRiderRequestId,
  setBookedRideUuid,
  setCurrentStep,
  setFetchedLicenseInfo,
  setIsReservationCompleted
} from '../data/reducer'
import {
  useRiderDocumentMutation,
  useCreateRequestMutation,
  useBookRequestMutation,
  useGetLicenceInfoQuery,
} from '../data/services/hobnob'
import BrandingMobile from './mobile/BrandingMobile'
import BrandingDesktop from './utils/BrandingDesktop'
import Grid from '@material-ui/core/Grid'
import { licenseValidationSchema } from './validations/LicenseValidation'
import { loading } from './utils/loading'
import {
  BIRTH_MONTH_OPTIONS,
  DATE_OPTIONS,
  YEAR_BIRTH_OPTIONS,
  YEAR_OPTIONS,
} from '../components/utils/licenseFieldOptions'
import { countries } from './utils/countryCodesData'

function DriverLicense(props) {
  const { checkout, isMobile } = props
  const fetchDocumentInfo = useGetLicenceInfoQuery()
  const [riderDocument, riderDocumentResult] = useRiderDocumentMutation()
  const [createRequest, createRequestResult] = useCreateRequestMutation()
  const [bookRequest, bookRequestResult] = useBookRequestMutation()
  const [documentsError, setDocumentsError] = useState(null)
  const [createRequestError, setCreateRequestError] = useState(null)
  const [bookRequestError, setBookRequestError] = useState(null)
  const [bookLoading, setBookLoading] = useState(false)
  const [iscreateCalled, setIsCreateCalled] = useState(false)
  const [isCreateSuccess, setIsCreateSuccess] = useState(false)
  const [isBookSuccess, setBookSuccess] = useState(false)
  const [formSubmit, setFormSubmit] = useState(false)
  const [requestUuId, setRequestUuId] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(licenseValidationSchema),
  })

  useEffect(() => {
    if (
      fetchDocumentInfo.status === 'fulfilled' &&
      checkout.license?.number === null &&
      fetchDocumentInfo.data?.drivers_license?.license_no
    ) {
      const dob =
        fetchDocumentInfo.data?.drivers_license?.date_of_birth?.split('-')
      const expiration_dob =
        fetchDocumentInfo.data?.drivers_license?.expiration_date?.split('-')
      const {
        first_name,
        gender,
        last_name,
        license_no,
        license_state,
        middle_name,
      } = fetchDocumentInfo.data?.drivers_license
      const { address_street_1, address_street_2, city, country, postal_code } =
        fetchDocumentInfo.data?.drivers_license?.location
      dispatch(
        setFetchedLicenseInfo({
          number: license_no,
          gender: gender,
          firstName: first_name,
          middleName: middle_name,
          lastName: last_name,
          birthMonth: dob?.[1],
          birthDay: dob?.[2],
          birthYear: dob?.[0],
          expMonth: expiration_dob?.[1],
          expDay: expiration_dob?.[2],
          expYear: expiration_dob?.[0],
          country: country,
          streetAddress1: address_street_1,
          streetAddress2: address_street_2,
          aptSuite: null,
          city: city,
          state: license_state,
          postal: postal_code,
        })
      )
      reset({
        license_number: license_no,
        license_first_name: first_name,
        license_middle_name: middle_name,
        license_last_name: last_name,
        license_birth_month: dob?.[1],
        license_birth_day: dob?.[2],
        license_birth_year: dob?.[0],
        license_expiration_month: expiration_dob?.[1],
        license_expiration_day: expiration_dob?.[2],
        license_expiration_year: expiration_dob?.[0],
        address_country: country,
        address_line_1: address_street_1,
        address_city: city,
        address_state: license_state,
        address_postal_code: postal_code,
      })
    }
    if (isBookSuccess) {
      if (isMobile === true) {
        dispatch(setCurrentStep(7))
        dispatch(setIsReservationCompleted(true))
        navigate('/checkout/success')
      } else {
        dispatch(setCurrentStep(6))
        dispatch(setIsReservationCompleted(true))
        navigate('/checkout/success')
      }
    } else if (checkout.isReservationCompleted) {
      navigate('/checkout/success')
    } else if (checkout.currentStep < 5) {
      navigate(`/checkout/${checkout.currentStep}`)
    } else if (isCreateSuccess) {
      try {
        bookRequest({
          requestUuid: requestUuId,
          rideDate: checkout.rideDate,
          pickupTime: checkout.selectedTimeFormat,
          paymentsCardUuid: checkout.paymentsCardUuid,
          termsServiceAgreed: checkout.termsServiceAgreed,
          riderInsurance: checkout.selectedInsurance,
          promotionCode: checkout.promoCode,
          dropoffTime: checkout.dropoffTimeFormat,
          dropoffDate: moment(props.checkout?.dropoffDate).format('YYYY-MM-DD'),
          timeZone: checkout.motorcycleTimeZone,
        })
      } catch (error) {
        console.log('book-error', error)
      }
    } else if (iscreateCalled) {
      try {
        createRequest({
          motorcycleId: checkout.motorcycleId,
          rideDate: checkout.rideDate,
          pickupTime: checkout.selectedTimeFormat,
          dropoffTime: checkout.dropoffTimeFormat,
          dropoffDate: checkout.dropoffDate,
          timeZone: checkout.motorcycleTimeZone,
        })
      } catch (error) {
        console.log('create-req-error', error)
      }
    }
  }, [isBookSuccess, requestUuId, iscreateCalled, fetchDocumentInfo])

  if (bookRequestResult.status === 'fulfilled' && !isBookSuccess) {
    dispatch(setBookedRideUuid(bookRequestResult.data?.request_uuid))
    setBookLoading(false)
    setBookSuccess(true)
  }

  if (bookRequestResult.status === 'rejected' && bookRequestError === null) {
    setBookLoading(false)
    const { error } = bookRequestResult
    setBookRequestError(
      error.data.error_messages?.length > 0 ?
        error.data.error_messages[0] : "Something went wrong."
    )
    dispatch(setRiderRequestId(null))
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }

  if (createRequestResult.status === 'fulfilled' && requestUuId === null) {
    const { data } = createRequestResult
    dispatch(setRiderRequestId(data.id))
    setBookLoading(false)
    setRequestUuId(data.request_uuid)
    setIsCreateSuccess(true)
  }

  if (
    createRequestResult.status === 'rejected' &&
    createRequestError === null
  ) {
    const { error } = createRequestResult
    setBookLoading(false)
    setCreateRequestError(
      error.data.error_messages?.length > 0 ?
        error.data.error_messages[0] : "Something went wrong."
    )
  }

  if (riderDocumentResult.status === 'fulfilled' && !iscreateCalled) {
    setIsCreateCalled(true)
  }

  if (riderDocumentResult.status === 'rejected' && documentsError === null) {
    setBookLoading(false)
    const { error } = riderDocumentResult
    setDocumentsError(error.data?.error_messages[0])
  }

  useEffect(() => {
    if (formSubmit) {
      setIsCreateCalled(false)
      const {
        number,
        gender,
        firstName,
        middleName,
        lastName,
        birthMonth,
        birthDay,
        birthYear,
        expMonth,
        expDay,
        expYear,
        country,
        streetAddress1,
        streetAddress2,
        aptSuite,
        city,
        state,
        postal,
      } = checkout.license
      const dob_year = birthYear
        ? birthYear
        : checkout.fetchedLicenseInfo?.birthYear
      const dob_month = birthMonth
        ? birthMonth
        : checkout.fetchedLicenseInfo?.birthMonth
      const dob_day = birthDay
        ? birthDay
        : checkout.fetchedLicenseInfo?.birthDay
      const license_dob_year = expYear
        ? expYear
        : checkout.fetchedLicenseInfo?.expYear
      const license_dob_month = expMonth
        ? expMonth
        : checkout.fetchedLicenseInfo?.expMonth
      const license_dob_day = expDay
        ? expDay
        : checkout.fetchedLicenseInfo?.expDay
      try {
        riderDocument({
          licenseNo: number ? number : checkout.fetchedLicenseInfo?.number,
          firstName: firstName
            ? firstName
            : checkout.fetchedLicenseInfo?.firstName,
          middleName: middleName
            ? middleName
            : checkout.fetchedLicenseInfo?.middleName,
          lastName: lastName ? lastName : checkout.fetchedLicenseInfo?.lastName,
          gender: gender ? gender : checkout.fetchedLicenseInfo?.gender,
          dob: dob_year + '-' + dob_month + '-' + dob_day,
          licenseState: state ? state : checkout.fetchedLicenseInfo?.state,
          licenseCountry: country
            ? country
            : checkout.fetchedLicenseInfo?.country,
          licenseExpiry:
            license_dob_year + '-' + license_dob_month + '-' + license_dob_day,
          addressCity: city ? city : checkout.fetchedLicenseInfo?.city,
          addressCountry: country
            ? country
            : checkout.fetchedLicenseInfo?.country,
          addressState: state ? state : checkout.fetchedLicenseInfo?.state,
          addressStateAbbr: state ? state : checkout.fetchedLicenseInfo?.state,
          addressStreet1: streetAddress1
            ? streetAddress1
            : checkout.fetchedLicenseInfo?.streetAddress1,
          addressStreet2: streetAddress2
            ? streetAddress2
            : checkout.fetchedLicenseInfo?.streetAddress2,
          addressZip: postal ? postal : checkout.fetchedLicenseInfo?.postal,
        })
      } catch (error) {
        console.log('create-license-error', error)
      }
    }
  }, [formSubmit])

  const onSubmit = (data) => {
    setBookLoading(true)
    setDocumentsError(null)
    setCreateRequestError(null)
    setBookRequestError(null)
    setFormSubmit(true)
  }

  const gender = [
    { key: 'Male', value: 'Male', text: 'Male' },
    { key: 'Female', value: 'Female', text: 'Female' },
  ]

  return (
    <FlexBox direction="column" style={{ width: '100%' }}>
      {isMobile ? <BrandingMobile /> : <BrandingDesktop />}
      <StyledSubTitle marginBottom="1.5rem">License Information</StyledSubTitle>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="on">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <StyledInputFieldWrapper paddingBottom="0rem">
              <StyledFieldLabel>Driving License No.</StyledFieldLabel>
              <StyledInputField
                type="text"
                name="license_number"
                placeholder="Driving License"
                width={'100%'}
                height={'40px'}
                color={errors.license_number?.message}
                {...register('license_number')}
                value={
                  checkout.license?.number
                    ? checkout.license?.number
                    : checkout.fetchedLicenseInfo?.number
                      ? checkout.fetchedLicenseInfo?.number
                      : ''
                }
                onChange={(e) => {
                  dispatch(setLicenseNumber(e.target.value))
                }}
              />
              <StyledErrorLabel>
                {errors.license_number?.message}
              </StyledErrorLabel>
            </StyledInputFieldWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledInputFieldWrapper>
              <StyledFieldLabel>Gender</StyledFieldLabel>
              <StyledSelect
                type="select"
                name="license_gender"
                width={'100%'}
                height={'40px'}
                color={errors.license_gender?.message}
                {...register('license_gender')}
                value={
                  checkout.license?.gender
                    ? checkout.license?.gender
                    : checkout.fetchedLicenseInfo?.gender
                      ? checkout.fetchedLicenseInfo?.gender
                      : ''
                }
                onChange={(e) => {
                  dispatch(setLicenseGender(e.target.value))
                }}
              >
                <StyledOptions value="" hidden>
                  Choose Gender
                </StyledOptions>
                {gender.map((item, index) => (
                  <StyledOptions key={index} value={item.text}>
                    {item.text}
                  </StyledOptions>
                ))}
              </StyledSelect>
              <StyledErrorLabel>
                {errors.license_gender?.message}
              </StyledErrorLabel>
            </StyledInputFieldWrapper>
          </Grid>
        </Grid>
        <FlexBox direction="row" marginBottom="0rem">
          <StyledBoldSubHeading>Name&nbsp;</StyledBoldSubHeading>
          <StyledParagraph marginBottom="1rem">
            Please enter your name as it appears on your license
          </StyledParagraph>
        </FlexBox>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <StyledInputFieldWrapper paddingBottom="0rem">
              <StyledFieldLabel>First Name</StyledFieldLabel>
              <StyledInputField
                type="text"
                name="license_first_name"
                placeholder="First"
                width={'100%'}
                height={'40px'}
                color={errors.license_first_name?.message}
                {...register('license_first_name')}
                value={
                  checkout.license?.firstName
                    ? checkout.license?.firstName
                    : checkout.fetchedLicenseInfo?.firstName
                      ? checkout.fetchedLicenseInfo?.firstName
                      : ''
                }
                onChange={(e) => {
                  dispatch(setLicenseFirstName(e.target.value))
                }}
              />
              <StyledErrorLabel>
                {errors.license_first_name?.message}
              </StyledErrorLabel>
            </StyledInputFieldWrapper>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledInputFieldWrapper paddingBottom="0rem">
              <StyledFieldLabel>Middle Name</StyledFieldLabel>
              <StyledInputField
                type="text"
                name="license_middle_name"
                placeholder="Middle"
                width={'100%'}
                height={'40px'}
                {...register('license_middle_name')}
                value={
                  checkout.license?.middleName
                    ? checkout.license?.middleName
                    : checkout.fetchedLicenseInfo?.middleName
                      ? checkout.fetchedLicenseInfo?.middleName
                      : ''
                }
                onChange={(e) => {
                  dispatch(setLicenseMiddleName(e.target.value))
                }}
              />
            </StyledInputFieldWrapper>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledInputFieldWrapper>
              <StyledFieldLabel>Last Name</StyledFieldLabel>
              <StyledInputField
                type="text"
                name="license_last_name"
                placeholder="Last"
                width={'100%'}
                height={'40px'}
                color={errors.license_last_name?.message}
                {...register('license_last_name')}
                value={
                  checkout.license?.lastName
                    ? checkout.license?.lastName
                    : checkout.fetchedLicenseInfo?.lastName
                      ? checkout.fetchedLicenseInfo?.lastName
                      : ''
                }
                onChange={(e) => {
                  dispatch(setLicenseLastName(e.target.value))
                }}
              />
              <StyledErrorLabel>
                {errors.license_last_name?.message}
              </StyledErrorLabel>
            </StyledInputFieldWrapper>
          </Grid>
        </Grid>
        <FlexBox direction="row">
          <StyledBoldSubHeading marginBottom={'1rem'}>
            Birthdate{' '}
          </StyledBoldSubHeading>
        </FlexBox>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <StyledInputFieldWrapper paddingBottom="0rem">
              <StyledFieldLabel>Month</StyledFieldLabel>
              <StyledSelect
                type="select"
                name="license_birth_month"
                width={'100%'}
                height={'40px'}
                color={errors.license_birth_month?.message}
                {...register('license_birth_month')}
                value={
                  checkout.license?.birthMonth
                    ? checkout.license?.birthMonth
                    : checkout.fetchedLicenseInfo?.birthMonth
                      ? checkout.fetchedLicenseInfo?.birthMonth
                      : ''
                }
                onChange={(e) => {
                  dispatch(setLicenseBirthMonth(e.target.value))
                }}
              >
                <StyledOptions value="" hidden>
                  Birth Month
                </StyledOptions>
                {BIRTH_MONTH_OPTIONS.map((item, index) => (
                  <StyledOptions key={index} value={item.value}>
                    {item.text}
                  </StyledOptions>
                ))}
              </StyledSelect>
              <StyledErrorLabel>
                {errors.license_birth_month?.message}
              </StyledErrorLabel>
            </StyledInputFieldWrapper>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledInputFieldWrapper paddingBottom="0rem">
              <StyledFieldLabel>Day</StyledFieldLabel>
              <StyledSelect
                type="select"
                name="license_birth_day"
                width={'100%'}
                height={'40px'}
                color={errors.license_birth_day?.message}
                {...register('license_birth_day')}
                value={
                  checkout.license?.birthDay
                    ? checkout.license?.birthDay
                    : checkout.fetchedLicenseInfo?.birthDay
                      ? checkout.fetchedLicenseInfo?.birthDay
                      : ''
                }
                onChange={(e) => {
                  dispatch(setLicenseBirthDay(e.target.value))
                }}
              >
                <StyledOptions value="" hidden>
                  Birth Day
                </StyledOptions>
                {DATE_OPTIONS.map((item, index) => (
                  <StyledOptions key={index} value={item.value}>
                    {item.text}
                  </StyledOptions>
                ))}
              </StyledSelect>
              <StyledErrorLabel>
                {errors.license_birth_day?.message}
              </StyledErrorLabel>
            </StyledInputFieldWrapper>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledInputFieldWrapper>
              <StyledFieldLabel>Year</StyledFieldLabel>
              <StyledSelect
                type="select"
                name="license_birth_year"
                width={'100%'}
                height={'40px'}
                color={errors.license_birth_year?.message}
                {...register('license_birth_year')}
                value={
                  checkout.license?.birthYear
                    ? checkout.license?.birthYear
                    : checkout.fetchedLicenseInfo?.birthYear
                      ? checkout.fetchedLicenseInfo?.birthYear
                      : ''
                }
                onChange={(e) => {
                  dispatch(setLicenseBirthYear(e.target.value))
                }}
              >
                <StyledOptions value="" hidden>
                  Birth Year
                </StyledOptions>
                {YEAR_BIRTH_OPTIONS.map((item, index) => (
                  <StyledOptions key={index} value={item.value}>
                    {item.text}
                  </StyledOptions>
                ))}
              </StyledSelect>
              <StyledErrorLabel>
                {errors.license_birth_year?.message}
              </StyledErrorLabel>
            </StyledInputFieldWrapper>
          </Grid>
        </Grid>
        <FlexBox direction="row">
          <StyledBoldSubHeading marginBottom="1rem">
            Expiration Date
          </StyledBoldSubHeading>
        </FlexBox>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <StyledInputFieldWrapper paddingBottom="0rem">
              <StyledFieldLabel>Month</StyledFieldLabel>
              <StyledSelect
                type="select"
                name="license_expiration_month"
                width={'100%'}
                height={'40px'}
                color={errors.license_expiration_month?.message}
                {...register('license_expiration_month')}
                value={
                  checkout.license?.expMonth
                    ? checkout.license?.expMonth
                    : checkout.fetchedLicenseInfo?.expMonth
                      ? checkout.fetchedLicenseInfo?.expMonth
                      : ''
                }
                onChange={(e) => {
                  dispatch(setLicenseExpMonth(e.target.value))
                }}
              >
                <StyledOptions value="" hidden>
                  Expiration Month
                </StyledOptions>
                {BIRTH_MONTH_OPTIONS.map((item, index) => (
                  <StyledOptions key={index} value={item.value}>
                    {item.text}
                  </StyledOptions>
                ))}
              </StyledSelect>
              <StyledErrorLabel>
                {errors.license_expiration_month?.message}
              </StyledErrorLabel>
            </StyledInputFieldWrapper>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledInputFieldWrapper paddingBottom="0rem">
              <StyledFieldLabel>Day</StyledFieldLabel>
              <StyledSelect
                type="select"
                name="license_expiration_day"
                width={'100%'}
                height={'40px'}
                color={errors.license_expiration_day?.message}
                {...register('license_expiration_day')}
                value={
                  checkout.license?.expDay
                    ? checkout.license?.expDay
                    : checkout.fetchedLicenseInfo?.expDay
                      ? checkout.fetchedLicenseInfo?.expDay
                      : ''
                }
                onChange={(e) => {
                  dispatch(setLicenseExpDay(e.target.value))
                }}
              >
                <StyledOptions value="" hidden>
                  Expiration Day
                </StyledOptions>
                {DATE_OPTIONS.map((item, index) => (
                  <StyledOptions key={index} value={item.value}>
                    {item.text}
                  </StyledOptions>
                ))}
              </StyledSelect>
              <StyledErrorLabel>
                {errors.license_expiration_day?.message}
              </StyledErrorLabel>
            </StyledInputFieldWrapper>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledInputFieldWrapper>
              <StyledFieldLabel>Year</StyledFieldLabel>
              <StyledSelect
                type="select"
                name="license_expiration_year"
                width={'100%'}
                height={'40px'}
                color={errors.license_expiration_year?.message}
                {...register('license_expiration_year')}
                value={
                  checkout.license?.expYear
                    ? checkout.license?.expYear
                    : checkout.fetchedLicenseInfo?.expYear
                      ? checkout.fetchedLicenseInfo?.expYear
                      : ''
                }
                onChange={(e) => {
                  dispatch(setLicenseExpYear(e.target.value))
                }}
              >
                <StyledOptions value="" hidden>
                  Expiration Year
                </StyledOptions>
                {YEAR_OPTIONS.map((item, index) => (
                  <StyledOptions key={index} value={item.value}>
                    {item.text}
                  </StyledOptions>
                ))}
              </StyledSelect>
              <StyledErrorLabel>
                {errors.license_expiration_year?.message}
              </StyledErrorLabel>
            </StyledInputFieldWrapper>
          </Grid>
        </Grid>
        <FlexBox direction="row">
          <StyledBoldSubHeading marginBottom={'1rem'}>
            Address&nbsp;
          </StyledBoldSubHeading>
          <StyledParagraph marginBottom="1rem">
            Enter your address as it appears on your license
          </StyledParagraph>
        </FlexBox>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <StyledInputFieldWrapper paddingBottom={isMobile ? '0rem' : '18px'}>
              <StyledFieldLabel>Country</StyledFieldLabel>
              <StyledSelect
                type="select"
                name="address_country"
                width={'100%'}
                height={'40px'}
                color={errors.address_country?.message}
                {...register('address_country')}
                value={
                  checkout.license?.country
                    ? checkout.license?.country
                    : checkout.fetchedLicenseInfo?.country
                      ? checkout.fetchedLicenseInfo?.country
                      : ''
                }
                onChange={(e) => {
                  dispatch(setLicenseCountry(e.target.value))
                }}
              >
                <StyledOptions value="" hidden>
                  Choose Country
                </StyledOptions>
                {countries.map((item, index) => (
                  <StyledOptions key={index} value={item.name}>
                    {item.name}
                  </StyledOptions>
                ))}
              </StyledSelect>
              <StyledErrorLabel>
                {errors.address_country?.message}
              </StyledErrorLabel>
            </StyledInputFieldWrapper>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <StyledInputFieldWrapper paddingBottom={isMobile ? '0rem' : '18px'}>
              <StyledFieldLabel>Street Address</StyledFieldLabel>
              <StyledInputField
                type="text"
                name="address_line_1"
                placeholder="Street Address"
                width={'100%'}
                height={'40px'}
                color={errors.address_line_1?.message}
                {...register('address_line_1')}
                value={
                  checkout.license?.streetAddress1
                    ? checkout.license?.streetAddress1
                    : checkout.fetchedLicenseInfo?.streetAddress1
                      ? checkout.fetchedLicenseInfo?.streetAddress1
                      : ''
                }
                onChange={(e) => {
                  dispatch(setLicenseStreetAddress1(e.target.value))
                }}
              />
              <StyledErrorLabel>
                {errors.address_line_1?.message}
              </StyledErrorLabel>
            </StyledInputFieldWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledInputFieldWrapper paddingBottom={isMobile ? '0rem' : '18px'}>
              <StyledFieldLabel>Apt Suite (Optional)</StyledFieldLabel>
              <StyledInputField
                type="number"
                name="apt_suite"
                placeholder="Apt Number"
                width={'100%'}
                height={'40px'}
                {...register('apt_suite')}
                value={
                  checkout.license?.aptSuite
                    ? checkout.license?.aptSuite
                    : checkout.fetchedLicenseInfo?.aptSuite
                      ? checkout.fetchedLicenseInfo?.aptSuite
                      : ''
                }
                onChange={(e) => {
                  dispatch(setLicenseAptSuite(e.target.value))
                }}
              />
            </StyledInputFieldWrapper>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <StyledInputFieldWrapper paddingBottom={isMobile ? '0rem' : '18px'}>
              <StyledFieldLabel>City</StyledFieldLabel>
              <StyledInputField
                type="text"
                name="address_city"
                placeholder="City"
                width={'100%'}
                height={'40px'}
                color={errors.address_city?.message}
                {...register('address_city')}
                value={
                  checkout.license?.city
                    ? checkout.license?.city
                    : checkout.fetchedLicenseInfo?.city
                      ? checkout.fetchedLicenseInfo?.city
                      : ''
                }
                onChange={(e) => {
                  dispatch(setLicenseCity(e.target.value))
                }}
              />
              <StyledErrorLabel>
                {errors.address_city?.message}
              </StyledErrorLabel>
            </StyledInputFieldWrapper>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledInputFieldWrapper paddingBottom={isMobile ? '0rem' : '18px'}>
              <StyledFieldLabel>State</StyledFieldLabel>
              <StyledInputField
                type="text"
                name="address_state"
                placeholder="State"
                width={'100%'}
                height={'40px'}
                color={errors.address_state?.message}
                {...register('address_state')}
                value={
                  checkout.license?.state
                    ? checkout.license?.state
                    : checkout.fetchedLicenseInfo?.state
                      ? checkout.fetchedLicenseInfo?.state
                      : ''
                }
                onChange={(e) => {
                  dispatch(setLicenseState(e.target.value))
                }}
              />
              <StyledErrorLabel>
                {errors.address_state?.message}
              </StyledErrorLabel>
            </StyledInputFieldWrapper>
          </Grid>
          <Grid item xs={12} md={4}>
            <StyledInputFieldWrapper>
              <StyledFieldLabel>Postal Code</StyledFieldLabel>
              <StyledInputField
                type="number"
                name="address_postal_code"
                placeholder="Postal Code"
                width={'100%'}
                height={'40px'}
                color={errors.address_postal_code?.message}
                {...register('address_postal_code')}
                value={
                  checkout.license?.postal
                    ? checkout.license?.postal
                    : checkout.fetchedLicenseInfo?.postal
                      ? checkout.fetchedLicenseInfo?.postal
                      : ''
                }
                onChange={(e) => {
                  dispatch(setLicensePostalCode(e.target.value))
                }}
              />
              <StyledErrorLabel>
                {errors.address_postal_code?.message}
              </StyledErrorLabel>
            </StyledInputFieldWrapper>
          </Grid>
        </Grid>
        <StyledErrorLabel>{documentsError}</StyledErrorLabel>
        <StyledErrorLabel>{createRequestError}</StyledErrorLabel>
        <StyledErrorLabel>{bookRequestError}</StyledErrorLabel>
        <FlexBox>
          <Button
            type="submit"
            marginTop={isMobile ? '-0.5rem' : '1rem'}
            marginBottom={isMobile ? '3.7rem' : '0.7rem'}
            twistedGold
            twistedDarkBlack
            disabled={bookLoading ? true : false}
          >
            {bookLoading ? (
              <FlexBox direction="row">
                <Image src={loading.LOADING} width="25px" height="25px" />
              </FlexBox>
            ) : (
              'Complete Reservation'
            )}
          </Button>
        </FlexBox>
      </form>
      {!isMobile && (
        <StyledAbbr>
          * The test ride fee covers insurance costs and wear and tear on the
          motorcycle during the Extended Test Ride period. This fee is fully
          refundable if you choose to purchase the bike.
        </StyledAbbr>
      )}
    </FlexBox>
  )
}

export default DriverLicense
