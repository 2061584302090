import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded'
import FlexBox from '../FlexBox'
import {
  Button,
  StyledSubTitle,
  StyledErrorLabel,
} from '../styles/StyledElements'


function WrapUpModal(props) {
  const {
    isMobile,
    wrapUpModalOpen,
    showSuccess,
    checkoutError,
    checkoutErrorMessage,
    onError
  } = props

  const closeModal = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return
    }
  }
  return (
    <div>
      <Dialog
        open={wrapUpModalOpen}
        onClose={() => closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <FlexBox
          direction="column"
          alignItems="center"
          justify="center"
          textAlign="center"
          padding="0.5rem 2.5rem"

        >
          <FlexBox
            direction="column"
            alignItems="center"
            marginTop={isMobile ? '0.5rem' : '1rem'}
            padding="0.5rem 2.5rem"
          >
            <StyledSubTitle marginBottom="0.5rem">
              {!checkoutError
                ? "Oops!"
                : !showSuccess
                  ? "Wrapping up!"
                  : "Your reservation is booked successfully."}
            </StyledSubTitle>

            <DialogContentText id="alert-dialog-description">
              {!checkoutError
                ? checkoutErrorMessage?.map((message, index) => (
                  <>
                    {message &&
                      <StyledErrorLabel fontSize="16">
                        <li>{message}</li>
                      </StyledErrorLabel>
                    }
                  </>
                ))
                : !showSuccess
                  ? <img
                    src="https://res.cloudinary.com/twisted-road/image/upload/c_fill,g_center,q_auto,f_auto/v1572480187/frontend_assets/search/searchNoResultsLoader.gif"
                    alt="Persona Scripts Loading"
                  />
                  : <VerifiedRoundedIcon
                    sx={[{ fontSize: "4rem", color: "#008000" }]}
                  />
              }
            </DialogContentText>
          </FlexBox>
          {!checkoutError &&
            <DialogActions className="vouched-dialog-action">
              <Button
                padding={'0.5em 3em'}
                twistedGold
                twistedGoldDark
                twistedDarkBlack
                onClick={() => {
                  onError()
                  window.open("https://www.twistedroad.com", "_self")
                }}
                disabled={false}
              >
                Got it
              </Button>
            </DialogActions>
          }
        </FlexBox>
      </Dialog>
    </div>
  );
}

export default WrapUpModal