export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

export const BASE_API_URL = process.env.REACT_APP_API_URL

export const AIRBRAKE_API_KEY = process.env.REACT_APP_AIRBRAKE_API_KEY

export const AIRBRAKE_PROJECT_ID = process.env.REACT_APP_AIRBRAKE_PROJECT_ID

export const MAPBOX_API_KEY = process.env.REACT_APP_MAP_BOX_API_KEY

export const TIME_ZONE = 'America/Chicago'

export const BUILD_ENV = process.env.REACT_APP_ENV

export const GOOGLE_RECAPTCHA_V3_TOKEN = process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_SITE_KEY

export const DRIVER_LICENSE_BEFORE_BOOKING = process.env.REACT_APP_DRIVERS_LICENSE_BEFORE_BOOKING

export const USE_VOUCHED = process.env.REACT_APP_USE_VOUCHED

export const KLAVIYO_PUBLIC_API_KEY = process.env.REACT_APP_KLAVIYO_PUBLIC_API_KEY

export const PRICING_OPTIONS = {
  roadside_assistance: false,
  tire_protection: false,
  use_all_free_days: false,
  use_all_twisted_road_credits: false,
  booked_through_dealer_testride: true
}

export const BLACKLISTED_ERRORS = [
  'Blocked a frame with origin "https://www.twistedroad.com" from accessing a cross-origin frame. Protocols, domains, and ports must match.',
  'GEOCODER_GEOCODE: ERROR: A geocoding request could not be processed due to a server error. The request may succeed if you try again.'
]
