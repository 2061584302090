import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import VouchedModal from '../modal/vouchedModal'

function Vouched(props) {
  const { checkout, isMobile } = props
  const navigate = useNavigate()
  useEffect(() => {
    if (checkout.isVouchedTriggered) {
      navigate('/checkout/5/vouched')
    }
  }, [])
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <VouchedModal checkout={checkout} isMobile={isMobile} />
    </div>
  )
}

export default Vouched