import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import FlexBox from './FlexBox'
import Grid from '@material-ui/core/Grid'
import {
  StyledSubTitle,
  StyledParagraph,
  StyledH4,
  Image,
  StyledBikename,
} from './styles/StyledElements'
import TestrideMapBox from './map/TestrideMapBox'
import TripInfo from './tripinformation/TripInfo'
import { useBookingInfoQuery } from '../data/services/hobnob'
import BrandingMobile from './mobile/BrandingMobile'
import BrandingDesktop from './utils/BrandingDesktop'

function Success(props) {
  const { checkout, isMobile } = props
  const navigate = useNavigate()

  useEffect(() => {
    if (!checkout.isReservationCompleted && checkout.isVouchedTriggered) {
      navigate(`/checkout/5/vouched`)
    } else if (checkout.currentStep < 5) {
      navigate(`/checkout/${checkout.currentStep}`)
    }
  }, [])
  const bookingResult = useBookingInfoQuery(checkout)
  return (
    <FlexBox direction="column">
      {isMobile ? <BrandingMobile /> : <BrandingDesktop />}
      <StyledSubTitle marginBottom={'0rem'}>See You Soon!</StyledSubTitle>
      <StyledParagraph marginBottom="1rem">
        <StyledH4 twistedBlack>Your test ride has been reserved</StyledH4>
      </StyledParagraph>
      <TripInfo checkout={checkout} isMobile={isMobile} isSuccessPage={true} />
      {bookingResult.data?.motorcycle && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Image
              src={bookingResult.data?.motorcycle?.images[0]?.url}
              alt="bike-description"
              width={isMobile ? '100%' : '335px'}
              height={'189px'}
              objectFit="cover"
            />
          </Grid>
        </Grid>
      )}
      {bookingResult.data && (
        <StyledBikename margin={'16px 0'}>
          {bookingResult.data.motorcycle?.year +
            ' ' +
            bookingResult.data.motorcycle?.make +
            ' ' +
            bookingResult.data.motorcycle?.model}
        </StyledBikename>
      )}
      <Grid container>
        <Grid item xs={12} md={12}>
          {bookingResult.data && (
            <TestrideMapBox checkout={bookingResult} isMobile={isMobile} />
          )}
          <br />
        </Grid>
      </Grid>
      {bookingResult.data && (
        <div className="booking-metadata">
          {((checkout.partnerLocation?.address_street_1) ||
            (checkout.partnerLocation?.address_street_2) ||
            (checkout.partnerLocation?.address_street_3)) ?
            <StyledH4 twistedBlack>
              {checkout.partnerLocation?.address_street_1
                ? checkout.partnerLocation?.address_street_1
                : checkout.partnerLocation?.address_street_2
                  ? checkout.partnerLocation?.address_street_2
                  : checkout.partnerLocation?.address_street_3
                    ? checkout.partnerLocation?.address_street_3
                    : null
              }
              {
                " " + checkout.partnerLocation?.city + ", " +
                checkout.partnerLocation?.region + " " +
                checkout.partnerLocation?.postal_code
              }
            </StyledH4> :
            <StyledH4 twistedBlack>
              {bookingResult.data?.booking?.location?.address_street_1
                ? bookingResult.data?.booking?.location?.address_street_1
                : bookingResult.data?.booking?.location?.address_street_2
                  ? bookingResult.data?.booking?.location?.address_street_2
                  : null
              }
              {
                " " + bookingResult.data?.booking?.location?.city !== '' ?

                  bookingResult.data?.booking?.location?.city +
                  ', ' +
                  bookingResult.data?.booking?.location?.region +
                  ' ' +
                  bookingResult.data?.booking?.location.postal_code
                  : null
              }
            </StyledH4>
          }
        </div>
      )}
    </FlexBox>
  )
}

export default Success
