import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { NavLink, useNavigate } from 'react-router-dom'
import FlexBox from '../FlexBox'
import { yupResolver } from '@hookform/resolvers/yup'
import { loginValidationSchema } from '../validations/SignInValidation'
import { passwordResetValidation } from '../validations/PasswordResetValidation'
import { phoneValidationSchema } from '../validations/phoneValidation'
import Grid from '@material-ui/core/Grid'
import { loading } from '../utils/loading'
import {
  Button,
  StyledSubTitle,
  StyledParagraph,
  StyledSpan,
  StyledSignUPWrapper,
  StyledInputFieldWrapper,
  StyledFieldLabel,
  StyledInputField,
  StyledErrorLabel,
  Image,
  StyledSignUpForm,
  StyledH1,
  StyledH4,
  StyledBoldSubHeading,
  StyledPhoneInput
} from '../styles/StyledElements'
import {
  DialogTitle,
  Dialog,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import { setAuthToken, setCurrentStep } from '../../data/reducer'
import {
  useLoginUserMutation,
  usePasswordResetMutation,
  useSendPhoneTokenMutation,
  useValidatePhoneTokenMutation
} from '../../data/services/hobnob'
import BrandingDesktop from '../utils/BrandingDesktop'
import { scrollToTop } from '../utils/scrollTop'
import Cookies from 'js-cookie'

function LogIn(props) {
  const { checkout, isMobile } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginValidationSchema),
  })
  const [generalError, setGeneralError] = useState(null)
  const [phoneVerification, setPhoneVerification] = useState(false)
  const [resetPassword, setResetPassword] = useState(false)
  const [loginUser, loginResult] = useLoginUserMutation()
  const [eyeOpen, setEyeOpen] = useState(false)
  const [type, setType] = useState('password');

  useEffect(() => {
    if (checkout.isReservationCompleted) {
      navigate('/checkout/success')
    } else if (!(checkout.currentStep >= 2)) {
      if (isMobile) { scrollToTop() }
      navigate(`/checkout/${checkout.currentStep}`)
    }
  }, [])

  const setCookies = (data) => {
    Cookies.set('vouchedPublicKey', data.vouched_public_key)
      Cookies.set('vouchedWebhookUrl', data.vouched_webhook_url)
  }

  useEffect(() => {
    if (loginResult.status === "fulfilled" && !(loginResult.data?.user?.phone_verified)) {
      setCookies(loginResult.data)
      dispatch(setAuthToken(loginResult.data.token))
      setPhoneVerification(true)
    } else if (loginResult.status === 'fulfilled') {
      setCookies(loginResult.data)
      dispatch(setAuthToken(loginResult.data.token))
      if (props.isMobile) {
        dispatch(setCurrentStep(4))
        if (isMobile) { scrollToTop() }
        navigate('/checkout/4')
      } else {
        dispatch(setCurrentStep(3))
        navigate('/checkout/3')
      }
    }

    if (loginResult.status === 'rejected' && generalError === null) {
      const { error } = loginResult
      setGeneralError(
        error.data.error_messages?.length > 0 ?
          error.data.error_messages[0] : "Something went wrong."
      )
    }
  }, [loginResult])

  const onSubmit = (data) => {
    loginUser({
      email: data.username,
      password: data.password,
    })
  }

  const handleClose = () => setResetPassword(false)

  const handlePhoneClose = () => {
    setPhoneVerification(false)
    dispatch(setCurrentStep(2))
    navigate(`/checkout/2`)
  }

  const handleEyeToggle = () => {
    if (type === 'password') {
      setEyeOpen(true)
      setType('text');
    } else {
      setEyeOpen(false)
      setType('password');
    }
  }

  return (
    <>
      <FlexBox direction="column">
        {!isMobile && <BrandingDesktop />}
        <StyledSubTitle marginBottom={isMobile ? '-1.4rem' : '0.4rem'}>
          WELCOME BACK
        </StyledSubTitle>
        {!isMobile && (
          <StyledParagraph marginBottom={'1rem'}>
            Sign in to your Twisted Road account for a faster experience or create
            an account if you’re new! You only need to do this once. We’ll store
            your information for quicker checkout on your next test ride.
          </StyledParagraph>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledSignUpForm>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <StyledInputFieldWrapper>
                  <StyledFieldLabel>Email</StyledFieldLabel>
                  <StyledInputField
                    type="email"
                    name="username"
                    placeholder="Email Address"
                    width={'100%'}
                    height={'40px'}
                    color={errors.username?.message}
                    {...register('username')}
                  />
                  <StyledErrorLabel>{errors.username?.message}</StyledErrorLabel>
                </StyledInputFieldWrapper>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <StyledInputFieldWrapper>
                  <StyledFieldLabel>Password</StyledFieldLabel>
                  <FlexBox
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className="reveal-wrapper"
                    style={{ width: '100%' }}
                  >
                    <StyledInputField
                      type={type}
                      name="password"
                      placeholder="Password"
                      width={'100%'}
                      height={'40px'}
                      paddingRight="1.8rem"
                      color={errors.password?.message}
                      {...register('password')}
                    />
                    {
                      eyeOpen ?
                        <i class="fa fa-eye" aria-hidden="true"
                          onClick={() => handleEyeToggle()}
                        ></i>
                        :
                        <i class="fa fa-eye-slash" aria-hidden="true"
                          onClick={() => handleEyeToggle()}
                        ></i>
                    }
                  </FlexBox>
                  <StyledErrorLabel>{errors.password?.message}</StyledErrorLabel>
                </StyledInputFieldWrapper>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <FlexBox
                  direction="row"
                  justify="flex-end"
                  marginTop="-1.6rem"
                >
                  <StyledSpan
                    fontWeight="600"
                    fontSize="13px"
                    className="reset-password"
                    padding="4px 8px"
                    onClick={() => {
                      setResetPassword(true)
                    }}
                  >
                    Reset Password
                  </StyledSpan>
                </FlexBox>
              </Grid>
            </Grid>
          </StyledSignUpForm>
          <StyledSignUPWrapper width={isMobile ? '100%' : '27%'}>
            <Button
              type="submit"
              marginTop={'1rem'}
              twistedGold
              twistedDarkBlack
              disabled={loginResult.isLoading ? true : false}
            >
              {loginResult.isLoading ? (
                <FlexBox direction="row" alignItems="center" justify="center">
                  <Image src={loading.LOADING} width="25px" height="25px" />
                </FlexBox>
              ) : (
                'Sign In'
              )}
            </Button>
            <StyledErrorLabel>
              {generalError ? generalError : null}
            </StyledErrorLabel>
            {!props.isMobile && (
              <>
                <p>Don't have an account ?</p>
                <StyledSpan twistedBrownDark>
                  <NavLink to="/checkout/2/signup" style={{ color: '#6B5827' }}>
                    Sign Up Now{' '}
                  </NavLink>
                </StyledSpan>
              </>
            )}
          </StyledSignUPWrapper>
        </form>
      </FlexBox>
      <div className="reset-password-modal">
        <PasswordReset
          isMobile={isMobile}
          checkout={checkout}
          openResetModel={resetPassword}
          handleClose={handleClose}
        />
      </div>
      {phoneVerification &&
        <div className="phone-verifaction">
          <PhoneVerificationModal
            showPhoneModal={phoneVerification}
            checkout={checkout}
            isMobile={isMobile}
            handlePhoneClose={handlePhoneClose}
          />
        </div>
      }
    </>
  )
}

function PasswordReset(props) {
  const { isMobile, checkout, openResetModel } = props
  const [passwordReset, passwordResetResult] = usePasswordResetMutation()
  const [isLinkSent, setIsLinkSent] = useState(false)
  const [showConfirmModel, setConfirmModel] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(passwordResetValidation),
  })

  useEffect(() => {
    if (passwordResetResult.isSuccess) {
      props.handleClose()
      setConfirmModel(true)
    }
  }, [passwordResetResult])

  const handleConfirmClose = () => setConfirmModel(false)

  const onPasswordResetSubmit = (data) => {
    const { email } = data
    passwordReset(email)
  }
  return (
    <>
      <Dialog
        open={openResetModel}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <FlexBox
          direction="column"
          alignItems="center"
          justify="center"
          textAlign="center"
          padding={isMobile ? '1rem' : '2rem 2rem'}
        >
          <DialogTitle id="alert-dialog-title">
            <StyledSubTitle
              twistedDarkBlack
              fontSize="20px"
              marginBottom="0"
            >
              Enter your email address to reset your password.
            </StyledSubTitle>
          </DialogTitle>
          <form onSubmit={handleSubmit(onPasswordResetSubmit)}>
            <FlexBox
              direction="column"
              alignItems="center"
              justify="center"
              textAlign="center"
            >
              <div style={{ 'width': '100%' }}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <StyledInputFieldWrapper paddingBottom="8px">
                      <StyledInputField
                        type="email"
                        className="password-rest-input"
                        name="email"
                        paddingLeft="8px"
                        placeholder="Email Address"
                        width={'100%'}
                        height={'40px'}
                        color={errors.email?.message}
                        {...register('email')}
                      />
                      <StyledErrorLabel>
                        {errors.email?.message}
                      </StyledErrorLabel>
                    </StyledInputFieldWrapper>
                  </Grid>
                </Grid>
              </div>
              <Button
                type="submit"
                padding={isMobile ? ' 0.75em 0.8em' : '0.5em 2em'}
                transparent={'transparent'}
                fontColor="#AF985F"
                marginTop="-0.5rem"
                marginBottom="0"
                disabled={false}
                className="password-reset-link-btn"
              >
                Send Password Reset
              </Button>
            </FlexBox>
          </form>
        </FlexBox>
      </Dialog>

      <Dialog
        open={showConfirmModel}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <FlexBox
          direction="column"
          alignItems="center"
          justify="center"
          textAlign="center"
          padding={isMobile ? '1rem' : '2rem 2rem'}
        >
          <DialogTitle id="alert-dialog-title">
            <StyledH1 twistedDarkBlack>Email Sent</StyledH1>
          </DialogTitle>
          <StyledBoldSubHeading marginBottom={isMobile ? "0.2rem" : "1rem"}>
            Make sure to check your spam and junk folder!
          </StyledBoldSubHeading>
          <StyledParagraph marginBottom="1rem" padding={isMobile ? "4px 0.5rem" : "8px 3rem"}>
            An Email has been sent to the email on file for your account. Please follow the link to reset your password.
          </StyledParagraph>
          <div style={isMobile ? { 'width': '75%' } : { 'width': '50%' }}>
            <Grid container>
              <Grid item xs={12} md={12}>

              </Grid>
            </Grid>
          </div>
          <Button
            padding={isMobile ? '0.5em 0.5em' : '0.5em 2em'}
            transparent={'transparent'}
            fontColor="#AF985F"
            marginTop="-0.5rem"
            marginBottom="0"
            disabled={false}
            onClick={(e) => {
              e.preventDefault()
              setConfirmModel(false)
            }}
          >
            Back To Login
          </Button>
        </FlexBox>
      </Dialog>
    </>
  )
}

function PhoneVerificationModal(props) {
  const {
    checkout,
    isMobile,
  } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(phoneValidationSchema),
  })
  const [sendPhoneToken, sendPhoneTokenResult] = useSendPhoneTokenMutation()
  const [validatePhoneToken, validatePhoneTokenResult] = useValidatePhoneTokenMutation()
  const [mobile, setMobile] = useState()
  const [otpError, setOtpError] = useState(null)
  const [otpValidateError, setOtpValidateError] = useState(null)
  const [showOtpModal, setShowOtpModal] = useState(false)
  const [showPhoneModal, setShowPhoneModal] = useState(props.showPhoneModal)

  useEffect(() => {
    if (sendPhoneTokenResult.status === 'fulfilled' && !showOtpModal) {
      setShowOtpModal(true)
      setShowPhoneModal(false)
    }

    if (sendPhoneTokenResult.status === 'rejected' && otpError === null) {
      const { error } = sendPhoneTokenResult
      if (error.data?.error_messages[0]) {
        setOtpError(error.data?.error_messages[0])
      } else {
        setOtpError(error.data?.errors[0])
      }
      setShowOtpModal(false)
    }
  }, [sendPhoneTokenResult])

  useEffect(() => {
    if (validatePhoneTokenResult.status === 'fulfilled') {
      if (isMobile) {
        setShowOtpModal(false)
        dispatch(setCurrentStep(4))
      } else {
        dispatch(setCurrentStep(3))
        navigate('/checkout/3')
      }
    }

    if (
      validatePhoneTokenResult.status === 'rejected' &&
      otpValidateError === null
    ) {
      const { error } = validatePhoneTokenResult
      setOtpValidateError(
        error.data.error_messages?.length > 0 ?
          error.data.error_messages[0] : "Something went wrong."
      )
      setOtpError(null)
      setMobile(undefined)
    }
  }, [validatePhoneTokenResult])

  const onPhoneVerifySubmit = (data) => {
    const { mobile } = data
    sendPhoneToken(mobile.replaceAll(' ', ''))
    setMobile(mobile)
  }

  const handleSendCodeAgain = () => {
    sendPhoneToken(mobile.replaceAll(' ', ''))
  }

  const handleVerfCode = (verfCode) => {
    if (verfCode.length === 4) {
      validatePhoneToken(verfCode)
    }
  }

  return (
    <div className="phone-verification-container">
      {showPhoneModal &&
        <Dialog
          open={showPhoneModal}
          onClose={props.handlePhoneClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <FlexBox
            direction="column"
            alignItems="center"
            justify="center"
            textAlign="center"
            padding={isMobile ? '2rem' : '2rem 2.5rem'}
          >
            <StyledSubTitle
              twistedDarkBlack
              fontSize="24px"
              marginBottom="1rem"
            >
              Phone Verification
            </StyledSubTitle>
            <form onSubmit={handleSubmit(onPhoneVerifySubmit)}>
              <FlexBox
                direction="column"
                alignItems="center"
                justify="center"
                textAlign="center"
              >
                <div style={{ 'width': '100%' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <StyledInputFieldWrapper>
                        {/* <Controller
                          control={control}
                          render={({ field: { onChange } }) => (
                            <StyledPhoneInput
                              international
                              name="mobile"
                              placeholder="Enter Mobile Number"
                              defaultCountry="US"
                              value={mobile}
                              {...register("mobile")}
                              onChange={onChange}
                            />
                          )}
                        /> */}
                        <StyledPhoneInput
                          international
                          name="mobile"
                          defaultCountry="US"
                          control={control}
                        />
                        <StyledErrorLabel>{errors.mobile?.message}</StyledErrorLabel>
                        {otpError && (
                          <StyledErrorLabel fontSize="16">
                            {otpError}
                          </StyledErrorLabel>
                        )}
                      </StyledInputFieldWrapper>
                    </Grid>
                  </Grid>
                </div>
                <Button
                  type="submit"
                  padding={isMobile ? ' 0.5em 1em' : '0.5em 1em'}
                  marginTop="-0.5rem"
                  marginBottom="0"
                  twistedGold
                  twistedDarkBlack
                  disabled={sendPhoneTokenResult.isLoading ? true : false}
                  className="verify-phone-btn"
                >
                  {sendPhoneTokenResult.isLoading ? (
                    <FlexBox direction="row" alignItems="center" justify="center">
                      <Image src={loading.LOADING} width="25px" height="25px" />
                    </FlexBox>
                  ) : (
                    'Confirm Phone Number'
                  )}
                </Button>
              </FlexBox>
            </form>
          </FlexBox>
        </Dialog>
      }
      {showOtpModal &&
        <div className="otp-modal">
          <Dialog
            open={showOtpModal}
            onClose={() => {
              setShowOtpModal(false)
              dispatch(setCurrentStep(2))
              navigate(`/checkout/2`)
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <FlexBox
              direction="column"
              alignItems="center"
              justify="center"
              textAlign="center"
              padding={isMobile ? '1rem' : '2rem 8rem'}
            >
              <DialogTitle id="alert-dialog-title">
                <StyledH1 twistedDarkBlack>Enter 4-digit Code</StyledH1>
              </DialogTitle>
              <StyledH4 twistedBlack margin={'1rem 0'}>
                We sent an SMS to {mobile} with your code.
              </StyledH4>
              <div>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <StyledInputFieldWrapper paddingBottom="0">
                      <StyledInputField
                        type="text"
                        className="mobile-otp-input"
                        name="mobileOtp"
                        textAlign={'center'}
                        paddingLeft="0"
                        placeholder="X-X-X-X"
                        width={'100%'}
                        height={'40px'}
                        onChange={(e) => handleVerfCode(e.target.value)}
                      />
                      <StyledErrorLabel>
                        {errors.license_number?.message}
                      </StyledErrorLabel>
                    </StyledInputFieldWrapper>
                  </Grid>
                </Grid>
              </div>
              <Button
                padding={'0.5em 2em'}
                transparent={'transparent'}
                fontColor="#AF985F"
                marginTop="0.2rem"
                onClick={() => {
                  handleSendCodeAgain()
                }}
                disabled={false}
              >
                Send code again
              </Button>
              <Button
                padding={'0.5em 3em'}
                marginTop="-1rem"
                transparent={'transparent'}
                twistedDarkBlack
                onClick={() => {
                  setShowOtpModal(false)
                  dispatch(setCurrentStep(2))
                  navigate(`/checkout/2`)
                }}
                disabled={false}
              >
                Cancel
              </Button>
              <StyledErrorLabel fontSize={16}>
                {otpValidateError}
              </StyledErrorLabel>
            </FlexBox>
          </Dialog>
        </div>
      }
    </div>
  )

}

export default LogIn
