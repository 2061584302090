import styled from 'styled-components'

export const StyledKlaviyoBikeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: ${({ width }) => width || '200px'};
  ${({ height }) =>
    height &&
    `
  height: ${height};
  `}
  border-top: 0px solid transparent;
  border-right: 0px solid transparent;
  border-left: 0px solid transparent;
  border-bottom: 0px solid transparent;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  overflow: hidden;
  min-width: 200px;
  min-height: 250px;
`

export const StyledKlaviyoBikeImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
`

export const StyledKaviyoContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px;
  min-height: 250px;
  justify-content: center;
`