import adventureurl from '../images/bike-styles/adventure.svg'
import cruiserUrl from '../images/bike-styles/cruiser.svg'
import sportUrl from '../images/bike-styles/sport.svg'
import standardUrl from '../images/bike-styles/standard.svg'
import touringUrl from '../images/bike-styles/touring.svg'
import threeWheel from '../images/bike-styles/three_wheel.svg'
import othersUrl from '../images/bike-styles/others.svg'


export const BIKE_CATEGORIES =
{
    adventureUrl: adventureurl,
    cruiserUrl: cruiserUrl,
    sportUrl: sportUrl,
    standardUrl: standardUrl,
    touringUrl: touringUrl,
    threeWheel: threeWheel,
    othersUrl: othersUrl
}