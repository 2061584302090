import React, { useEffect, useState } from 'react'
import './MobileStyling.css'
import { loading } from './../utils/loading'
import { Divider, Typography, Grid } from '@mui/material'

const BrandingMobile = (props) => {
  return (
    <>
      <Grid container justifyContent="flex-start">
        <Grid item xs={12}>
          <Typography fontSize="23px" fontWeight="bold" color="#695626">
            Extended Test Ride
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item>
              <Typography marginRight="5px">Powered by</Typography>
            </Grid>
            <Grid item>
              <img src={loading.TR_LOGO_2} width="95px" height="auto" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ marginBottom: '10px' }} />
    </>
  )
}

export default BrandingMobile
