import * as Yup from 'yup'

export const licenseValidationSchema = Yup.object().shape({
  license_number: Yup.string()
    .nullable()
    .required('License Number is required.'),

  license_first_name: Yup.string()
    .nullable()
    .required('First Name is required.'),
  //license_middle_name: Yup.string().nullable().required('Required'),
  license_last_name: Yup.string()
    .nullable()
    .required('Last Name is required.'),
  license_birth_month: Yup.string()
    .nullable()
    .required('Birth Month is required'),

  license_birth_day: Yup.string()
    .nullable()
    .required('Birth Day is required'),

  license_birth_year: Yup.string()
    .nullable()
    .required('Birth Year is required'),

  license_gender: Yup.string()
    .nullable()
    .required('Gender is required.'),
  license_expiration_month: Yup.string()
    .nullable()
    .required('Expiration Month is required'),

  license_expiration_day: Yup.string()
    .nullable()
    .required('Expiration Day is required'),

  license_expiration_year: Yup.string()
    .nullable()
    .required('Expiration Year is required'),
  address_line_1: Yup.string()
    .nullable()
    .required('Street Address is required.'),
  //address_line_2 : Yup.string().nullable().required('Required'),
  address_city: Yup.string()
    .nullable()
    .required('City is required.'),
  address_state: Yup.string()
    .nullable()
    .required('State/Region is required.'),
  address_country: Yup.string()
    .nullable()
    .required('Country is required.'),
  address_postal_code: Yup.number()
    .typeError('Postal Code is Required.')
    .required('Postal code is required.'),
})