import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { add, formatISO, parse } from 'date-fns'
import { getISOStringByTimezone } from '../../components/utils/getISOStringByTimezone'
import { BASE_API_URL, PRICING_OPTIONS } from '../../constants/index'

export const hobnobApi = createApi({
  reducerPath: 'hobnobApi',
  // serializeQueryArgs: ({ queryArgs }) => {
  //   /* // If we want to differentiate by ride date & time?
  //   const { motorcycleId, rideDate, pickupTime } = queryArgs
  //   return `${motorcycleId}:${rideDate}:${pickupTime}`
  //   */
  //   if (queryArgs?.motorcycleId) {
  //     return `${queryArgs.motorcycleId}`
  //   }

  //   const cacheKey = new URLSearchParams(queryArgs)
  //   return cacheKey.toString()
  // },
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().checkout.authToken
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Payment', 'Motorcycles'],
  endpoints: (builder) => ({
    motorcyclePricing: builder.query({
      query: ({ motorcycleId, rideDate, pickupTime, selectedInsurance, promoCode }) => {
        const startAt =
          rideDate && pickupTime
            ? parse(`${rideDate} ${pickupTime}`, 'yyyy-MM-dd HH:mm', new Date())
            : new Date()
        const endAt = add(startAt, { hours: 24 })

        const urlParams = Object.assign(
          {},
          {
            motorcycle_pretty_id: motorcycleId,
            start_at: `${startAt.toISOString()}`,
            end_at: `${endAt.toISOString()}`,
            rider_insurance: selectedInsurance,
            promotion_code: promoCode,
          },
          PRICING_OPTIONS
        )

        return {
          url: `pricing/motorcycles?${new URLSearchParams(
            urlParams
          ).toString()}`,
        }
      },
    }),
    motorcycleInfo: builder.query({
      query: ({ VIN, VIN6, sameDayRental }) => {
        const URL = sameDayRental
          ? `motorcycles/testride?${VIN6 ? 'vin6=' + VIN6 : 'vin=' + VIN
          }${'&same_day=1'}`
          : `motorcycles/testride?${VIN6 ? 'vin6=' + VIN6 : 'vin=' + VIN}`
        return {
          url: URL,
          method: 'GET',
        }
      },
      providesTags: ['Motorcycles'],
    }),
    createUser: builder.mutation({
      query: (params) => ({
        url: 'users',
        method: 'POST',
        body: params,
      }),
    }),
    createPayment: builder.mutation({
      query: (params) => {
        return {
          url: 'payments',
          method: 'POST',
          body: params,
        }
      },
      invalidatesTags: ['Payment'],
    }),
    getPaymentInfo: builder.query({
      query: () => 'payments',
      providesTags: ['Payment'],
    }),
    deletePaymentInfo: builder.mutation({
      query: ({ payment_method_id }) => {
        return {
          url: `payments/${payment_method_id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['Payment'],
    }),
    loginUser: builder.mutation({
      query: (params) => ({
        url: 'users/sessions',
        method: 'POST',
        body: params,
      }),
    }),
    profilePhoto: builder.mutation({
      query: (params) => ({
        url: 'users/profile_photo',
        method: 'PUT',
        body: params,
      }),
    }),
    sendPhoneToken: builder.mutation({
      query: (phone_number) => ({
        url: 'users/validations/send_phone_token',
        method: 'PUT',
        body: { phone_number },
      }),
    }),
    validatePhoneToken: builder.mutation({
      query: (code) => ({
        url: 'users/validations/validate_phone_token',
        method: 'POST',
        body: { verification_code: code },
      }),
    }),
    riderExperience: builder.mutation({
      query: ({ category, years, id }) => {
        let bodyContent = null
        if (id) {
          bodyContent = {
            rider: {
              rider_experiences: [
                {
                  category: category,
                  years_experience: years,
                  id: id,
                },
              ],
            },
          }
        } else {
          bodyContent = {
            rider: {
              rider_experiences: [
                {
                  category: category,
                  years_experience: years,
                },
              ],
            },
          }
        }
        return {
          url: 'users/basic_info',
          method: 'PUT',
          body: bodyContent,
        }
      },
    }),
    riderVouchedData: builder.mutation({
      query: ({
        reservationID,
        stepType
      }) => ({
        url: 'riders/data',
        method: 'POST',
        body: {
          name: stepType === 'payment' ?
            'reservation.vouched.workflow.required' :
            'reservation.vouched.workflow.started' ,
          category: 'identity',
          value: reservationID
        }
      })
    }),
    riderDocument: builder.mutation({
      query: ({
        firstName,
        middleName,
        lastName,
        gender,
        dob,
        licenseNo,
        licenseState,
        licenseCity,
        licenseCountry,
        licenseExpiry,
        addressCity,
        addressCountry,
        addressState,
        addressStateAbbr,
        addressStreet1,
        addressStreet2,
        addressZip,
      }) => ({
        url: 'users/documents',
        method: 'PUT',
        body: {
          drivers_license: {
            first_name: firstName,
            last_name: lastName,
            middle_name: middleName,
            date_of_birth: dob,
            gender: gender,
            license_no: licenseNo,
            license_country: licenseCountry,
            license_state: licenseState,
            expiration_date: licenseExpiry,
            location: {
              address_street_1: addressStreet1,
              address_street_2: addressStreet2,
              address_city: addressCity,
              address_state: addressState,
              address_state_abbreviation: addressStateAbbr,
              address_country: addressCountry,
              address_zip: addressZip,
            },
          },
        },
      }),
    }),
    getLicenceInfo: builder.query({
      query: () => 'users/documents',
    }),
    getBasicInfo: builder.query({
      query: () => 'users/basic_info',
    }),
    createRequest: builder.mutation({
      query: ({
        motorcycleId,
        rideDate,
        pickupTime,
        dropoffDate,
        dropoffTime,
        timeZone
      }) => {
        const startAt = getISOStringByTimezone(rideDate, pickupTime, timeZone)
        const endAt = getISOStringByTimezone(dropoffDate, dropoffTime, timeZone)

        return {
          url: 'riders/requests',
          method: 'POST',
          body: {
            motorcycle_pretty_id: motorcycleId,
            start_at: startAt,
            end_at: endAt,
            pricing_options: PRICING_OPTIONS,
          },
        }
      },
    }),
    bookRequest: builder.mutation({
      query: ({
        requestUuid,
        rideDate,
        pickupTime,
        paymentsCardUuid,
        termsServiceAgreed,
        riderInsurance,
        promotionCode,
        dropoffDate,
        dropoffTime,
        timeZone
      }) => {
        const startAt = getISOStringByTimezone(rideDate, pickupTime, timeZone)
        const endAt = getISOStringByTimezone(dropoffDate, dropoffTime, timeZone)
        return {
          url: `riders/requests/${requestUuid}/book`,
          method: 'POST',
          body: {
            start_at: startAt,
            end_at: endAt,
            pricing_options: Object.assign(
              {},
              {
                rider_insurance: riderInsurance,
                promotion_code: promotionCode
              },
              PRICING_OPTIONS
            ),
            payments_card_uuid: paymentsCardUuid,
            terms_service_agreed: termsServiceAgreed,
            message_request:
              '24-Hour Extended Test Drive powered by Twisted Road',
          },
        }
      },
    }),
    bookingInfo: builder.query({
      query: ({ bookedRideUuid }) => ({
        url: `riders/reservations/${bookedRideUuid}`,
        method: 'GET',
      }),
    }),
    passwordReset: builder.mutation({
      query: (email) => ({
        url: 'users/request_password_reset',
        method: 'POST',
        body: { email },
      }),
    }),
    scheduleLeadCommunication: builder.mutation({
      query: (params) => ({
        url: 'leads',
        method: 'POST',
        body: params,
      }),
    }),
  }),
})

export const {
  useMotorcyclePricingQuery,
  useCreateUserMutation,
  useLoginUserMutation,
  useCreatePaymentMutation,
  useGetPaymentInfoQuery,
  useDeletePaymentInfoMutation,
  useProfilePhotoMutation,
  useSendPhoneTokenMutation,
  useValidatePhoneTokenMutation,
  useRiderExperienceMutation,
  useRiderVouchedDataMutation,
  useRiderDocumentMutation,
  useMotorcycleInfoQuery,
  useCreateRequestMutation,
  useBookRequestMutation,
  useBookingInfoQuery,
  useGetLicenceInfoQuery,
  useGetBasicInfoQuery,
  usePasswordResetMutation,
  useScheduleLeadCommunicationMutation,
} = hobnobApi
