import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { NavLink, useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import FlexBox from '../FlexBox'
import { useDispatch } from 'react-redux'
import {
  setAuthToken,
  setOtp,
  setMobileConfirmed,
  setCurrentStep,
} from '../../data/reducer'
import {
  useCreateUserMutation,
  useSendPhoneTokenMutation,
  useValidatePhoneTokenMutation,
} from '../../data/services/hobnob'
import { signUpValidationSchema } from '../validations/SignUpValidation'
import {
  Typography,
  DialogTitle,
  Dialog,
  Checkbox,
  Grid,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { loading } from '../utils/loading'
import {
  StyledH1,
  Button,
  StyledSubTitle,
  StyledParagraph,
  StyledSpan,
  StyledSignUPWrapper,
  StyledSignUpForm,
  StyledFieldLabel,
  StyledInputField,
  StyledInputFieldWrapper,
  StyledErrorLabel,
  StyledH4,
  StyledPhoneInput,
  Image,
} from '../styles/StyledElements'
import BrandingDesktop from '../utils/BrandingDesktop'
import { scrollToTop } from '../utils/scrollTop'
import PasswordChecklist from "react-password-checklist"
import { GOOGLE_RECAPTCHA_V3_TOKEN } from '../../constants/index'
import {
  trackUserSignedUp,
  createKlaviyoProfile
} from '../elements/klaviyoTracking/userSignup'
import Cookies from 'js-cookie'

function SignUp(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { checkout, isMobile } = props
  const { email, firstName, lastName, phone: phoneNumber } = checkout.klaviyoFormInfo
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signUpValidationSchema),
  })
  const [generalError, setGeneralError] = useState(null)
  const [otpError, setOtpError] = useState(null)
  const [otpValidateError, setOtpValidateError] = useState(null)
  const [reEnterMobile, setReEnterMobile] = useState(false)
  const [mobile, setMobile] = useState()
  const [isCreateSuccess, setIsCreateSuccess] = useState(false)
  const [bookLoading, setBookLoading] = useState(false)
  const [isRadioChecked, setIsRadioChecked] = useState(false)
  const [passwordField, setPasswordField] = useState('')
  const [eyeOpen, setEyeOpen] = useState(false)
  const [type, setType] = useState('password');
  const [klaviyoTrackData, setKlaviyoTrackData] = useState({
    email: null,
    firstName: null,
    lastName: null,
    phone: null,
    source: "Extended Test Ride"
  })

  const [createUser, createResult] = useCreateUserMutation()
  const [sendPhoneToken, sendPhoneTokenResult] = useSendPhoneTokenMutation()
  const [validatePhoneToken, validatePhoneTokenResult] =
    useValidatePhoneTokenMutation()

  const getTermsText = (isMobile) => {
    return (
      <Typography className={isMobile ? 'terms-of-service' : ''}>
        I agree to Twisted Road's{' '}
        <StyledSpan twistedBrown>
          <a
            href="https://www.twistedroad.com/terms-conditions"
            target="_blank" rel="noreferrer"
          >
            {' '}
            terms of service
          </a>{' '}
        </StyledSpan>
        and{' '}
        <StyledSpan twistedBrown>
          <a href="https://www.twistedroad.com/privacy-policy" target="_blank" rel="noreferrer">
            {' '}
            privacy policy.
          </a>{' '}
        </StyledSpan>
      </Typography>
    )
  }

  useEffect(() => {
    if (!document.getElementById("grecaptcha")) {
      const recaptcha = document.createElement("script");
      recaptcha.setAttribute("id", "grecaptcha")
      recaptcha.setAttribute("src", `https://www.google.com/recaptcha/api.js?render=${GOOGLE_RECAPTCHA_V3_TOKEN}`)
      document.body.appendChild(recaptcha)
    }
    if (checkout.isReservationCompleted) {
      navigate('/checkout/success')
    } else if (!(checkout.currentStep >= 2)) {
      navigate(`/checkout/${checkout.currentStep}`)
    }
  }, [])

  useEffect(() => {
    if (isCreateSuccess) {
      sendPhoneToken(mobile.replaceAll(' ', ''))
    }
  }, [isCreateSuccess])

  useEffect(() => {
    if (createResult.status === 'fulfilled' && !isCreateSuccess) {
      Cookies.set('vouchedWebhookUrl', createResult.data.vouched_webhook_url)
      Cookies.set('vouchedPublicKey', createResult.data.vouched_public_key)
      setBookLoading(false)
      dispatch(setAuthToken(createResult.data.token))
      setIsCreateSuccess(true)
      trackUserSignedUp(
        klaviyoTrackData?.email,
        klaviyoTrackData?.firstName,
        klaviyoTrackData?.lastName,
        klaviyoTrackData?.phone,
        klaviyoTrackData?.source
      )

      createKlaviyoProfile(
        klaviyoTrackData?.email,
        klaviyoTrackData?.firstName,
        klaviyoTrackData?.lastName,
        klaviyoTrackData?.phone,
        klaviyoTrackData?.source
      )
    }

    if (createResult.status === 'rejected' && generalError === null) {
      const { error } = createResult
      if (
        error.data?.errors[0] ===
        'captcha-failed'
      ) {
        setGeneralError('Invalid CAPTCHA. Please try again!')
      } else {
        setGeneralError(error.data?.error_messages[0])
      }
    }
  }, [createResult])

  useEffect(() => {
    if (sendPhoneTokenResult.status === 'fulfilled') {
      dispatch(setOtp(true))
    }

    if (sendPhoneTokenResult.status === 'rejected' && otpError === null) {
      const { error } = sendPhoneTokenResult
      if (error.data?.error_messages[0]) {
        setOtpError(error.data?.error_messages[0])
      } else {
        setOtpError(error.data?.errors[0])
      }
      setOtp(false)
      setReEnterMobile(true)
    }
  }, [sendPhoneTokenResult])

  useEffect(() => {
    if (validatePhoneTokenResult.status === 'fulfilled') {
      if (props.isMobile) {
        dispatch(setCurrentStep(4))
        scrollToTop()
      } else {
        dispatch(setCurrentStep(3))
        navigate('/checkout/3')
      }
      dispatch(setMobileConfirmed(true))
      dispatch(setOtp(false))
    }

    if (
      validatePhoneTokenResult.status === 'rejected' &&
      otpValidateError === null
    ) {
      const { error } = validatePhoneTokenResult
      setOtpValidateError(
        error.data.error_messages?.length > 0 ?
          error.data.error_messages[0] : "Something went wrong."
      )
      setOtpError(null)
      setMobile(undefined)
    }
  }, [validatePhoneTokenResult])

  const onSubmit = async (data) => {
    setGeneralError(null)
    setBookLoading(true)
    const { firstName, lastName, email, acceptedTerms, phoneInput, password } = data
    const mobile = phoneInput?.replaceAll(' ', '')
    const klaviyoUpdatedData = {
      email: email, firstName: firstName, lastName: lastName, phone: mobile
    }
    setKlaviyoTrackData({ ...klaviyoTrackData, ...klaviyoUpdatedData })
    window.grecaptcha.ready(function () {
      window.grecaptcha.execute(
        GOOGLE_RECAPTCHA_V3_TOKEN,
        { action: 'signup' }
      ).then(function (grecaptchaToken) {
        createUser({
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          terms_of_service_agreed: isRadioChecked,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          grecaptcha_token: grecaptchaToken
        })
        setMobile(mobile)
      })
        .catch(
          function (responseData) {
            console.log("captcha error", responseData)
          }
        )
    });
  }

  const onSubmitMobile = async (mobile) => {
    sendPhoneToken(mobile.replaceAll(' ', ''))
  }

  const handleOtpClose = () => {
    dispatch(setOtp(false))
  }

  const handlereEnterClose = () => [setReEnterMobile(false)]

  // const handleEmailClose = () => {
  // may use in future  
  //   dispatch(setMobileConfirmed(false))
  //   if(validatePhoneTokenResult.status === 'fulfilled') {
  //     navigate("/checkout/3")
  //   }else {
  //     navigate("/checkout/2/signup")
  //   }
  // }

  const handleVerfCode = (verfCode) => {
    if (verfCode.length === 4) {
      validatePhoneToken(verfCode)
    }
  }

  const handleSendCodeAgain = () => {
    sendPhoneToken(mobile)
  }

  const handleEyeToggle = () => {
    if (type === 'password') {
      setEyeOpen(true)
      setType('text');
    } else {
      setEyeOpen(false)
      setType('password');
    }
  }

  return (
    <>
      <FlexBox direction="column">
        {!isMobile && <BrandingDesktop />}
        <StyledSubTitle marginBottom={isMobile ? '-1.4rem' : '0.4rem'}>
          Sign Up
        </StyledSubTitle>
        {!isMobile && (
          <StyledParagraph>
            Sign in to your Twisted Road account for a faster experience or
            create an account if you’re new! You only need to do this once.
            We’ll store your information for quicker checkout on your next test
            ride.
          </StyledParagraph>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <StyledSignUpForm>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <StyledInputFieldWrapper>
                  <StyledFieldLabel>Email</StyledFieldLabel>
                  <StyledInputField
                    defaultValue={email}
                    type="email"
                    name="email"
                    placeholder="Enter Email"
                    width={'100%'}
                    height={'40px'}
                    color={errors.email?.message}
                    {...register('email')}
                  />
                  <StyledErrorLabel>{errors.email?.message}</StyledErrorLabel>
                </StyledInputFieldWrapper>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <StyledInputFieldWrapper>
                  <StyledFieldLabel>First Name</StyledFieldLabel>
                  <StyledInputField
                    defaultValue={firstName}
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    width={'100%'}
                    height={'40px'}
                    color={errors.firstName?.message}
                    {...register('firstName')}
                  />
                  <StyledErrorLabel>
                    {errors.firstName?.message}
                  </StyledErrorLabel>
                </StyledInputFieldWrapper>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <StyledInputFieldWrapper>
                  <StyledFieldLabel>Last Name</StyledFieldLabel>
                  <StyledInputField
                    defaultValue={lastName}
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    width={'100%'}
                    height={'40px'}
                    color={errors.lastName?.message}
                    {...register('lastName')}
                  />
                  <StyledErrorLabel>
                    {errors.lastName?.message}
                  </StyledErrorLabel>
                </StyledInputFieldWrapper>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <StyledInputFieldWrapper>
                  <StyledFieldLabel>Mobile</StyledFieldLabel>
                  {/* <Controller
                    name="phoneInput"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <StyledPhoneInput
                        international
                        name="phoneInput"
                        placeholder="Enter Mobile Number"
                        defaultCountry="US"
                        value={mobile ? mobile : phoneNumber}
                        {...register('phoneInput')}
                        onChange={onChange}
                      />
                    )}
                  /> */}
                  <StyledPhoneInput
                    international
                    name="phoneInput"
                    defaultCountry="US"
                    control={control}
                  />
                  <StyledErrorLabel>{errors.phoneInput?.message}</StyledErrorLabel>
                  {otpError && (
                    <StyledErrorLabel fontSize="16">
                      {otpError}
                    </StyledErrorLabel>
                  )}
                </StyledInputFieldWrapper>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <StyledInputFieldWrapper paddingBottom="4px">
                  <StyledFieldLabel>Password</StyledFieldLabel>
                  <FlexBox
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className="reveal-wrapper"
                    style={{ width: '100%' }}
                  >
                    <StyledInputField
                      type={type}
                      name="password"
                      placeholder="Enter Password"
                      width={'100%'}
                      height={'40px'}
                      paddingRight="1.8rem"
                      color={errors.password?.message}
                      {...register('password')}
                      onChange={(e) => {
                        setPasswordField(e.target.value)
                      }}
                    />
                    {
                      eyeOpen ?
                        <i class="fa fa-eye" aria-hidden="true"
                          onClick={() => handleEyeToggle()}
                        ></i>
                        :
                        <i class="fa fa-eye-slash" aria-hidden="true"
                          onClick={() => handleEyeToggle()}
                        ></i>
                    }
                  </FlexBox>
                  <StyledErrorLabel>
                    {errors.password?.message}
                  </StyledErrorLabel>
                </StyledInputFieldWrapper>
              </Grid>
            </Grid>
            {passwordField && (
              <PasswordChecklist
                rules={["minLength", "specialChar", "number", "capital", "lowercase"]}
                minLength={12}
                value={passwordField}
                className="signup-password-checklist"
              />
            )}
            <FlexBox direction="row" alignItems={!isMobile ? 'center' : 'flex-start'}>
              <StyledInputField
                type="checkbox"
                name="acceptedTerms"
                width={isMobile ? '22px' : '18px'}
                height={isMobile ? '22px' : '18px'}
                checked={isRadioChecked}
                onClick={() => {
                  setIsRadioChecked((isRadioChecked) => !isRadioChecked)
                }}
                {...register('acceptedTerms')}
              />&nbsp;&nbsp;
              {getTermsText()}
            </FlexBox>
            <StyledErrorLabel>{errors.acceptedTerms?.message}</StyledErrorLabel>
          </StyledSignUpForm>
          <StyledErrorLabel fontSize={16}>{generalError}</StyledErrorLabel>
          <StyledSignUPWrapper width={isMobile ? '100%' : '27%'}>
            <Button
              type="submit"
              marginTop={'1rem'}
              twistedGold
              twistedDarkBlack
              disabled={
                createResult.isLoading || !isRadioChecked ? true : false
              }
            >
              {createResult.isLoading ? (
                <FlexBox direction="row" alignItems="center" justify="center">
                  <Image src={loading.LOADING} width="25px" height="25px" />
                </FlexBox>
              ) : (
                'Sign Up'
              )}
            </Button>
            {!props.isMobile && (
              <>
                <StyledParagraph>I have an account</StyledParagraph>
                <StyledSpan twistedBrownDark>
                  <NavLink to="/checkout/2/login" style={{ color: '#6B5827' }}>
                    Log In
                  </NavLink>
                </StyledSpan>
              </>
            )}
          </StyledSignUPWrapper>
        </form>
      </FlexBox>
      <div className="otp-modal">
        <Dialog
          open={checkout.isOtpSent}
          onClose={handleOtpClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <FlexBox
            direction="column"
            alignItems="center"
            justify="center"
            textAlign="center"
            padding={isMobile ? '1rem' : '2rem 8rem'}
          >
            <DialogTitle id="alert-dialog-title">
              <StyledH1 twistedDarkBlack>Enter 4-digit Code</StyledH1>
            </DialogTitle>
            <StyledH4 twistedBlack margin={'1rem 0'}>
              We sent an SMS to {mobile} with your code.
            </StyledH4>
            <div>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <StyledInputFieldWrapper>
                    <StyledInputField
                      type="text"
                      className="mobile-otp-input"
                      name="mobileOtp"
                      textAlign={'center'}
                      paddingLeft="0"
                      placeholder="X-X-X-X"
                      width={'100%'}
                      height={'40px'}
                      onChange={(e) => handleVerfCode(e.target.value)}
                    />
                    <StyledErrorLabel>
                      {errors.license_number?.message}
                    </StyledErrorLabel>
                  </StyledInputFieldWrapper>
                </Grid>
              </Grid>
            </div>
            <Button
              padding={'0.5em 2em'}
              transparent={'transparent'}
              fontColor="#AF985F"
              marginTop="0.2rem"
              onClick={handleSendCodeAgain}
              disabled={false}
            >
              Send code again
            </Button>
            <Button
              padding={'0.5em 3em'}
              marginTop="-1rem"
              transparent={'transparent'}
              twistedDarkBlack
              onClick={handleOtpClose}
              disabled={false}
            >
              Cancel
            </Button>
            <StyledErrorLabel fontSize={16}>
              {otpValidateError}
            </StyledErrorLabel>
          </FlexBox>
        </Dialog>
      </div>
      <div className="re_enter_mobile_modal">
        <Dialog
          open={reEnterMobile}
          onClose={handleOtpClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <FlexBox
            direction="column"
            alignItems="center"
            justify="center"
            textAlign="center"
            padding="2rem 2rem"
          >
            <DialogTitle id="alert-dialog-title">
              <StyledH1 twistedDarkBlack>Re-Enter Your Mobile Number</StyledH1>
            </DialogTitle>
            <FlexBox
              direction="column"
              alignItems="center"
              justify="center"
              textAlign="center"
            >
              <div style={{ width: '100%' }}>
                <StyledPhoneInput
                  international
                  placeholder="Enter Mobile Number"
                  defaultCountry="US"
                  value={mobile}
                  onChange={setMobile}
                />
              </div>
            </FlexBox>
            <Button
              padding={'0.5em 3em'}
              marginTop="1rem"
              transparent={'transparent'}
              fontColor="#AF985F"
              type="button"
              onClick={() => {
                if (mobile === undefined || mobile === '' || mobile === null) {
                  setOtpError('Mobile number is required')
                } else {
                  onSubmitMobile(mobile)
                  setOtpError(null)
                }
              }}
              disabled={false}
            >
              Send code again
            </Button>
            <Button
              padding={'0.5em 3em'}
              marginTop="-1rem"
              marginBottom="-0.3rem"
              transparent={'transparent'}
              twistedDarkBlack
              type="button"
              onClick={handlereEnterClose}
              disabled={false}
            >
              Cancel
            </Button>
            {otpError && (
              <StyledErrorLabel fontSize="16">{otpError}</StyledErrorLabel>
            )}
          </FlexBox>
        </Dialog>
      </div>
      {/* may require in future
      <div className="email-modal">
        <Dialog
          open={checkout.isMobileConfirmed}
          onClose={handleEmailClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <FlexBox direction="column" alignItems="center" justify="center" textAlign="center" padding="1rem 2rem">
            <DialogTitle id="alert-dialog-title">
            <StyledH1 twistedDarkBlack>Verify Email</StyledH1>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <StyledH4 twistedBlack>
                  Tap the link in the email we sent you to confirm your email address.
                </StyledH4>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                padding = {"0.5em 3em"}
                twistedGold
                twistedGoldDark
                twistedBlack
                marginTop="3rem"
                onClick={handleEmailClose}
                disabled={false}
              >Got it</Button>
            </DialogActions>
          </FlexBox>
        </Dialog>
      </div> */}
    </>
  )
}

export default SignUp
