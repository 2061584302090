import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    line-height: 1.5;
  }

  body {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.15em;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    line-height: 1.5;
  }
  
  img {
    max-width: 100%;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #000000;
  }
`

export default GlobalStyles