export const getErrorMessage = (message) => {
  switch (message) {
    case 'phone-not-verified':
      return 'Phone must be verified.'

    case 'under-21':
      return "You must be 21 years or older to rent a motorcycle on Twisted Road."

    case 'young-rider-partner-error':
      return 'Oops! You must be 25+ to test ride a motorcycle with this dealer.'

    case 'tr-insurance-required-for-partner-bike':
      return 'Twisted road insurance is required for partner bike.'

    case 'tr-insurance-required-for-same-day':
      return 'Twisted road insurance is required for same day rental.'

    case 'tr-insurance-required-for-rider-under-25':
      return 'Twisted road insurance is required for rider under 25.'

    case 'date-conflict-availability-hours':
      return 'Date conflict availability hours error.'

    default:
      return message
  }
}