import { Notifier } from '@airbrake/browser'
import {
  AIRBRAKE_API_KEY,
  AIRBRAKE_PROJECT_ID,
  BUILD_ENV,
  BLACKLISTED_ERRORS
} from '../../../constants/index'

export class NotifyAirbraker {
  constructor() {
    this.airbrake = new Notifier({
      projectId: AIRBRAKE_PROJECT_ID,
      projectKey: AIRBRAKE_API_KEY
    })
  }

  notifyError(error, info) {
    // Send error to Airbrake service
    if (!BLACKLISTED_ERRORS.includes(error.message)) {
      this.airbrake.notify({
        error: error,
        environment: { env: BUILD_ENV },
        params: { info: info }
      })
    }
  }
}