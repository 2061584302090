import React, { Component } from "react"
import { Button } from '../../styles/StyledElements'
import { Notifier } from '@airbrake/browser';
import {
  AIRBRAKE_API_KEY,
  AIRBRAKE_PROJECT_ID,
  BUILD_ENV,
  BLACKLISTED_ERRORS
} from '../../../constants/index'
import './notFound.css'

export default class MyErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      redirect: false
    }
    this.airbrake = new Notifier({
      projectId: AIRBRAKE_PROJECT_ID,
      projectKey: AIRBRAKE_API_KEY
    })
  }

  componentDidCatch(error, info) {
    // Send error to Airbrake service
    if (!BLACKLISTED_ERRORS.includes(error.message)) {
      this.airbrake.notify({
        error: error,
        environment: { env: BUILD_ENV },
        params: { info: info }
      })
    }
  }

  componentWillUnmount() { }
  static getDerivedStateFromError(error) {
    // Update state so next render shows fallback UI.
    return { error: error }
  }

  render() {
    if (this.state.error) {
      return (
        <div className="not-found-wrap ">
          <div className="not-found-wrap-inner">
            <div className="center-screen">
              <h1 className="has-text-centered">
                ERROR <span>We can't find what you're looking for.</span>
              </h1>
              <Button className="has-text-centered"><a href="/checkout/1">Go Back</a></Button>
            </div>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}