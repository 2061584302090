import styled from 'styled-components';

const FlexBox = styled.div`
  display: flex;
  ${({ flex }) => flex && `
    flex: ${flex}
  `}
  ${({ justify }) => justify && `
    justify-content: ${justify};
  `}
  ${({ align }) => align && `
    align-self: ${align};
  `}
  ${({ alignItems }) => alignItems && `
    align-items: ${alignItems};
  `}
  ${({ alignContent }) => alignContent && `
    align-content: ${alignContent};
  `}
  ${({ direction }) => direction && `
    flex-direction: ${direction};
  `}
  ${({ minWidth }) => minWidth && `
    min-width: ${minWidth};
  `}
  ${({ textAlign }) => textAlign && `
    text-align: ${textAlign};
  `}
  ${({ padding }) => padding && `
    padding: ${padding};
  `}
  ${({ marginTop }) => marginTop && `
    margin-top: ${marginTop};
  `}
  ${({ marginBottom }) => marginBottom && `
    margin-bottom: ${marginBottom};
  `}
  ${({ flexWrap }) => flexWrap && `
    flex-wrap: ${flexWrap};
  `}
  ${({ marginRight }) => marginRight && `
    margin-right: ${marginRight};
  `}
  ${({ marginLeft }) => marginLeft && `
    margin-left: ${marginLeft};
  `}
   ${({ width }) => width && `
    width: ${width};
  `}
    ${({ height }) => height && `
    height: ${height};
  `}
`;

export default FlexBox;