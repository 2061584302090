import { headers } from "./headers"
import { KLAVIYO_PUBLIC_API_KEY } from "../../../constants"

export const createKlaviyoProfile = (email, firstName, lastName, phone, source) => {
  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      data: {
        type: 'profile',
        attributes: {
          properties: {
            $email: email,
            $first_name: firstName,
            $last_name: lastName,
            $phone_number: phone,
            $source: source
          },
        },
      }
    })
  }

  fetch(`https://a.klaviyo.com/client/profiles/?company_id=${KLAVIYO_PUBLIC_API_KEY}`, options)
  .then(response => {
    console.log('klaviyo upadting profile success', response)
  })
  .catch(err => console.log('Filed to update klaviyo profile', err))
}

export const trackUserSignedUp = async (email, firstName, lastName, phone, source) => {
  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      data: {
        type: 'event',
        attributes: {
          properties: {
            $email: email,
            $first_name: firstName,
            $last_name: lastName,
            $phone_number: phone,
            $source: source
          },
          metric: {data: {type: 'metric', attributes: {name: 'User Signed Up'}}},
          profile: {
            data: {
              type: 'profile',
              attributes: {
                email: email,
                properties: {
                  Email: email,
                  FirstName: firstName,
                  LastName: lastName,
                  Phone: phone,
                  Source: source
                },//An object containing key/value pairs for any custom properties assigned to this profile
              }
            }
          }
        }
      }
    })
  }

  await fetch(`https://a.klaviyo.com/client/events/?company_id=${KLAVIYO_PUBLIC_API_KEY}`, options)
  .then(response => {
    console.log('klaviyo tracking user signed-up success', response)
  })
  .catch(err => {
    console.log('Failed to track klaviyo user signed-up', err)
  })
}

export const subscribeUser = async (subscribeData, callback = null) => {
  const { email, firstName, lastName, phone, source, vin } = subscribeData
  const options = {
    method: 'POST',
    headers: {revision: '2024-06-15', 'content-type': 'application/json'},
    body: JSON.stringify({
      data: {
        type: 'subscription',
        attributes: {
          profile: {
            data: {
              type: 'profile',
              attributes: {
                email: email,
                properties: {
                  FirstName: firstName,
                  LastName: lastName,
                  Phone: phone,
                  Source: source,
                  Vin: vin
                }
              },
            }
          }
        },
        relationships: {list: {data: {type: 'list', id: 'R9hYxA'}}}
      }
    })
  }

  await fetch(`https://a.klaviyo.com/client/subscriptions/?company_id=${KLAVIYO_PUBLIC_API_KEY}`, options)
  .then(response => {
    if (callback) callback()
    console.log('klaviyo subscription success', response)
  })
  .catch(err => {
    console.log('Failed klaviyo subscriptin', err)
  })
}
