import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  StyledCard,
  Image,
  StyledBikename,
  StyledPickUpInfo,
  StyledTotal,
} from './styles/StyledElements'
import TripInfo from './tripinformation/TripInfo'
import {
  useMotorcyclePricingQuery,
  useMotorcycleInfoQuery,
} from '../data/services/hobnob'
import {
  setSelectedInsurance,
  setBlackOutDates,
  setMotorcycleId,
  setPartnerWorkingHours,
  setPartnerLocation,
  setSecurityDepositAmount,
  setNetTotal,
  setPromoCodeMessage,
  setPromoSuccessMessage,
  setMotorcycleTimeZone,
  setPartnerLogoUrl,
  setPartnerAvatarUrl,
  setBikeID,
} from '../data/reducer'
import { ROUTING_URLS } from '../constants/routingUrls'
import { TIME_ZONE } from '../constants/index'
import { loading } from './utils/loading'

const BikeInfo = (props) => {
  const { checkout, isMobile } = props
  const dispatch = useDispatch()
  const locationName = useLocation()
  const pricingResult = useMotorcyclePricingQuery(checkout, {
    skip: checkout.motorcycleId ? false : true,
  })
  const motorcycleResult = useMotorcycleInfoQuery(checkout, {
    skip: checkout.VIN || checkout.VIN6 ? false : true,
  })
  const bikeImage = null

  useEffect(() => {
    if (pricingResult.status === 'fulfilled') {
      dispatch(
        setNetTotal(
          pricingResult.data.pricing?.totals?.net_total?.net_total_cents
            ? pricingResult.data.pricing?.totals?.net_total?.net_total_cents /
            100
            : 0
        )
      )
      if (pricingResult.data.pricing?.promotions?.promotion_error_message
        && checkout.promoCode !== null) {
        dispatch(
          setPromoCodeMessage(
            pricingResult.data.pricing?.promotions?.promotion_error_message
          )
        )
      } else {
        dispatch(setPromoCodeMessage(null))
      }
      const aplliedCode = pricingResult.data.pricing?.line_items.filter(item => item.type == 'promotion_code')
      if (aplliedCode?.length > 0) {
        dispatch(
          setPromoSuccessMessage((
            aplliedCode[0]?.promo_info?.code + " - " + aplliedCode[0]?.promo_info?.title
          )
          )
        )
      }
    }
    if (motorcycleResult.status === 'fulfilled') {
      if (!motorcycleResult.data) {
        window.open("https://www.twistedroad.com", "_self")
      }
      dispatch(
        setPartnerLogoUrl(
          motorcycleResult?.data?.partner_info?.partner_logo_url
        )
      )
      dispatch(
        setPartnerAvatarUrl(
          motorcycleResult?.data?.owner?.avatar?.url
        )
      )
      dispatch(
        setMotorcycleTimeZone(
          motorcycleResult?.data?.partner_info?.timezone ?
            motorcycleResult?.data?.partner_info?.timezone
            : motorcycleResult?.data?.timezone
        )
      )
      dispatch(
        setBlackOutDates(motorcycleResult.data?.motorcycle_blackout_dates[0])
      )
      const insuranceOption =
        motorcycleResult.data?.available_services?.rider_insurance_options.filter(
          (option) => option.title === 'Premium'
        )
      if (insuranceOption?.length > 0) {
        dispatch(setSelectedInsurance(insuranceOption[0]?.uuid))
      }
      dispatch(setMotorcycleId(motorcycleResult.data?.pretty_id))
      dispatch(setBikeID(motorcycleResult.data.id))
      dispatch(
        setPartnerWorkingHours(
          motorcycleResult.data?.partner_info?.partner_working_hours
        )
      )
      dispatch(
        setPartnerLocation(
          motorcycleResult.data?.partner_info?.partner_location
        )
      )
      dispatch(
        setSecurityDepositAmount(motorcycleResult.data?.pricing?.hold_amount)
      )
    }
  }, [motorcycleResult, pricingResult])

  // const netTotal = pricingResult.status === 'fulfilled' ? pricingResult.data.pricing.totals.net_total.net_total_cents / 100 : 0
  return (
    <>
      {motorcycleResult?.data && (
        <StyledCard>
          <Image
            src={motorcycleResult.data?.images[0]?.url}
            alt="bike-image"
            width={'100%'}
            height={'189px'}
            objectFit="cover"
          />
          {motorcycleResult.data && (
            <StyledBikename color={'#FFF'} margin={'6px 0'}>
              {motorcycleResult.data
                ? motorcycleResult.data?.year +
                ' ' +
                motorcycleResult.data?.title ||
                motorcycleResult.data?.make + ' ' + motorcycleResult.data?.model
                : null}
            </StyledBikename>
          )}
          <TripInfo checkout={checkout} isMobile={isMobile} />
          {pricingResult.status === 'fulfilled' &&
            ROUTING_URLS.includes(locationName?.pathname) && (
              <StyledTotal>Total: ${checkout.netTotal} (refundable)*</StyledTotal>
            )}
        </StyledCard>
      )}
    </>
  )
}

export default BikeInfo
