import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import FlexBox from './FlexBox'
import {
  Button,
  StyledSubTitle,
  StyledParagraph,
  StyledBoldSubHeading,
  StyledExperienceContainer,
  StyledInputFieldWrapper,
  StyledExperienceImage,
  StyledErrorLabel,
  Image,
} from './styles/StyledElements'
import Grid from '@material-ui/core/Grid'
import Slider from '@mui/material/Slider'
import { styled } from '@mui/material/styles'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { BIKE_CATEGORIES } from '../constants/BikeCategories'
import {
  useRiderExperienceMutation,
  useGetBasicInfoQuery,
} from '../data/services/hobnob'
import BrandingMobile from './mobile/BrandingMobile'
import BrandingDesktop from './utils/BrandingDesktop'
import { scrollToTop } from './utils/scrollTop'
import {
  setBikeCategory,
  setYearsOfExperience,
  setIsStyleSelected,
  setCurrentStep,
  setExperienceId,
  setBasicInfo
} from '../data/reducer'
import { loading } from './utils/loading'

function RiderExperience(props) {
  const CustomSlider = styled(Slider)({
    border: 0,
    height: 5,
    borderRadius: 3,
    color: '#6B5827',
    '& .css-eg0mwd-MuiSlider-thumb:hover, .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible':
    {
      boxShadow: 'none',
    },
    '& .css-1kz0hui-MuiSlider-valueLabel': {
      backgroundColor: '#6B5827',
      fontWeight: 600,
    },
  })

  const { checkout, isMobile } = props
  const { bikeCategory } = checkout
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const riderInfo = useGetBasicInfoQuery()
  const [riderExperience, riderExperienceResult] = useRiderExperienceMutation()

  const [generalError, setGeneralError] = useState(null)

  useEffect(() => {
    if (checkout.isReservationCompleted) {
      navigate('/checkout/success')
    } else if (!(checkout.currentStep >= 3)) {
      navigate(`/checkout/${checkout.currentStep}`)
    }
  }, [])

  useEffect(() => {
    if (riderInfo.status === 'fulfilled') {
      dispatch(setBasicInfo(riderInfo.data))
      const simple_experience_length =
        riderInfo.data?.user?.rider_simple_experiences.length
      const { category, years_experience, id } =
        simple_experience_length > 0
          ? riderInfo.data?.user?.rider_simple_experiences?.[
          simple_experience_length - 1
          ]
          : { category: null, years_experience: 0, id: null }
      if (!checkout.bikeCategory) {
        dispatch(setBikeCategory(category))
        dispatch(setYearsOfExperience(years_experience))
        dispatch(setIsStyleSelected(true))
      }
      dispatch(setExperienceId(id))
    }
    if (riderExperienceResult.status === 'fulfilled') {
      const simple_experience_length =
        riderExperienceResult.data?.user?.rider_simple_experiences.length
      const expId =
        riderExperienceResult.data?.user?.rider_simple_experiences[
          simple_experience_length - 1
        ]?.id
      if (props.isMobile) {
        dispatch(setCurrentStep(5))
        scrollToTop()
      } else {
        dispatch(setExperienceId(expId))
        dispatch(setCurrentStep(4))
        navigate('/checkout/4')
      }
    }

    if (riderExperienceResult.status === 'rejected' && generalError === null) {
      const { error } = riderExperienceResult
      setGeneralError(
        error.data.error_messages?.length > 0 ?
          error.data.error_messages[0] : "Something went wrong."
      )
    }
  }, [riderExperienceResult, riderInfo])

  const handleSliderChange = (event, newValue) => {
    dispatch(setYearsOfExperience(newValue))
    dispatch(setIsStyleSelected(true))
  }

  const handleNextClick = () => {
    if (props.isMobile) {
      dispatch(setCurrentStep(5))
    }
    riderExperience({
      category: checkout.bikeCategory,
      years: Number(checkout.experienceYears),
      id: checkout?.experienceId,
    })
  }

  return (
    <FlexBox direction="column" style={{ width: '100%' }}>
      {isMobile ? <BrandingMobile /> : <BrandingDesktop />}
      <StyledSubTitle marginBottom="1.5rem">Your Experience</StyledSubTitle>
      <StyledBoldSubHeading marginBottom="1rem">
        Which style of motorcycle do you have the most experience on?
      </StyledBoldSubHeading>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <StyledInputFieldWrapper>
            <StyledExperienceContainer
              disabled={
                checkout.bikeCategory === 'adventure' ||
                  checkout.bikeCategory === null
                  ? false
                  : true
              }
              width={'100%'}
              onClick={(e) => {
                e.preventDefault()
                setIsStyleSelected(checkout.bikeCategory ? false : true)
                dispatch(
                  setBikeCategory(checkout.bikeCategory ? null : 'adventure')
                )
              }}
              backgroundColor={
                bikeCategory === 'adventure' ? '#6B5827' : '#FFF'
              }
              color={bikeCategory === 'adventure' ? '#FFF' : '#424242'}
            >
              {bikeCategory === 'adventure' ? (
                <CheckRoundedIcon style={{ fontSize: '32px' }} />
              ) : (
                <AddRoundedIcon style={{ fontSize: '32px' }} />
              )}
              <StyledExperienceImage
                src={BIKE_CATEGORIES.adventureUrl}
                alt="adventure"
                invert={bikeCategory === 'adventure'}
              />
              <StyledParagraph
                fontWeight="600"
                color={bikeCategory === 'adventure' ? '#FFF' : '#424242'}
              >
                Adventure
              </StyledParagraph>
            </StyledExperienceContainer>
          </StyledInputFieldWrapper>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledInputFieldWrapper>
            <StyledExperienceContainer
              disabled={
                checkout.bikeCategory === 'cruiser' ||
                  checkout.bikeCategory === null
                  ? false
                  : true
              }
              width={'100%'}
              onClick={(e) => {
                e.preventDefault()
                setIsStyleSelected(checkout.bikeCategory ? false : true)
                dispatch(
                  setBikeCategory(checkout.bikeCategory ? null : 'cruiser')
                )
              }}
              backgroundColor={bikeCategory === 'cruiser' ? '#6B5827' : '#FFF'}
              color={bikeCategory === 'cruiser' ? '#FFF' : '#424242'}
            >
              {bikeCategory === 'cruiser' ? (
                <CheckRoundedIcon style={{ fontSize: '32px' }} />
              ) : (
                <AddRoundedIcon style={{ fontSize: '32px' }} />
              )}
              <StyledExperienceImage
                src={BIKE_CATEGORIES.cruiserUrl}
                alt="cruiser"
                invert={bikeCategory === 'cruiser'}
              />
              <StyledParagraph
                fontWeight="600"
                color={bikeCategory === 'cruiser' ? '#FFF' : '#424242'}
              >
                Cruiser
              </StyledParagraph>
            </StyledExperienceContainer>
          </StyledInputFieldWrapper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <StyledInputFieldWrapper>
            <StyledExperienceContainer
              disabled={
                checkout.bikeCategory === 'sport' ||
                  checkout.bikeCategory === null
                  ? false
                  : true
              }
              width={'100%'}
              onClick={(e) => {
                e.preventDefault()
                setIsStyleSelected(checkout.bikeCategory ? false : true)
                dispatch(
                  setBikeCategory(checkout.bikeCategory ? null : 'sport')
                )
              }}
              backgroundColor={bikeCategory === 'sport' ? '#6B5827' : '#FFF'}
              color={bikeCategory === 'sport' ? '#FFF' : '#424242'}
            >
              {bikeCategory === 'sport' ? (
                <CheckRoundedIcon style={{ fontSize: '32px' }} />
              ) : (
                <AddRoundedIcon style={{ fontSize: '32px' }} />
              )}
              <StyledExperienceImage
                src={BIKE_CATEGORIES.sportUrl}
                alt="sport"
                invert={bikeCategory === 'sport'}
              />
              <StyledParagraph
                fontWeight="600"
                color={bikeCategory === 'sport' ? '#FFF' : '#424242'}
              >
                Sport
              </StyledParagraph>
            </StyledExperienceContainer>
          </StyledInputFieldWrapper>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledInputFieldWrapper>
            <StyledExperienceContainer
              disabled={
                checkout.bikeCategory === 'standard' ||
                  checkout.bikeCategory === null
                  ? false
                  : true
              }
              width={'100%'}
              onClick={(e) => {
                e.preventDefault()
                setIsStyleSelected(checkout.bikeCategory ? false : true)
                dispatch(
                  setBikeCategory(checkout.bikeCategory ? null : 'standard')
                )
              }}
              backgroundColor={bikeCategory === 'standard' ? '#6B5827' : '#FFF'}
              color={bikeCategory === 'standard' ? '#FFF' : '#424242'}
            >
              {bikeCategory === 'standard' ? (
                <CheckRoundedIcon style={{ fontSize: '32px' }} />
              ) : (
                <AddRoundedIcon style={{ fontSize: '32px' }} />
              )}
              <StyledExperienceImage
                src={BIKE_CATEGORIES.standardUrl}
                alt="standard"
                invert={bikeCategory === 'standard'}
              />
              <StyledParagraph
                fontWeight="600"
                color={bikeCategory === 'standard' ? '#FFF' : '#424242'}
              >
                Standard
              </StyledParagraph>
            </StyledExperienceContainer>
          </StyledInputFieldWrapper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <StyledInputFieldWrapper>
            <StyledExperienceContainer
              disabled={
                checkout.bikeCategory === 'touring' ||
                  checkout.bikeCategory === null
                  ? false
                  : true
              }
              width={'100%'}
              onClick={(e) => {
                e.preventDefault()
                setIsStyleSelected(checkout.bikeCategory ? false : true)
                dispatch(
                  setBikeCategory(checkout.bikeCategory ? null : 'touring')
                )
              }}
              backgroundColor={bikeCategory === 'touring' ? '#6B5827' : '#FFF'}
              color={bikeCategory === 'touring' ? '#FFF' : '#424242'}
            >
              {bikeCategory === 'touring' ? (
                <CheckRoundedIcon style={{ fontSize: '32px' }} />
              ) : (
                <AddRoundedIcon style={{ fontSize: '32px' }} />
              )}
              <StyledExperienceImage
                src={BIKE_CATEGORIES.touringUrl}
                alt="touring"
                invert={bikeCategory === 'touring'}
              />
              <StyledParagraph
                fontWeight="600"
                color={bikeCategory === 'touring' ? '#FFF' : '#424242'}
              >
                Touring
              </StyledParagraph>
            </StyledExperienceContainer>
          </StyledInputFieldWrapper>
        </Grid>
        <Grid item xs={12} md={4}>
          <StyledInputFieldWrapper>
            <StyledExperienceContainer
              disabled={
                checkout.bikeCategory === 'three_wheel' ||
                  checkout.bikeCategory === null
                  ? false
                  : true
              }
              width={'100%'}
              onClick={(e) => {
                e.preventDefault()
                setIsStyleSelected(checkout.bikeCategory ? false : true)
                dispatch(
                  setBikeCategory(checkout.bikeCategory ? null : 'three_wheel')
                )
              }}
              backgroundColor={bikeCategory === 'three_wheel' ? '#6B5827' : '#FFF'}
              color={bikeCategory === 'three_wheel' ? '#FFF' : '#424242'}
            >
              {bikeCategory === 'three_wheel' ? (
                <CheckRoundedIcon style={{ fontSize: '32px' }} />
              ) : (
                <AddRoundedIcon style={{ fontSize: '32px' }} />
              )}
              <StyledExperienceImage
                src={BIKE_CATEGORIES.threeWheel}
                alt="three_wheel"
                invert={bikeCategory === 'three_wheel'}
              />
              <StyledParagraph
                fontWeight={600}
                color={bikeCategory === 'three_wheel' ? '#FFF' : '#424242'}
              >
                Three Wheel
              </StyledParagraph>
            </StyledExperienceContainer>
          </StyledInputFieldWrapper>
        </Grid>
      </Grid>
      <StyledBoldSubHeading marginBottom={'2rem'}>
        How many years of riding experience do you have on this style of bike?
      </StyledBoldSubHeading>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent={'flex-start'}
        alignItems={'flex-start'}
      >
        <Grid item xs={10} md={5}>
          <CustomSlider
            value={checkout.experienceYears}
            onChange={handleSliderChange}
            aria-labelledby="years-of-experience-slider"
            valueLabelDisplay="on"
            min={0}
            max={15}
          />
        </Grid>
      </Grid>
      <StyledErrorLabel>{generalError ? generalError : null}</StyledErrorLabel>
      <FlexBox>
        <Button
          marginTop={isMobile ? '1rem' : '8rem'}
          twistedGold
          twistedDarkBlack
          disabled={
            checkout.isStyleSelected &&
              checkout.experienceYears > 0 &&
              !riderExperienceResult.isLoading
              ? false
              : true
          }
          onClick={() => handleNextClick()}
        >
          {riderExperienceResult.isLoading ? (
            <FlexBox direction="row" alignItems="center" justify="center">
              <Image src={loading.LOADING} width="25px" height="25px" />
            </FlexBox>
          ) : (
            'Next'
          )}
        </Button>
      </FlexBox>
    </FlexBox>
  )
}

export default RiderExperience
