import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import RecentActorsIcon from '@mui/icons-material/RecentActors'
import FlexBox from '../FlexBox'
import WrapUpModal from './WrapUpModal'
import BrandingMobile from '../mobile/BrandingMobile'
import BrandingDesktop from '../utils/BrandingDesktop'
import { scrollToTop } from '../utils/scrollTop'
import { getErrorMessage } from '../utils/bookRequestErrors'
import moment from 'moment-timezone/builds/moment-timezone-with-data'
import InformationWizardVouched from '../vouched/informationWizardVouched'
import { toast } from 'react-toastify'
import { NotifyAirbraker } from '../elements/errors/notifyAirbraker'
import {
  Button,
  StyledSubTitle,
  StyledH4,
} from '../styles/StyledElements'
import {
  setBookedRideUuid,
  setCurrentStep,
  setIsReservationCompleted,
  setRiderRequestId
} from '../../data/reducer'
import {
  useCreateRequestMutation,
  useBookRequestMutation,
  useRiderVouchedDataMutation
} from '../../data/services/hobnob'

function VouchedModal(props) {
  const { checkout, isMobile } = props
  const airbrake = new NotifyAirbraker()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showWrapupModal, setShowWrapupModal] = useState(true)
  const [modalOpen, setModalOpen] = useState(false)
  const [skipVouched, setSkipVouched] = useState(true)
  const [showWizard, setShowWizard] = useState(false)
  const [iscreateCalled, setIsCreateCalled] = useState(true)
  const [requestUuId, setRequestUuId] = useState(null)
  const [isCreateSuccess, setIsCreateSuccess] = useState(false)
  const [isBookSuccess, setBookSuccess] = useState(false)
  const [createRequestError, setCreateRequestError] = useState(null)
  const [bookRequestError, setBookRequestError] = useState(null)
  const [showBookSuccess, setShowBookSuccess] = useState(false)
  const [createRequest, createRequestResult] = useCreateRequestMutation()
  const [bookRequest, bookRequestResult] = useBookRequestMutation()
  const [riderVouchedData, riderVouchedDataResult] = useRiderVouchedDataMutation()

  const closeModal = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return
    } else {
      setModalOpen(true)
    }
  }

  const showToastMessage = (message) => {
    toast.error(message, {
      position: 'top-right',
      icon: false,
      closeButton: true,
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined
    })
    airbrake.notifyError(message)
  }

  useEffect(() => {
    if (
      checkout?.bookedRideUuid && (
        !(checkout?.basic_info?.user?.rider.drivers_license.mvr_confirmed &&
          checkout?.basic_info?.user?.rider.has_booked_in_the_past_year
        ) ||
        checkout?.basic_info?.user?.rider.drivers_license.expired
      )
    ) {
      setModalOpen(true)
      setSkipVouched(false)
    }
  }, [])

  useEffect(() => {
    if (checkout?.requestId == null) {
      try {
        createRequest({
          motorcycleId: checkout.motorcycleId,
          rideDate: checkout.rideDate,
          pickupTime: checkout.selectedTimeFormat,
          dropoffTime: checkout.dropoffTimeFormat,
          dropoffDate: checkout.dropoffDate,
          timeZone: checkout.motorcycleTimeZone,
        })
      } catch (error) {
        console.log('create-req-error', error)
      }
    }
  },[])

  useEffect(() => {
    if (checkout?.bookedRideUuid == null && isCreateSuccess) {
      try {
        bookRequest({
          requestUuid: requestUuId,
          rideDate: checkout.rideDate,
          pickupTime: checkout.selectedTimeFormat,
          paymentsCardUuid: checkout.paymentsCardUuid,
          termsServiceAgreed: checkout.termsServiceAgreed,
          riderInsurance: checkout.selectedInsurance,
          promotionCode: checkout.promoCode,
          dropoffTime: checkout.dropoffTimeFormat,
          dropoffDate: moment(props.checkout?.dropoffDate).format('YYYY-MM-DD'),
          timeZone: checkout.motorcycleTimeZone,
        })
      } catch (error) {
        console.log('book-error', error)
      }
    }
  }, [isCreateSuccess])

  useEffect(() => {
    if (isBookSuccess) {
      if (isMobile === true) {
        dispatch(setCurrentStep(7))
        dispatch(setIsReservationCompleted(true))
        setTimeout(() => {
          navigate('/checkout/success')
          scrollToTop()
          setShowBookSuccess(false)
        }, 4000)
      } else {
        dispatch(setCurrentStep(6))
        dispatch(setIsReservationCompleted(true))
        setTimeout(() => {
          navigate('/checkout/success')
          setShowBookSuccess(false)
        }, 4000)
      }
    } else if (checkout.isReservationCompleted) {
      navigate('/checkout/success')
    } else if (checkout.currentStep < 5) {
      navigate(`/checkout/${checkout.currentStep}`)
    } 
    
  }, [isBookSuccess])

  if (bookRequestResult.status === 'fulfilled' && !isBookSuccess && skipVouched) {
    dispatch(setBookedRideUuid(bookRequestResult.data?.request_uuid))
    riderVouchedData({
      reservationID: requestUuId,
      stepType: 'payment'
    })
    setIsCreateSuccess(false)
    if (
      checkout?.basic_info?.user?.rider.drivers_license.mvr_confirmed &&
      checkout?.basic_info?.user?.rider.has_booked_in_the_past_year &&
      !checkout?.basic_info?.user?.rider.drivers_license.expired
    ) {
      setShowWrapupModal(true)
      setBookSuccess(true)
      setShowBookSuccess(true)
    } else {
      setSkipVouched(false)
      setModalOpen(true)
      setShowWrapupModal(true)
    }
  }

  if (bookRequestResult.status === 'rejected' && bookRequestError === null) {
    const { error } = bookRequestResult
    const errorMessage = getErrorMessage(error.data.error_messages[0])
    setBookRequestError(errorMessage)
    const info = {
      source: 'Testride booking failed.',
      requestId: checkout?.requestId
    }
    airbrake.notifyError(errorMessage, info)
    dispatch(setRiderRequestId(null))
  }

  if (createRequestResult.status === 'fulfilled' && requestUuId === null) {
    const { data } = createRequestResult
    dispatch(setRiderRequestId(data.request_id))
    setRequestUuId(data.request_uuid)
    setIsCreateSuccess(true)
  }

  if (
    createRequestResult.status === 'rejected' &&
    createRequestError === null
  ) {
    const { error } = createRequestResult
    const errorMessage = error.data.error_messages?.length > 0 ?
      getErrorMessage(error.data.error_messages[0]) : "Testride request creation failed."
    setCreateRequestError(errorMessage)
    const info = {
      source: 'Testride create request failed.',
      requestId: checkout?.requestId
    }
    airbrake.notifyError(errorMessage, info)
  }

  const wizardOnSuccess = () => {
    setShowBookSuccess(true)
    setShowWrapupModal(true)
    setIsCreateCalled(false)
    setBookSuccess(true)
  }

  const wizardOnFailure = () => {
    setModalOpen(true)
    setBookRequestError(null)
    setCreateRequestError(null)
    showToastMessage('Testride vouched verification failed.')
    if (isMobile) {
      dispatch(setCurrentStep(5))
      scrollToTop()
    } else {
      dispatch(setCurrentStep(4))
      navigate('/checkout/4')
    }
  }

  const clearVlaues = () => {
    setBookRequestError(null)
    setCreateRequestError(null)
    dispatch(setRiderRequestId(null))
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {isMobile ? <BrandingMobile /> : <BrandingDesktop />}
      {modalOpen &&
        <Dialog
          open={modalOpen}
          onClose={() => closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {!skipVouched && (
            <FlexBox
              direction="column"
              alignItems="center"
              justify="center"
              textAlign="center"
              padding="0.5rem 1.5rem"
            >
              <FlexBox
                direction="row"
                alignItems="center"
                marginTop={isMobile ? '0.5rem' : '1.5rem'}
                marginBottom={isMobile ? '0rem' : '0.5rem'}
              >
                <RecentActorsIcon sx={[{ fontSize: "4rem", color: "#695626" }]} />
              </FlexBox>
              <StyledSubTitle marginBottom="-0.5rem">One last step!</StyledSubTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <StyledH4 twistedBlack>
                    You'll need to enter the <br />
                    following information
                  </StyledH4>
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  <StyledH4
                    twistedBlack
                    margin="0.5rem 0 0 0"
                    fontWeight="600"
                  >
                    <li>Driver's license</li>
                  </StyledH4>
                </DialogContentText>
              </DialogContent>
              <DialogActions className="vouched-dialog-action">
                <Button
                  padding={!isMobile ? '0.5em 5em' : '0.5em 3em'}
                  twistedGold
                  twistedGoldDark
                  twistedDarkBlack
                  onClick={() => {
                    setModalOpen(false)
                    setShowWizard(true)
                  }}
                  disabled={false}
                >
                  Start Now
                </Button>
              </DialogActions>
            </FlexBox>
          )}
        </Dialog>
      }
      {showWizard &&
        <InformationWizardVouched
          reservationID={requestUuId}
          checkout={checkout}
          isMobile={isMobile}
          modalOpen={showWizard}
          onSuccess={wizardOnSuccess}
          onFailure={wizardOnFailure}
          onReset={() => setShowWizard(false)}
        />
      }
      {showWrapupModal &&
        <WrapUpModal
          wrapUpModalOpen={showWrapupModal}
          isMobile={isMobile}
          showSuccess={showBookSuccess}
          checkoutError={
            [
              createRequestError,
              bookRequestError,
            ].every(element => element === null)
          }
          checkoutErrorMessage={[
            createRequestError,
            bookRequestError,
          ]}
          onError={clearVlaues}
        />
      }
    </div>
  )
}

export default VouchedModal