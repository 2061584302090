import * as Yup from 'yup'

export const klaviyoFormSchema = Yup.object().shape({
  email: Yup.string()
    .nullable()
    .email('Email address is not valid.')
    .required('Email is required.'),
  firstName: Yup.string()
    .nullable()
    .required('First name is required.'),
  lastName: Yup.string()
    .nullable()
    .required('Last name is required.'),
  phoneInput: Yup.string()
    .nullable()
    .required('Mobile number is required'),
})
