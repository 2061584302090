import './App.css'
import { useSelector, useDispatch } from 'react-redux'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { useEffect, useMemo, useState, Suspense, lazy } from 'react'
import FlexBox from './components/FlexBox'
import {
  Container,
  Highlight,
  Content,
  Image,
} from './components/styles/StyledElements'
import { setIsReservationCompleted, setVin, setVin6, setSource } from './data/reducer'
import GlobalStyles from './components/styles/Global'
import CheckoutSteps from './components/CheckoutSteps'
import CheckoutStepsMobile from './components/mobile/CheckoutStepsMobile'
import BikeInfo from './components/BikeInfo'
import MyErrorBoundary from './components/elements/errors/errorBounds'
import KlaviyoModal from './components/modal/klaviyo/klaviyoModal'

const StepDateTime = lazy(() => import('./components/StepDateTime'))
const AboutYou = lazy(() => import('./components/AboutYou'))
const SignUp = lazy(() => import('./components/signup/SignUp'))
const LogIn = lazy(() => import('./components/login/LogIn'))
const RiderExperience = lazy(() => import('./components/RiderExperience'))
const Payments = lazy(() => import('./components/Payments'))
const Vouched = lazy(() => import('./components/vouched/vouched'))
const DriverLicense = lazy(() => import('./components/DriverLicense'))
const Success = lazy(() => import('./components/Success'))


function App() {
  const locationName = useLocation()
  const dispatch = useDispatch()

  const query = new URLSearchParams(window.location.search)
  const vin = query.get('vin')
  const vin6 = query.get('vin6')
  const source = query.get('source')

  if (source) {
    dispatch(setSource(source))
  }

  if (vin) {
    dispatch(setIsReservationCompleted(false))
    dispatch(setVin(vin))
  } else if (vin6) {
    dispatch(setIsReservationCompleted(false))
    dispatch(setVin6(vin6))
  }

  const checkout = useSelector(({ checkout }) => checkout)

  if (checkout.VIN === null && checkout.VIN6 === null) {
    window.open("https://www.twistedroad.com", "_self")
  }

  const [width, setWidth] = useState(window.innerWidth)
  const handleResize = () => setWidth(window.innerWidth)
  const isMobile = useMemo(() => width <= 768, [width])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <MyErrorBoundary>
      {/* when source === testrde means rider revisiting the motorcycle where he had shown interest in it. */}
      {( checkout.showKlaviyoModal && checkout.source !== 'testride') &&
        <KlaviyoModal
          isMobile={isMobile}
        />
      }
      <Container isMobile={isMobile}>
        <FlexBox
          direction={isMobile ? 'column' : 'row'}
          alignItems="center"
          flex={1}
        >
          <GlobalStyles />
          <Highlight isMobile={isMobile}>
            {checkout.partner_logo_url &&
              <Image
                src={checkout.partner_logo_url}
                alt="partner-logo"
                height={isMobile ? '180px' : '200px'}
                objectFit="contain"
                marginBottom={'16px'}
                marginTop={isMobile ? '16px' : '8px'}
              />
            }
            {!isMobile && (
              <CheckoutSteps checkout={checkout} isMobile={isMobile} />
            )}
            {isMobile && (
              <CheckoutStepsMobile checkout={checkout} isMobile={isMobile} />
            )}
            {!isMobile && !(locationName?.pathname === '/checkout/success') && (
              <BikeInfo checkout={checkout} isMobile={isMobile} />
            )}
          </Highlight>
          {!isMobile && (
            <Content isMobile={isMobile}>
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route
                    exact
                    path="/checkout/1"
                    element={
                      <StepDateTime checkout={checkout} isMobile={isMobile} />
                    }
                  />
                  <Route
                    exact
                    path="/checkout/2"
                    element={<AboutYou checkout={checkout} isMobile={isMobile} />}
                  />
                  <Route
                    exact
                    path="/checkout/2/signup"
                    element={<SignUp checkout={checkout} isMobile={isMobile} />}
                  />
                  <Route
                    exact
                    path="/checkout/2/login"
                    element={<LogIn checkout={checkout} isMobile={isMobile} />}
                  />
                  <Route
                    exact
                    path="/checkout/3"
                    element={
                      <RiderExperience checkout={checkout} isMobile={isMobile} />
                    }
                  />
                  <Route
                    exact
                    path="/checkout/4"
                    element={<Payments checkout={checkout} isMobile={isMobile} />}
                  />
                  <Route
                    exact
                    path="/checkout/5/vouched"
                    element={<Vouched checkout={checkout} isMobile={isMobile} />}
                  />
                  <Route
                    exact
                    path="/checkout/5"
                    element={
                      <DriverLicense checkout={checkout} isMobile={isMobile} />
                    }
                  />
                  <Route
                    exact
                    path="/checkout/success"
                    element={<Success checkout={checkout} isMobile={isMobile} />}
                  />
                  <Route path="*" element={<Navigate to="/checkout/1" />} />
                </Routes>
              </Suspense>
            </Content>
          )}
        </FlexBox>
      </Container>
    </MyErrorBoundary>
  )
}

export default App
