import { configureStore } from '@reduxjs/toolkit'
import { checkoutReducer } from './reducer'
import { hobnobApi } from './services/hobnob'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import sessionStorage from 'redux-persist/lib/storage/session'

const persistConfig = {
  key: 'checkout',
  version: 1,
  storage: sessionStorage,
}

const persistedReducer = persistReducer(persistConfig, checkoutReducer)

export default configureStore({
  reducer: {
    checkout: persistedReducer,
    [hobnobApi.reducerPath]: hobnobApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    }
  }).concat(hobnobApi.middleware)
})