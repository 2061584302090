import FlexBox from '../FlexBox'
import styled from 'styled-components'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form"


export const StyledPhoneInput = styled(PhoneInputWithCountry).attrs()`
  &.PhoneInput {
    width: 100% !important;
  }

  & .PhoneInputInput {
    height: 40px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #8b8b8b;
  }
  & input:focus {
    outline: none;
    border: 2px solid #ae975e;
  }

  & .PhoneInputCountry select {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
  }
`

export const Container = styled.div.attrs((props) => ({
  isMobile: props.isMobile,
}))`
  width: ${(props) => (props.isMobile ? '100%' : '1200px')};
  margin: auto;
  font-size: 16px;
  line-height: 1.25em;
`

export const Highlight = styled(FlexBox).attrs((props) => ({
  isMobile: props.isMobile,
}))`
  background: ${(props) => props.theme.twistedBlack};
  color: ${(props) => props.theme.twistedWhite};
  padding: ${(props) => (props.isMobile ? '0px' : '1em 2em')};
  flex: 1;
  height: 1132px;
  width: ${(props) => (props.isMobile ? '100%' : '400px')};
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Content = styled(FlexBox).attrs((props) => ({
  isMobile: props.isMobile,
}))`
  flex: ${(props) => (props.isMobile ? 1 : 3)};
  padding: 1em 2em;
  width: ${(props) => (props.isMobile ? '100%' : '900px')};
  height: 834px;
`

export const StyledH1 = styled.h1.attrs((props) => ({
  color: props.twistedBlack
    ? props.theme.twistedBlack
    : props.twistedDarkBlack
      ? props.theme.twistedDarkBlack
      : props.theme.twistedBrown,
}))`
  color: ${(props) => props.color};
  font-style: normal;
  font-weight: bold;
  font-size: ${({ fontSize }) => fontSize || '32px'};
  line-height: 44px;
  ${({ marginBottom }) =>
    marginBottom &&
    `
    margin-bottom: ${marginBottom};
  `}
  ${({ marginTop }) =>
    marginTop &&
    `
    margin-top: ${marginTop};
  `}
`

export const StyledH2 = styled.h2.attrs((props) => ({
  color: props.twistedBrown
    ? props.theme.twistedBrown
    : props.theme.twistedBlack,
}))`
  color: ${(props) => props.color};
  ${({ marginBottom }) =>
    marginBottom &&
    `
    margin-bottom: ${marginBottom};
  `}
    ${({ fontSize }) =>
    fontSize &&
    `
    font-size: ${fontSize};
  `}
`

export const StyledH4 = styled.h4.attrs((props) => ({
  color: props.twistedBlack
    ? props.theme.twistedBlack
    : props.theme.twistedBrownLight,
}))`
  color: ${(props) => props.color};
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  font-size: 16px;
  font-size: ${({ fontSize }) => fontSize} !important;
  line-height: 22px;
  margin: ${({ margin }) => margin};
`

export const StyledSubTitle = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: ${({ fontSize }) => fontSize || '24px'};
  line-height: 33px;
  text-transform: capitalize;
  color: #212121;
  margin-bottom: ${({ marginBottom }) => marginBottom || '1rem'};
  margin-top: ${({ marginTop }) => marginTop};
`

export const StyledBoldSubHeading = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  ${({ color }) =>
    color &&
    `
    color: ${color} !important;
  `}
  ${({ marginBottom }) =>
    marginBottom &&
    `
    margin-bottom: ${marginBottom};
  `}
  ${({ marginTop }) =>
    marginTop &&
    `
    margin-top: ${marginTop};
  `}
  ${({ marginRight }) =>
    marginRight &&
    `
  margin-right: ${marginRight};
  `}
  ${({ marginLeft }) =>
    marginLeft &&
    `
  margin-left: ${marginLeft};
`}
`

export const StyledItalic = styled.p`
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  ${({ color }) =>
    color &&
    `
    color: ${color} !important;
  `}
  ${({ marginBottom }) =>
    marginBottom &&
    `
    margin-bottom: ${marginBottom};
  `}
  ${({ marginTop }) =>
    marginTop &&
    `
    margin-top: ${marginTop};
  `}
`

export const StyledSpan = styled.span`
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || 'bold'};
  font-size: ${({ fontSize }) => fontSize || '16px'};
  padding: ${({ padding }) => padding};
  line-height: 22px;
  cursor: pointer;
  color: ${(props) =>
    props.twistedBrownDark
      ? props.theme.twistedBrownDark
      : props.theme.twistedBrown};
  
  &.reset-password:hover, &.remove-promo-code:hover {
    background-color: #f9f9f9;
    border-radius: 4px;
  }
`

export const StyledBikename = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: ${({ color }) => color || '#000000'};
  margin: ${({ margin }) => margin};
`

export const StyledTotal = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  color: #ffffff;
  margin-bottom: 0;
`

export const StyledPickUpInfo = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  margin: 6px 0;
  color: ${({ color }) => color || '#000000'};
`

export const StyledParagraph = styled.p`
  font-style: normal;
  ${({ fontWeight }) =>
    fontWeight &&
    `
  font-weight: ${fontWeight};
  `}
  font-size: 16px;
  line-height: 22px;
  color: ${(color) => color || '#000000'};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
  margin-top: ${({ marginTop }) => marginTop || 0};
`

export const Button = styled.button.attrs((props) => ({
  gradientStart: props.twistedBrown
    ? props.theme.twistedBrownLight
    : props.twistedGold
      ? props.theme.twistedGoldLight
      : props.theme.twistedGray,
  gradientEnd: props.twistedBrown
    ? props.theme.twistedBrownDark
    : props.twistedGold
      ? props.theme.twistedGoldDark
      : props.theme.twistedGray,
}))`
  background: linear-gradient(
    180deg,
    ${(props) => props.gradientStart} 0%,
    ${(props) => props.gradientEnd} 100%
  );
  background: ${({ transparent }) => transparent};
  border: none;
  padding: ${({ padding }) => padding || '0.75em 4em'};
  font-weight: bold;
  color: ${(props) =>
    props.twistedDarkBlack
      ? props.theme.twistedDarkBlack
      : props.twistedBrown
        ? props.theme.twistedWhite
        : props.theme.twistedWhite};
  border-radius: 5px;
  border-top-left-radius: ${({ borderTopLeft }) => borderTopLeft} !important;
  border-bottom-left-radius: ${({ borderBottomLeft }) => borderBottomLeft} !important;
  font-size: 1em;
  ${({ fontColor }) =>
    fontColor &&
    `
  color: ${fontColor} !important;
  `}
  cursor: pointer;
  ${({ marginTop }) =>
    marginTop &&
    `
  margin-top: ${marginTop};
  `}
  ${({ marginLeft }) =>
    marginLeft &&
    `
  margin-left: ${marginLeft};
  `}
  ${({ marginBottom }) =>
    marginBottom &&
    `
  margin-bottom: ${marginBottom} !important;
  `}
  ${({ fullWidth }) =>
    fullWidth &&
    `
  width: 100%;
  `}
  ${({ width }) =>
    width &&
    `
  width: ${width};
  `}
  margin-bottom: 1rem;

  :disabled {
    background: ${(props) => props.theme.twistedGray};
    cursor: default;
    color: #fff;
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: ${({ padding }) => padding ||
    '0.75em 2.5em'} !important;
  }
`

export const StyledSignUPWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  align-items: center;
  justify-content: center;
`

export const Image = styled.img`
  ${({ width }) =>
    width &&
    `
    width: ${width};
  `}
  ${({ height }) =>
    height &&
    `
    height: ${height};
  `}
  ${({ maxHeight }) =>
    maxHeight &&
    `
    max-height: ${maxHeight};
  `}
  ${({ objectFit }) =>
    objectFit &&
    `
    object-fit: ${objectFit};
  `}
  ${({ invert }) =>
    invert &&
    `
    filter: invert(${invert});
  `}
  ${({ marginLeft }) =>
    marginLeft &&
    `
    margin-left: ${marginLeft};
  `}
  ${({ marginRight }) =>
    marginRight &&
    `
    margin-right: ${marginRight};
  `}
  ${({ marginBottom }) =>
    marginBottom &&
    `
    margin-bottom: ${marginBottom};
  `}
  ${({ marginTop }) =>
    marginTop &&
    `
    margin-top: ${marginTop};
  `}
  ${({ borderRadius }) =>
    borderRadius &&
    `
    border-radius: ${borderRadius};
  `}
  max-width: 100%;

  // @media (max-width: ${({ theme }) => theme.mobile}) {
  //   margin: 10px 0 10px;
  // }
`

export const StyledCheckoutLists = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`

export const CheckoutItems = styled.ul`
  color: #af985f;
  text-align: left;
  list-style: none;
  padding-left: 0;
  margin-bottom: 1rem;

  li a {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    text-decoration: none;
    padding-left: 10px;
  }

  &:hover {
    opacity: 0.9;
  }
`

export const StyledCard = styled.div`
  display: flex;
  align-items: left;
  border-radius: 16px;
  margin: 40px 0;
  flex-direction: column;
  box-sizing: border-box;
  border: 2px solid #e7e7e7;
  box-sizing: border-box;
  padding: 8px;
  width: 352px;
  ${({ objectFit }) =>
    objectFit &&
    `
    object-fit: ${objectFit};
  `}
  img {
    border-radius: 8px;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;
  }
`

export const StyledSignUpForm = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2rem;
`
export const StyledRadioField = styled.input`
  &[type='radio'] {
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    border: 2px solid #8b8b8b;
    border-radius: 50%;
    outline: none;
  }

  &[type='radio']:before {
    content: '';
    display: block;
    width: 70%;
    height: 70%;
    margin: 15% auto;
    border-radius: 50%;
  }
  &[type='radio']:checked:before {
    background: #af985f;
    border: 2px solid #af985f;
  }
`

export const StyledInputField = styled.input`
  border: 1px solid #8b8b8b;
  box-sizing: border-box;
  border-radius: 8px;
  ${({ marginTop }) =>
  marginTop &&
    `
  margin-top: ${marginTop};
  `}
  ${({ width }) =>
    width &&
    `
  width: ${width};
  `}
  ${({ height }) =>
    height &&
    `
  height: ${height};
  `}
  ${({ placeholder }) =>
    placeholder &&
    `
  placeholder: ${placeholder};
  `}
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  padding-left: ${({ paddingLeft }) => paddingLeft || '16px'};
  padding-right: ${({ paddingRight }) => paddingRight};

  ::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    text-align: ${({ textAlign }) => textAlign};
  }

  &:focus {
    outline: none !important;
    border: ${({ theme }) => '2px solid' + theme.twistedBrownLight};
  }

  &[type='checkbox'] {
    cursor: pointer;
  }
`

export const StyledSelect = styled.select`
  border: 1px solid #8b8b8b;
  box-sizing: border-box;
  border-radius: 8px;
  ${({ width }) =>
    width &&
    `
  width: ${width};
  `}
  ${({ height }) =>
    height &&
    `
  height: ${height};
  `}
  background: transparent;
  font-style: normal;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  padding-left: 16px;
  cursor: pointer;
  appearance: ${(props) => (props.appearance ? 'none' : '')};

  &:focus {
    outline: none !important;
    border: ${({ theme }) => '1px solid' + theme.twistedBrownLight};
  }

  &::after {
    padding-right: 8px;
  }
`
export const StyledOptions = styled.option`
  color: black;
  display: flex;
  white-space: pre;
  min-height: 20px;
  cursor: pointer;
  margin: 40px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

`

export const StyledInputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: ${({ paddingBottom }) => paddingBottom || '18px'};
`

export const StyledFieldLabel = styled.label`
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  padding-bottom: ${({ paddingBottom }) => paddingBottom || '4px'};
  padding-top: ${({ paddingTop }) => paddingTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`

export const StyledExperienceContainer = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  border-radius: 20px;
  padding: 0.5rem 1.2rem;
  border: 2px solid #b5b5b5;
  color: ${({ color }) => color}
  background: ${(backgroundColor) => backgroundColor};
  ${({ width }) =>
    width &&
    `
  width: ${width};
  `}
  height: 44px;

  &:hover {
    cursor: pointer;
  }

  :disabled {
    background: ${(props) => props.theme.twistedGray};
    cursor: not-allowed;
    opacity: 0.5
  }  
`

export const StyledExperienceImage = styled.img`
  width: 2.5rem;
  height: 1.5rem;
  margin: 0 12px;
  object-fit: ${({ objectFit }) => objectFit};
  ${({ invert }) =>
    invert &&
    `
  filter: invert(100%);
  `}
`

export const StyledErrorLabel = styled.p`
  color: #ff0000 !important;
  font-size: 14px;
  padding: 4px;
  ${({ marginBottom }) =>
    marginBottom &&
    `
  margin-bottom: ${marginBottom};
  `}
  ${({ marginTop }) =>
    marginTop &&
    `
  margin-top: ${marginTop};
  `}
  ${({ fontSize }) =>
    fontSize &&
    `
  font-size: ${fontSize}px !important;
  `}
`

export const StyledMapContainer = styled.div.attrs((props) => ({
  isMobile: props.isMobile,
}))`
	height: 200px !important;
	width: 100% !important;
`

export const StyledPopUpWrapper = styled.div`
  padding: ${({ padding }) => padding};
`

export const StyledAbbr = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
`

export const StyledSuccess = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #00a36a;
  ${({ marginBottom }) =>
    marginBottom &&
    `
  margin-bottom: ${marginBottom};
  `}
  ${({ marginTop }) =>
    marginTop &&
    `
  margin-top: ${marginTop};
  `}
`

