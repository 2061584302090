import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import FlexBox from './FlexBox'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_PUBLISHABLE_KEY } from '../constants'
import AddStripePayment from './AddStripePayment'
import { Elements } from '@stripe/react-stripe-js'
import BrandingMobile from './mobile/BrandingMobile'
import BrandingDesktop from './utils/BrandingDesktop'
import {
  StyledH1,
  StyledSubTitle,
  StyledParagraph,
  Image,
  StyledAbbr,
} from './styles/StyledElements'
import { loading } from './utils/loading'
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)

function Payments(props) {
  const { checkout, isMobile } = props
  const navigate = useNavigate()
  useEffect(() => {
    if (checkout.isReservationCompleted) {
      navigate('/checkout/success')
    } else if (!(checkout.currentStep >= 4)) {
      navigate(`/checkout/${checkout.currentStep}`)
    }
  }, [])
  return (
    <FlexBox direction="column">
      {isMobile ? <BrandingMobile /> : <BrandingDesktop />}
      {checkout.paymentInfo?.length > 0 ? (
        <StyledSubTitle>Saved Payment Methods</StyledSubTitle>
      ) : null}
      <div className="add-payment">
        <Elements stripe={stripePromise}>
          <AddStripePayment checkout={checkout} isMobile={isMobile} />
        </Elements>
      </div>
      {!isMobile && (
        <StyledAbbr marigTop="2rem">
          * The test ride fee covers insurance costs and wear and tear on the
          motorcycle during the Extended Test Ride period. This fee is fully
          refundable if you choose to purchase the bike.
        </StyledAbbr>
      )}
    </FlexBox>
  )
}

export default Payments
