import loadingImage1 from '../../images/loading/loading1.gif'
import loadingImage2 from '../../images/loading/loading2.gif'
import twistedRoad_Logo from '../../../src/images/tr-logo/twisted-road-logo-black.png'
import tr_logo_2 from '../../../src/images/tr-logo/twisted-road-logo-black-seo.png'
import harley_davidson from '../../images/harley-davidson.png'
import harley_davidson_removed_bg from '../../images/harley-davidson-removed-bg.png'
import try_me_modal_image from '../../images/try-me-modal-image.png'

export const loading = {
    LOADING: loadingImage1,
    LOADING2: loadingImage2,
    TR_LOGO: twistedRoad_Logo,
    TR_LOGO_2: tr_logo_2,
    HARLEY_DAVIDSON: harley_davidson,
    HARLEY_DAVIDSON_REMOVED_BG: harley_davidson_removed_bg,
    TRY_ME_MODAL_IMAGE: try_me_modal_image
}