import React, { useEffect, useState } from 'react'
import { loadDynamicScript } from '../../constants/loadExternalScript'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import RecentActorsIcon from '@mui/icons-material/RecentActors'
import FlexBox from '../FlexBox'
import {
  Button,
  StyledSubTitle,
  StyledH4,
} from '../styles/StyledElements'
import Cookies from 'js-cookie'
import { useRiderVouchedDataMutation } from '../../data/services/hobnob'

function InformationWizardVouched(props) {
  const { checkout, isMobile, reservationID } = props
  const [modalOpen, setWizardModalClose] = useState(props.modalOpen)
  const [vouchedReady, setVouchedReady] = useState(false)
  const [vouchedObject, setVouchedObject] = useState()
  const [vouchedLoading, setVouchedLoading] = useState(true)
  const [vouchedModalOpen, setVouchedModalOpen] = useState(false)
  const [riderVouchedData, riderVouchedDataResults] = useRiderVouchedDataMutation()

  useEffect(() => {
    if (checkout.basic_info) {
      loadDynamicScript(
        'https://static.vouched.id/plugin/releases/latest/index.js',
        'vouched',
        () => {
          const vouchedWebhookUrl = Cookies.get('vouchedWebhookUrl')
          const vouchedPublicKey = Cookies.get('vouchedPublicKey')
          const {
            first_name, last_name, email, phone
          } = checkout.basic_info?.user

          var vouchedClient = new window.Vouched({
          // Optional verification properties.
            verification: {
              // verify the user's information 
              firstName: first_name ? first_name : '',
              lastName: last_name ? last_name : '',
              // used for the crosscheck feature
              email: email ? email : '',
              phone: phone ? phone : '',
            },
            liveness: 'straight',
            
            appId: vouchedPublicKey,
            // your webhook for POST verification processing
            callbackURL: vouchedWebhookUrl,
    
            // mobile handoff
            crossDevice: true,
            crossDeviceQRCode: true,
            crossDeviceSMS: true,
            includeBackId: true,
            
            // called when the verification is completed.
            onDone: (job) => {
              if (job.status !== 'completed') {
                console.log('Vouched verification failerd', job)
                props.onFailure()
              } else {
                console.log("Vouched scanning completed", job)
                props.onSuccess()
              }
            },
            
            theme: {
              name: 'avant',
            },
          })
          setVouchedObject(vouchedClient)
          setVouchedLoading(false)
          setVouchedReady(true)
        }
      )
    }
  }, [])

  const closeWizardModal = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return
    } else {
      setWizardModalClose(true)
    }
  }

  const closeVouchedModal = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return
    } else {
      setVouchedModalOpen(false)
    }
  }

  return (
    <div>
      <Dialog
        open={modalOpen}
        onClose={() => closeWizardModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <FlexBox
          direction="column"
          alignItems="center"
          justify="center"
          textAlign="center"
          padding="0.5rem 1.5rem"

        >
          <FlexBox
            direction="row"
            alignItems="center"
            marginTop={isMobile ? '0.5rem' : '1.5rem'}
            marginBottom={isMobile ? '0rem' : '0.5rem'}
          >
            <RecentActorsIcon sx={[{ fontSize: "4rem", color: "#695626" }]} />
          </FlexBox>
          <StyledSubTitle marginBottom="-0.5rem">Identity Verification</StyledSubTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {!isMobile ?
                <StyledH4 twistedBlack margin="0 0 1rem 0">
                  We need a copy of your license to confirm <br />your identity
                  and driving record.
                </StyledH4>
                :
                <StyledH4 twistedBlack margin="0 0 1rem 0">
                  We need a copy of your license to confirm your identity
                  and driving record.
                </StyledH4>
              }
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              {vouchedLoading && (
                <img
                  src="https://res.cloudinary.com/twisted-road/image/upload/c_fill,g_center,q_auto,f_auto/v1572480187/frontend_assets/search/searchNoResultsLoader.gif"
                  alt="Vouched Script Loading"
                />
              )}
            </DialogContentText>
          </DialogContent>
          {vouchedReady &&
            <DialogActions className="vouched-dialog-action">
              <Button
                padding={!isMobile ? '0.5em 5em' : '0.5em 3em'}
                twistedGold
                twistedGoldDark
                twistedDarkBlack
                onClick={() => {
                  setWizardModalClose(false)
                  setVouchedModalOpen(true)
                  riderVouchedData({
                    reservationID: reservationID,
                    stepType: 'vouched modal'
                  })
                  setTimeout(() => {
                    vouchedObject.mount('#vouched-element')
                  }, 500)
                }}
                disabled={false}
              >
                Upload License
              </Button>
            </DialogActions>
          }
        </FlexBox>
      </Dialog>
      <Dialog
        open={vouchedModalOpen}
        onClose={() => closeVouchedModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <FlexBox
          direction="column"
          alignItems="center"
          justify="center"
          textAlign="center"
          padding="2rem"

        >
          <div 
            id='vouched-element' 
            style={{ 
              height: 500,
              width: isMobile ? 300 : 550
            }}
          ></div>
        </FlexBox>
      </Dialog>
    </div>
  )
}

export default InformationWizardVouched