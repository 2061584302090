export const theme = {
  // Common colors
  twistedBlack: '#212121',
  twistedDarkBlack : '#000000',
  twistedWhite: '#F8F8F8',
  white: '#FFF',

  twistedBrownLight: '#AF985F', //#AE975E
  twistedBrown: '#695626',
  twistedBrownDark: '#695626', //#6B5827

  twistedGoldLight: '#FFEC3B',  //#FFEB3A
  twistedGold: '#AF985F',
  twistedGoldDark: '#E2BC00', // E3BE29

  twistedGray: '#8B8B8B',
  body: '#fff',
  mobile: '768px'
}
